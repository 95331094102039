import { getBenchmarksCounter, removeDuplicates, validationSchemas } from '../application/common';
import {
  JobType,
  extendedApiJobsSlice,
  selectUiActiveStep,
  selectIsGetMethodError,
  selectIsReadOnly,
  selectUserProfile,
  useGetClientsQuery,
  ClientType,
  PlanType,
  GridStrategyType,
} from '../reducers';
import { useAppSelector } from './AppStoreHooks';
import { useFirstFpl } from './FirstFplHook';

export type SaveJobFunction = (arg: {
  job: JobType;
  customValidation?: (job: JobType) => boolean | never;
}) => Promise<JobType | never>;
export const useSaveJob = () => {
  const [mutate, saveJobResult] = extendedApiJobsSlice.useEditJobMutation({
    fixedCacheKey: 'use-edit-job-api',
  });
  const activeStep = useAppSelector(selectUiActiveStep);
  const isGetMethodError = useAppSelector(selectIsGetMethodError);
  const isReadOnly = useAppSelector(selectIsReadOnly);
  const user = useAppSelector(selectUserProfile);

  const getClientsResult = useGetClientsQuery('');

  const { getFirstFpl } = useFirstFpl();

  const getUpdate = (job: JobType) => {
    let result = { ...job };
    const { plans = [], strategies = [] } = job;

    if (getClientsResult.data?.length && plans.length) {
      const firstClient = plans[0];
      const selectedClient = getClientsResult.data.find(
        (client: ClientType) => client.clientShortCode === firstClient.clientShortCode,
      );
      result = {
        ...result,
        clientShortCode: selectedClient?.clientShortCode ?? '',
        clientName: selectedClient?.clientName ?? '',
      };
    }

    const { firstFpl } = getFirstFpl({ strategies });
    result = {
      ...result,
      fplGuid: firstFpl?.fplGuid ?? '',
      fplName: firstFpl?.fplName ?? '[FPL-TBD]',
      isPerformanceOnlyBook: Boolean(firstFpl?.isPerformanceOnlyBook),
    };

    const benchmarksCounter = getBenchmarksCounter(strategies);
    result.totalNumberOfColumns = benchmarksCounter + strategies.length;

    result.plans = removeDuplicates(plans, 'planShortCode') as PlanType[];
    result.strategies = removeDuplicates(strategies, 'strategyShortCode') as GridStrategyType[];

    return result;
  };

  const { validationSchema } = validationSchemas[activeStep];
  const saveJob: SaveJobFunction = async ({ job, customValidation }) => {
    try {
      if (isGetMethodError) throw new Error("Can't save with missing data.");
      if (!isReadOnly && !job.isCanceled) validationSchema.validateSync(job);
      if (customValidation) customValidation(job);
      return await mutate({ job: getUpdate(job), userGroup: user.group }).unwrap();
    } catch (err: any) {
      console.error('Job not saved.');
      throw err;
    }
  };

  return {
    saveJobResult,
    saveJob,
  };
};
