import { toast } from 'react-toastify';
import { EquityMktCapType } from '../../components/Shadow/Strategy/StrategiesColumnDefs';
import { extendedApiStrategyCharacteristcsSlice } from '../../reducers/strategyCharacteristicsSlice';
import { useValidateEquityCapGrid } from '../../components/Shadow/Strategy/stepsSettings/EquityCapGridValidations';

type StrategyCharacteristicsEquitysSubmitFunction = (
  equitysToSubmit: EquityMktCapType[],
) => Promise<EquityMktCapType[] | never>;

type StrategyCharacteristicsEquityDeleteFunction = (
  strategyCharEquityToDelete: EquityMktCapType,
) => Promise<string | never>;
export const useMutateStrategyCharEquityCap = () => {
  const [submitStrategiesCharEquity, submitStrategiesCharEquityResult] =
    extendedApiStrategyCharacteristcsSlice.useStrategyCharEquityMktCapSubmitMutation();
  const [deleteStrategyEquityMkt, deleteStrateGyCharEquityMktResult] =
    extendedApiStrategyCharacteristcsSlice.useStrategyCharEquityMktCapDeleteMutation();

  const { validateEquityCapGrid } = useValidateEquityCapGrid();

  const submitStrateGyCharEquity: StrategyCharacteristicsEquitysSubmitFunction = async (equitysCapsToSubmit) => {
    try {
      validateEquityCapGrid(equitysCapsToSubmit);
      const mappedEquitysCap = equitysCapsToSubmit.map((aum) => ({
        ...aum,
        modifiedDate: undefined,
      }));
      return await submitStrategiesCharEquity(mappedEquitysCap)
        .unwrap()
        .then((data) => {
          toast.success('Equities submitted successfully');
          return data;
        });
    } catch (err: any) {
      console.error('Equities not submitted.');
      throw err;
    }
  };

  const deleteStrateGyCharEquityMkt: StrategyCharacteristicsEquityDeleteFunction = async (
    strategyCharEquityMktToDelete,
  ) => {
    try {
      return await deleteStrategyEquityMkt(strategyCharEquityMktToDelete.strategyCharId)
        .unwrap()
        .then((data) => {
          toast.success('Equity Mkt Cap Deleted');
          return data;
        });
    } catch (err: any) {
      toast.error('Strategy Characteristics Equity Mkt Cap not deleted.');
      console.error('Strategy Characteristics Equity Mkt Cap not deleted.');
      throw err;
    }
  };

  return {
    submitStrateGyCharEquity,
    submitStrategiesCharEquityResult,
    deleteStrateGyCharEquityMkt,
    deleteStrateGyCharEquityMktResult,
  };
};
