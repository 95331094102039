import { staticData } from '../../../application/common';

import { GridColDef } from '@mui/x-data-grid';
import { BaseColumns } from './stepsSettings/CommonSettings';

export interface EquityMktCapType {
  isTouched: boolean;
  annualTurnover: number;
  currentNumberHoldings: number;
  dataSourceId: number;
  dataSourceName: string;
  dateLocked: string;
  firmLegalName: string;
  firmShortCode: string;
  id: number;
  strategyCharId: number;
  isLocked: boolean;
  largeCap: number;
  maxCashPercentage: number;
  medianMktCap: number;
  megaCap: number;
  microCap: number;
  midCap: number;
  midLargeCap: number;
  modifiedBy: string;
  modifiedDate?: string;
  month: number;
  smallCap: number;
  smidCap: number;
  strategyName: string;
  strategyShortCode: string;
  typicalCashPercentage: number;
  typicalHoldings: string;
  weightedAvgMktCap: number;
  year: number;
}

export interface EquitySecType {
  isTouched: boolean;
  id: number;
  strategyCharId: number;
  strategyShortCode: string;
  year: number;
  month: number;
  consumerDiscretionary: number;
  consumerStaples: number;
  energy: number;
  financials: number;
  healthcare: number;
  industrials: number;
  infoTech: number;
  materials: number;
  telecom: number;
  utilities: number;
  other: number;
  realEstate: number;
  isLocked: boolean;
  dateLocked: string;
}

export const emptyEquityMktCapType: EquityMktCapType = {
  isTouched: true,
  annualTurnover: 0,
  currentNumberHoldings: 0,
  dataSourceId: staticData.NEPC_CREATED_DS_ID,
  dataSourceName: '',
  dateLocked: '',
  firmLegalName: '',
  firmShortCode: '',
  id: 0,
  isLocked: false,
  largeCap: 0,
  maxCashPercentage: 0,
  medianMktCap: 0,
  megaCap: 0,
  microCap: 0,
  midCap: 0,
  midLargeCap: 0,
  modifiedBy: '',
  modifiedDate: '',
  month: 3,
  smallCap: 0,
  smidCap: 0,
  strategyName: '',
  strategyShortCode: '',
  typicalCashPercentage: 0,
  typicalHoldings: '',
  weightedAvgMktCap: 0,
  year: 0,
  strategyCharId: 0,
};

export const emptyEquitySecType = {
  isTouched: true,
  id: 0,
  strategyCharId: 0,
  strategyShortCode: '',
  month: 3,
  consumerDiscretionary: 0,
  consumerStaples: 0,
  energy: 0,
  financials: 0,
  healthcare: 0,
  industrials: 0,
  infoTech: 0,
  materials: 0,
  telecom: 0,
  utilities: 0,
  other: 0,
  realEstate: 0,
  isLocked: false,
  dateLocked: '',
};

export const EquityMktCapDefs: GridColDef[] = BaseColumns([
  {
    field: 'microCap',
    headerName: 'Micro Cap',
    width: 175,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'smallCap',
    headerName: 'Small Cap',
    width: 175,
    headerAlign: 'center',
    sortable: false,
    align: 'right',

    editable: true,
  },
  {
    field: 'smidCap',
    headerName: 'Small Mid Cap',
    width: 175,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'midCap',
    headerName: 'Mid Cap',
    width: 175,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'midLargeCap',
    headerName: 'Mid Large Cap',
    width: 175,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'largeCap',
    headerName: 'Large Cap',
    width: 175,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'megaCap',
    headerName: 'Mega Cap',
    width: 175,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'weightedAvgMktCap',
    headerName: 'Weighted Avg Mkt Cap',
    width: 175,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'currentNumberHoldings',
    headerName: 'Current Number',
    width: 175,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'typicalHoldings',
    headerName: 'Typical Holdings',
    width: 175,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'medianMktCap',
    headerName: 'Median Mkt Cap',
    width: 175,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'annualTurnover',
    headerName: 'Annual Turnover',
    width: 175,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'maxCashPercentage',
    headerName: 'Max Cash Percentage',
    width: 175,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'typicalCashPercentage',
    headerName: 'Typical Cash Percentage',
    width: 175,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
]);

export const EquitySecColumnDefs: GridColDef[] = BaseColumns([
  {
    field: 'consumerDiscretionary',
    headerName: 'Consumer Discretionary',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'consumerStaples',
    headerName: 'Consumer Staples',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    align: 'right',

    editable: true,
  },
  {
    field: 'energy',
    headerName: 'Energy',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'financials',
    headerName: 'Financials',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'healthcare',
    headerName: 'Healthcare',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'industrials',
    headerName: 'Industrials',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'infoTech',
    headerName: 'Info Tech',
    width: 150,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'materials',
    headerName: 'Materials',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'telecom',
    headerName: 'Telecom',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'utilities',
    headerName: 'Utilities',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'other',
    headerName: 'Other',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'realEstate',
    headerName: 'Research Analysts',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
]);
