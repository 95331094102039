import { apiSlice } from './apiSlice';
import * as Globals from '../application/common';
import { toast } from 'react-toastify';
import { Moment } from 'moment';

export interface JobStrategyType {
  id: number;
  deleteRow: boolean;
  benchmark2ShortCode: string | null;
  benchmarkShortCode: string | null;
  feeID: number;
  firmShortCode: string | null;
  fplGuid: string | null;
  grouping: string | null;
  jobID: string | null;
  managerNoticeStatus: string | null;
  strategyShortCode: string | null;
  type: string | null;
  vehicleID: number;
}

export interface GridStrategyType {
  benchmark2Name: string;
  benchmark2ShortCode: string;
  benchmarkName: string;
  benchmarkShortCode: string;
  createdDate: string | Moment | null;
  dataSourceID: number | string;
  dueDiligenceStatus: string;
  feeID: number | string;
  feeName: string;
  firmLegalName: string;
  firmShortCode: string;
  firmShortName: string;
  fplGuid: string;
  fplName: string;
  grossOrNet: string;
  grouping: string;
  id: number;
  isDrivingPerfOnlyBook?: boolean;
  isPerformanceOnly: boolean;
  jobID: string;
  jobStrategyID: number;
  managerNoticeStatus: string;
  planHoldingID: number | string;
  sortOrder: number;
  strategyName: string;
  strategyShortCode: string;
  type: string;
  vehicleID: number | string;
  vehicleName: string;
  winner: string;
}

export const emptyGridStrategyRow: GridStrategyType = {
  benchmark2Name: '',
  benchmark2ShortCode: '',
  benchmarkName: '',
  benchmarkShortCode: '',
  createdDate: '',
  dataSourceID: '',
  dueDiligenceStatus: '',
  feeID: '',
  feeName: '',
  firmLegalName: '',
  firmShortCode: '',
  firmShortName: '',
  fplGuid: '',
  fplName: '',
  grossOrNet: '',
  grouping: '',
  id: 0,
  isPerformanceOnly: false,
  jobID: '',
  jobStrategyID: 0,
  managerNoticeStatus: '',
  planHoldingID: '',
  sortOrder: 0,
  strategyName: '',
  strategyShortCode: '',
  type: '',
  vehicleID: '',
  vehicleName: '',
  winner: '',
};

export const extendedApiJobStrategiesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getJobStrategiesByJobID: builder.query({
      transformResponse: (baseQueryReturnValue?: GridStrategyType[]): GridStrategyType[] => baseQueryReturnValue ?? [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting strategies by jobID. Error: ${response.status}`);
        return response;
      },
      query: (jobID: string) => `${Globals.API.endpoints.JobStrategies}/${jobID}`,
      providesTags: (result, error, arg) => [{ type: 'Job', id: arg }],
    }),
  }),
});

export const { useGetJobStrategiesByJobIDQuery } = extendedApiJobStrategiesSlice;
