import { GridColDef } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import * as Globals from '../../../../application/common';
import { apiSlice } from '../../../../reducers';
import { BaseColumns, EmptyBaseType, StrategyCharacteristicsBaseType } from './CommonSettings';

const STRATEGIES_CHARACTERISTICS_FIXED_INCOME_TAG = 'Strategy-char-fixed-income';

export interface fixedDurationType extends StrategyCharacteristicsBaseType {
  lessThan1yr: number;
  oneToThreeYrs: number;
  threeToFiveYrs: number;
  fiveToSevenYrs: number;
  sevenToTenYrs: number;
  tenToTwentyYrs: number;
  greaterThan20yrs: number;
}

export const EmptyfixedDurationType: fixedDurationType = {
  ...EmptyBaseType,
  lessThan1yr: 0,
  oneToThreeYrs: 0,
  threeToFiveYrs: 0,
  fiveToSevenYrs: 0,
  sevenToTenYrs: 0,
  tenToTwentyYrs: 0,
  greaterThan20yrs: 0,
};

export const FixedDurationColumnDefs: GridColDef[] = BaseColumns([
  {
    field: 'lessThan1yr',
    headerName: 'Less than 1 year',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'oneToThreeYrs',
    headerName: 'One to three Years',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    align: 'right',
    editable: true,
  },
  {
    field: 'threeToFiveYrs',
    headerName: 'Three to five Years',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    align: 'right',
    editable: true,
  },
  {
    field: 'fiveToSevenYrs',
    headerName: 'Five to seven years',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'sevenToTenYrs',
    headerName: 'Seven to ten years',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'tenToTwentyYrs',
    headerName: 'Ten to twenty years',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'greaterThan20yrs',
    headerName: 'Greater than 20 years',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
]);

export const extendedApiStrategyCharacteristcsFixedDurationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStrategyCharFixedDuration: builder.query({
      transformResponse: (baseQueryReturnValue?: fixedDurationType[]): fixedDurationType[] =>
        baseQueryReturnValue ?? [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting Equity style data. Error: ${response.status}`);
        return response;
      },
      query: (strategyShortCode: string) => `${Globals.API.endpoints.strategyCharFixedDuration}/${strategyShortCode}`,
      providesTags: [STRATEGIES_CHARACTERISTICS_FIXED_INCOME_TAG],
    }),
    strategyCharFixedDurationSubmit: builder.mutation({
      transformErrorResponse: (response) => {
        toast.error(`Update Strategy Characteristics Fixed inc action failed. Error: ${response.status}`);
        return response;
      },
      query: (equityMkt: fixedDurationType[]) => ({
        url: `${Globals.API.endpoints.shadowStrategyCharFixedDuration}`,
        method: 'POST',
        body: equityMkt,
      }),
      invalidatesTags: [STRATEGIES_CHARACTERISTICS_FIXED_INCOME_TAG],
    }),
    strategyCharFixedDurationDelete: builder.mutation({
      transformResponse: (baseQueryReturnValue?: string): string => baseQueryReturnValue ?? '',
      transformErrorResponse: (response) => {
        toast.error(`Delete Strategy Characteristics Fixed Inc action failed. Error: ${response.status}`);
        return response;
      },
      query: (FixedDurationToDelete: number) => ({
        url: `${Globals.API.endpoints.shadowStrategyCharFixedDuration}/${FixedDurationToDelete}`,
        method: 'DELETE',
        responseHandler: (response) => response.text(),
      }),
      invalidatesTags: [STRATEGIES_CHARACTERISTICS_FIXED_INCOME_TAG],
    }),
  }),
});

export const {
  useGetStrategyCharFixedDurationQuery,
  useStrategyCharFixedDurationDeleteMutation,
  useStrategyCharFixedDurationSubmitMutation,
} = extendedApiStrategyCharacteristcsFixedDurationSlice;
