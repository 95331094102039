import { toast } from 'react-toastify';

import * as Globals from '../../../application/common';
import { apiSlice } from '../../../reducers';

const VEHICLES_TAG = 'Vehicles';
export interface performanceType {
  asOfDate: string;
  dataSourceName: string;
  dateLocked?: string;
  firmLegalName: string;
  firmShortCode: string;
  grossOrNet: string;
  isLocked: boolean;
  modifiedBy: string;
  modifiedDate?: string;
  month: number;
  performanceId: number;
  returnInDecimal: number;
  strategyName: string;
  strategyShortCode: string;
  vehicleDisplayName: string;
  vehicleId: number;
  year: number;
}

export interface GridPerformanceType extends performanceType {
  id: number;
}

export const extendedApiShadowVehiclesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVehiclesForPerformance: builder.query({
      transformResponse: (baseQueryReturnValue?: performanceType[]): performanceType[] => baseQueryReturnValue ?? [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting Equity style data. Error: ${response.status}`);
        return response;
      },
      query: ({
        selectedFirmShortCode,
        selectedFirmStrategyShortCode,
        selectedVehicleShortCode,
        selectedVehicleGrossOrNet,
      }) =>
        `${Globals.API.endpoints.vehiclesForPerformance}?firmShortCode=${selectedFirmShortCode}&strategyShortCode=${selectedFirmStrategyShortCode}&vehicleId=${selectedVehicleShortCode}&grossOrNet=${selectedVehicleGrossOrNet}`,
      providesTags: [VEHICLES_TAG],
    }),
    vehiclesSubmit: builder.mutation({
      transformErrorResponse: (response) => {
        toast.error(`Update Vehicles performance action failed. Error: ${response.status}`);
        return response;
      },
      query: (equityMkt: performanceType[]) => ({
        url: `${Globals.API.endpoints.shadowVehiclesForPerformance}`,
        method: 'POST',
        body: equityMkt,
      }),
      invalidatesTags: [VEHICLES_TAG],
    }),
    vehiclesDelete: builder.mutation({
      transformResponse: (baseQueryReturnValue?: string): string => baseQueryReturnValue ?? '',
      transformErrorResponse: (response) => {
        toast.error(`Delete Vehicles performance action failed. Error: ${response.status}`);
        return response;
      },
      query: (performanceToDelete: number) => ({
        url: `${Globals.API.endpoints.shadowVehiclesForPerformance}/${performanceToDelete}`,
        method: 'DELETE',
        responseHandler: (response) => response.text(),
      }),
      invalidatesTags: [VEHICLES_TAG],
    }),
  }),
});

export const { useGetVehiclesForPerformanceQuery, useVehiclesSubmitMutation, useVehiclesDeleteMutation } =
  extendedApiShadowVehiclesSlice;
