import { Stack } from '@mui/material';
import { useAppSelector } from '../../../hooks';
import { selectIsReadOnly } from '../../../reducers';
import { MultiSelectFormik, SelectFormik } from '../../Common';
import { staticData } from '../../../application/common';

const SearchBookType = () => {
  const isReadOnly = useAppSelector(selectIsReadOnly);
  const vehiclesUnderConsiderationOptions = ['Separate Account', 'Commingled Fund', 'Mutual Fund'];
  return (
    <>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <SelectFormik
          disabled={isReadOnly}
          id="search-template-id"
          label="Search Book Type*"
          name="searchTemplate"
          options={staticData.bookTypes}
        />
        <SelectFormik
          disabled={isReadOnly}
          id="source-of-candidates-id"
          label="Source of Candidates*"
          name="searchSourceOfCandidate"
          options={staticData.sourcesCandidates}
        />
      </Stack>
      <SelectFormik
        disabled={isReadOnly}
        id="portfolio-management-strategy-id"
        label="Portfolio Management Strategy"
        name="portfolioMgmtStrategy"
        options={staticData.managementStrategies}
      />
      <MultiSelectFormik
        disabled={isReadOnly}
        name="vehiclesUnderConsideration"
        label="Vehicles Under Consideration*"
        id="vehicles-under-consideration-id"
        options={vehiclesUnderConsiderationOptions}
      />
    </>
  );
};

export default SearchBookType;
