import { toast } from 'react-toastify';
import {
  extendedApiStrategyCharacteristcsFixedIncomeSlice,
  fixedIncomeType,
} from '../../components/Shadow/Strategy/stepsSettings/fixedIncome';

type StrategyCharacteristicsFixedIncomeSubmitFunction = (
  fixedIncomesToSubmit: fixedIncomeType[],
) => Promise<fixedIncomeType[] | never>;

type StrategyCharacteristicsFixedIncomeDeleteFunction = (
  strategyCharEquityToDelete: fixedIncomeType,
) => Promise<string | never>;
export const useMutateStrategyCharFixedIncome = () => {
  const [submitStrategiesCharFixedIncome, submitStrategiesCharFixedIncomeResult] =
    extendedApiStrategyCharacteristcsFixedIncomeSlice.useStrategyCharFixedIncomeSubmitMutation();
  const [deleteStrategyFixedIncome, deleteStrateGyCharFixedIncomeResult] =
    extendedApiStrategyCharacteristcsFixedIncomeSlice.useStrategyCharFixedIncomeDeleteMutation();

  const submitStrateGyCharFixedIncome: StrategyCharacteristicsFixedIncomeSubmitFunction = async (
    equitysStyleToSubmit,
  ) => {
    try {
      const mappedFixedIncome = equitysStyleToSubmit.map((equityStyle) => ({
        ...equityStyle,
        modifiedDate: undefined,
      }));
      return await submitStrategiesCharFixedIncome(mappedFixedIncome)
        .unwrap()
        .then((data) => {
          toast.success('Equities Style submitted successfully');
          return data;
        });
    } catch (err: any) {
      console.error('Equities Style not submitted.');
      throw err;
    }
  };

  const deleteStrateGyCharFixedIncome: StrategyCharacteristicsFixedIncomeDeleteFunction = async (
    strategyCharFixedIncomeToDelete,
  ) => {
    try {
      return await deleteStrategyFixedIncome(strategyCharFixedIncomeToDelete.strategyCharId)
        .unwrap()
        .then((data) => {
          toast.success('Equity Style Deleted');
          return data;
        });
    } catch (err: any) {
      toast.error('Strategy Characteristics Equity Style not deleted.');
      console.error('Strategy Characteristics Equity Style not deleted.');
      throw err;
    }
  };

  return {
    submitStrateGyCharFixedIncome,
    submitStrategiesCharFixedIncomeResult,
    deleteStrateGyCharFixedIncome,
    deleteStrateGyCharFixedIncomeResult,
  };
};
