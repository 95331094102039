import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import {
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowModel,
  useGridApiContext,
} from '@mui/x-data-grid';
import React, { useEffect, useMemo, useState } from 'react';

import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import moment from 'moment';
import { toast } from 'react-toastify';
import { GetMinId, inputSequentialIds, isValidQuarter } from '../../../application/common';
import { StyledDatagrid } from '../../styles';
import { useShadowContext } from '../Shadow';
import ShadowGridNumberInput from './ShadowGridNumberInput';
import { useStrategyCharacteristicsContext } from './Strategy';

export const StrategyCharGrid = () => {
  const currentYear = moment().year();
  const { selectedFirmStrategyShortCode, selectedFirmShortCode } = useShadowContext();
  const { columnDefs, stepId, result, submitQuery, deleteQuery, emptyObj, deleteValidation } =
    useStrategyCharacteristicsContext();
  const [year, setYear] = useState<number>(0);
  const [month, setMonth] = useState<number>(0);
  const [dataRows, setDataRows] = useState<any[]>([]);
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
  });

  const getGridData = result.data;

  const handleSubmit = () => {
    const today = moment();
    let invalidData = false;

    dataRows.forEach((dataRow) => {
      if (invalidData) {
        return;
      }
      const dateToCompare = moment(`${dataRow.year}-${dataRow.month}-01`);
      if (dataRow.year < 999) {
        invalidData = true;
        return toast.error('Year is not valid');
      }
      if (today.isBefore(dateToCompare, 'month')) {
        invalidData = true;
        return toast.error('Year/Month cannot be in the future');
      }
      if (!isValidQuarter(dataRow.month)) {
        invalidData = true;
        return toast.error('Month must be a Quarter End Month');
      }
    });
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!invalidData) {
      submitQuery(dataRows.filter((row) => row.isTouched));
    }
  };

  const addRow = () => {
    setDataRows([
      {
        ...emptyObj,
        id: GetMinId(dataRows) - 1,
        strategyCharId: GetMinId(dataRows) - 1,
        firmShortCode: selectedFirmShortCode,
        strategyShortCode: selectedFirmStrategyShortCode,
        year: currentYear,
        compDate: `3-${currentYear}`,
        isTouched: true,
      },
      ...dataRows,
    ]);
  };

  const deleteRow = (rowToDelete: any) => {
    deleteQuery(rowToDelete).then(() => {
      if (deleteValidation ? !deleteValidation(rowToDelete) : true) {
        setDataRows([...dataRows.filter((row) => row.id !== rowToDelete.id)]);
      }
    });
  };

  useEffect(() => {
    setDataRows([]);
    setMonth(0);
    setYear(0);
  }, [selectedFirmStrategyShortCode, selectedFirmShortCode]);

  useEffect(() => {
    if (
      !result.isUninitialized &&
      !result.isFetching &&
      result.data?.[0] &&
      !Object.keys(result.data[0]).includes(`strategyCharId`)
    ) {
      result.refetch();
      setMonth(0);
      setYear(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepId]);

  useEffect(() => {
    if (selectedFirmStrategyShortCode) {
      setDataRows(
        inputSequentialIds(
          [
            ...(getGridData?.map((row: any) => ({
              ...row,
              compDate: `${row.month}-${row.year}`,
              isTouched: false,
            })) ?? []),
          ].sort((a, b) => a.strategyCharAumId - b.strategyCharAumId),
        ) as unknown as any[],
      );
    }
  }, [getGridData, stepId, selectedFirmStrategyShortCode, selectedFirmShortCode]);

  const avaliableYears = useMemo<number[]>(() => {
    const trinnedYears: number[] = [];
    dataRows.forEach((aumRow: any) => {
      if (trinnedYears.includes(aumRow.year)) {
        return;
      }
      trinnedYears.push(aumRow.year);
    });
    return trinnedYears;
  }, [dataRows]);

  const deleteColumn: GridColDef = {
    field: 'deleteRow',
    headerName: '',
    headerAlign: 'center',
    width: 50,
    type: 'actions',
    cellClassName: 'actions',
    renderCell: (params: any) => (
      <IconButton
        id={`delete-btn-${params.row.id}`}
        disabled={deleteValidation ? deleteValidation(params.row) : false}
        onClick={() => deleteRow(params.row)}
        aria-label="delete"
      >
        <DeleteIcon />
      </IconButton>
    ),
    sortable: false,
  };

  const handleCellClick = React.useCallback((params: GridCellParams, event: React.MouseEvent) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if ((event.target as any).nodeType === 1 && !event.currentTarget.contains(event.target as Element)) {
      return;
    }

    // eslint-disable-next-line arrow-body-style
    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {},
            ),
          }),
          {},
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {},
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = React.useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow };
    setDataRows(dataRows.map((row: any) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const EditInputCell = (props: GridRenderCellParams<any, number>) => {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
    const ref = React.useRef<HTMLElement>();
    const numericalInput = typeof value === 'number';

    const handleChange = (event: SelectChangeEvent) => {
      const parsedValue = numericalInput ? Number(event.target.value) : event.target.value.replaceAll(/([aA-zZ])/g, '');
      if (field === 'year') {
        apiRef.current.setEditCellValue({ id, field, value: parsedValue });
        apiRef.current.setEditCellValue({ id, field: 'isTouched', value: true });
        processRowUpdate({ ...apiRef.current.getRow(id), [field]: parsedValue, isTouched: true });
        return;
      }
      apiRef.current.setEditCellValue({ id, field, value: event.target.value });
      apiRef.current.setEditCellValue({ id, field: 'isTouched', value: true });
      processRowUpdate({
        ...apiRef.current.getRow(id),
        [field]: numericalInput ? parsedValue : event.target.value,
        isTouched: true,
      });
    };

    if (field === 'month') {
      return (
        <Select
          ref={ref}
          labelId="month-filter-select"
          id={`${id}-month-filter-select-id`}
          value={value?.toString()}
          onChange={handleChange}
          fullWidth
        >
          <MenuItem value={3} key={`3-Months-select-filter`}>
            3
          </MenuItem>
          <MenuItem value={6} key={`6-Months-select-filter`}>
            6
          </MenuItem>
          <MenuItem value={9} key={`9-Months-select-filter`}>
            9
          </MenuItem>
          <MenuItem value={12} key={`12-Months-select-filter`}>
            12
          </MenuItem>
        </Select>
      );
    }
    return ShadowGridNumberInput(props, numericalInput, handleChange);
  };

  const MonthFilterSelector = () => (
    <FormControl fullWidth size="small">
      <InputLabel id="month-filter-select-label">Month</InputLabel>
      <Select
        labelId="month-filter-select"
        id="month-filter-select-id"
        defaultValue={0}
        value={month}
        label="Month"
        onChange={(event: SelectChangeEvent<number>) => {
          const monthValue = event.target.value as number;
          setMonth(monthValue);
          applyFilter(year, monthValue);
        }}
        disabled={!selectedFirmStrategyShortCode}
      >
        <MenuItem value={0} key={`AllMonths-select-filter`}>
          All
        </MenuItem>
        <MenuItem value={3} key={`3-Months-select-filter`}>
          3
        </MenuItem>
        <MenuItem value={6} key={`6-Months-select-filter`}>
          6
        </MenuItem>
        <MenuItem value={9} key={`9-Months-select-filter`}>
          9
        </MenuItem>
        <MenuItem value={12} key={`12-Months-select-filter`}>
          12
        </MenuItem>
      </Select>
    </FormControl>
  );

  const YearFilterSelector = () => (
    <FormControl fullWidth size="small">
      <InputLabel id="year-filter-select-label">Year</InputLabel>
      <Select
        labelId="year-filter-select"
        id="year-filter-select-id"
        value={year}
        defaultValue={0}
        label="Year"
        onChange={(event: SelectChangeEvent<number>) => {
          const yearValue = event.target.value as number;
          setYear(yearValue);
          applyFilter(yearValue, month);
        }}
        disabled={!selectedFirmStrategyShortCode}
      >
        <MenuItem value={0} key={`AllYears-select-filter`}>
          All
        </MenuItem>
        {avaliableYears.map((year: number) => (
          <MenuItem value={year} key={`${year}-year-select-filter`}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const EditInputBooleanCell = (props: GridRenderCellParams<any, boolean>) => {
    const { id, value, field, hasFocus } = props;
    const apiRef = useGridApiContext();
    const ref = React.useRef<HTMLElement>();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      apiRef.current.setEditCellValue({ id, field, value: event.target.checked });
      processRowUpdate({ ...apiRef.current.getRow(id), isLocked: event.target.checked });
    };

    useEnhancedEffect(() => {
      if (hasFocus && ref.current) {
        const input = ref.current.querySelector<HTMLInputElement>(`input[value="${value}"]`);
        input?.focus();
      }
    }, [hasFocus, value]);

    return <Checkbox checked={value} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />;
  };

  const getColumns = () => [
    deleteColumn,
    ...columnDefs.map((col: GridColDef) => {
      if (col.editable && col.type !== 'boolean') {
        return { ...col, renderEditCell: (params: GridRenderEditCellParams) => <EditInputCell {...params} /> };
      }
      if (col.editable && col.type === 'boolean') {
        return { ...col, renderEditCell: (params: GridRenderEditCellParams) => <EditInputBooleanCell {...params} /> };
      }
      return col;
    }),
  ];

  const applyFilter = (_year: number, _month: number) => {
    const filterValue = `${_month === 0 ? '' : _month}-${_year === 0 ? '' : _year}`;
    setFilterModel({
      items: [
        {
          field: 'compDate',
          operator: 'contains',
          value: filterValue,
        },
      ],
    });
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="baseline" p={1}>
        <Stack direction="row" width={{ xs: '50%', sm: '50%', md: '35%', lg: '25%', xl: '20%' }} p={1} gap={2}>
          {YearFilterSelector()}
          {MonthFilterSelector()}
        </Stack>
        <Stack direction="row" gap={1}>
          <Button
            variant="contained"
            onClick={() => handleSubmit()}
            disabled={!selectedFirmStrategyShortCode || result.isFetching}
          >
            Save
          </Button>
          <Button
            size="small"
            disabled={!selectedFirmStrategyShortCode || result.isFetching}
            variant="contained"
            onClick={addRow}
            id={`add-${stepId}-Row-buttom`}
          >
            Add Row
          </Button>
        </Stack>
      </Stack>
      <StyledDatagrid
        rows={dataRows as unknown as any[]}
        columns={getColumns()}
        loading={result.isLoading}
        filterModel={filterModel}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        initialState={{
          columns: {
            columnVisibilityModel: {
              compDate: false,
            },
          },
        }}
        autoHeight
        hideFooter
      />
    </Box>
  );
};
