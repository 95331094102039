import { Stack } from '@mui/material';
import { useStrategiesContext } from '../StrategiesContext/StrategiesContext';
import { getBenchmarksCounter } from '../../../../application/common';

export const HeaderWithCounter = () => {
  const { values } = useStrategiesContext();
  const benchmarksCounter = getBenchmarksCounter(values.strategies);
  return (
    <Stack direction="row" spacing={2} justifyContent="flex-end" mr="2px" mb="2px">
      <Stack direction="row" spacing={1} justifyContent="space-between">
        <h5 className="data-title">Benchmark count:</h5>
        <h5>{benchmarksCounter}</h5>
      </Stack>
      <Stack direction="row" spacing={1} justifyContent="space-between">
        <h5 className="data-title">Strategies count:</h5>
        <h5>{values.strategies?.length}</h5>
      </Stack>
    </Stack>
  );
};
