import moment from 'moment';
import business from 'moment-business';
import * as yup from 'yup';
import { ShadowFeeType } from '../../reducers';
import {
  DUE_DATE_DAYS_FORWARD_REVIEW,
  DUE_DATE_DAYS_FORWARD_SEARCH_BOOK,
  MEETING_DATE_DAYS_FORWARD,
} from './Constants';

const msgRequestorIsRequired = 'Requestor is a required field!';
const msgPrimaryConsultantIsRequired = 'Primary Consultant is a required field!';
const msgReturnInDecimalIsRequired = 'Return in decimal is a required field!';
const msgMandateSizeIsRequired = 'Mandate Size is a required field';
const msgAllBpsAndTiersFilled = 'Can only have tier amount/bps, or all bps';
const msgBalanceAndAllBpsFilled = 'Can only have either balance bps, or all bps';
const msgDuplicatedFee = 'Duplicated Fee';
const strDateFormat = 'MM/DD/YYYY';
const msgFutureDate = 'Year/Month cannot be in the future';

const getMinDate = (daysForward: number) => business.addWeekDays(moment().startOf('day'), daysForward);

const mainSelectionValidationSchema = yup.object({
  isDraft: yup.boolean(),
  asofdatePerf: yup
    .string()
    .typeError('Performance Quarter End is a required field!')
    .required('Performance Quarter End is a required field!'),
  asofdateChar: yup
    .string()
    .typeError('Character Quarter End is a required field!')
    .required('Character Quarter End is a required field!'),
  requestorEmail: yup.string().required(msgRequestorIsRequired),
  primaryConsultantEmail: yup.string().required(msgPrimaryConsultantIsRequired),
  searchTemplate: yup.string().required('Book Type is a required field!'),
  searchSourceOfCandidate: yup.string().required('Source of Candidates is a required field!'),
  vehiclesUnderConsideration: yup.array().min(1, 'Vehicles Under Consideration is a required field!'),
  dueDate: yup.lazy(() =>
    yup
      .date()
      .typeError('Due date is a required field')
      .required('Due date is a required field!')
      .when('isDraft', {
        is: true,
        then: (schema) =>
          schema
            .when('searchTemplate', {
              is: 'Search Book',
              then: (schema) =>
                schema.min(
                  getMinDate(DUE_DATE_DAYS_FORWARD_SEARCH_BOOK).toDate(),
                  `The minimum start due date required for search book is ${getMinDate(DUE_DATE_DAYS_FORWARD_SEARCH_BOOK).format(strDateFormat)}`,
                ),
            })
            .when('searchTemplate', {
              is: 'Review',
              then: (schema) =>
                schema.min(
                  getMinDate(DUE_DATE_DAYS_FORWARD_REVIEW).toDate(),
                  `The minimum start due date required for review is ${getMinDate(DUE_DATE_DAYS_FORWARD_REVIEW).format(strDateFormat)}`,
                ),
            }),
      }),
  ),
  meetingDate: yup.lazy(() =>
    yup
      .date()
      .typeError('Meeting date is a required field')
      .required('Meeting date is a required field!')
      .when('isDraft', {
        is: true,
        then: (schema) =>
          schema.min(
            getMinDate(MEETING_DATE_DAYS_FORWARD).toDate(),
            `The minimum start meeting date is ${getMinDate(MEETING_DATE_DAYS_FORWARD).format(strDateFormat)}`,
          ),
      }),
  ),
  clientShortCode: yup
    .string()
    .typeError('Please select a client and add at least one plan.')
    .required('Please select a client and add at least one plan.'),
  plans: yup
    .array()
    .of(
      yup.object().shape({
        planShortCode: yup.string().required(`Plan is a required field`),
        planMandateSize: yup
          .number()
          .typeError(msgMandateSizeIsRequired)
          .required(msgMandateSizeIsRequired)
          .moreThan(0, msgMandateSizeIsRequired)
          // eslint-disable-next-line no-loss-of-precision
          .max(9999999999999999.99, 'Mandate Size maximum value is 9,999,999,999,999,999.99'),
      }),
    )
    .required('Select at least a plan!'),
  fkRequestedBmk: yup
    .string()
    .nullable()
    .test(
      'is-fk-requested-bmk',
      `Primary and secondary benchmarks can't be the same`,
      (value, testContext): boolean => !(value?.length && value === testContext.parent.fkSecondaryBmk),
    ),
  fkSecondaryBmk: yup
    .string()
    .nullable()
    .test(
      'is-fk-secondary-bmk',
      `Primary and secondary benchmarks can't be the same`,
      (value, testContext) => !(value?.length && value === testContext.parent.fkRequestedBmk),
    ),
});

const strategySelectionValidationSchema = yup.object({
  // fplGuid: yup.string().required('Please Select an FPL First'),
  // strategyShortCode: yup.string().required('Please Select an incumbent First'),
});

const managerSubmissionValitationSchema = yup.object({});

const mainSelectionTab = { validationSchema: mainSelectionValidationSchema };
const strategySelectionTab = { validationSchema: strategySelectionValidationSchema };
const managerSubmissionTab = { validationSchema: managerSubmissionValitationSchema };

export const validationSchemas = [mainSelectionTab, strategySelectionTab, managerSubmissionTab];

export const jobSendToJiraValidationSchema = yup.object({
  requestorEmail: yup.string().required(msgRequestorIsRequired),
  jobName: yup.string().required('JobName is a required field!'),
  primaryConsultantEmail: yup.string().required(msgPrimaryConsultantIsRequired),
});

export const jobSubmitValidationSchema = yup.object({
  requestorEmail: yup.string().required(msgRequestorIsRequired),
  jobName: yup.string().required('JobName is a required field!'),
  primaryConsultantEmail: yup.string().required(msgPrimaryConsultantIsRequired),
});

export const shadowFeeUpdateValitationSchema = yup.object({
  fees: yup.array().of(
    yup.object().shape({
      feeTypeId: yup
        .number()
        .required('missing Fee type')
        .test({
          name: 'Duplicated-fees',
          message: msgDuplicatedFee,
          test: (value, ctx) => {
            const filtered = ctx.from?.[1].value.fees.filter((a: ShadowFeeType) => a.feeTypeId === value);
            return !(filtered.length > 1);
          },
        }),
      balanceBps: yup.number().test({
        name: 'balanceBps-check',
        message: msgBalanceAndAllBpsFilled,
        test: (value, ctx) => !((value ? value > 0 : false) && ctx.parent?.allBps > 0),
      }),
      allBps: yup
        .number()
        .test({
          name: 'allBps-check',
          message: msgBalanceAndAllBpsFilled,
          test: (value, ctx) => !((value ? value > 0 : false) && ctx.parent?.balanceBps > 0),
        })
        .test({
          name: 'allBps-tiers-check',
          message: msgAllBpsAndTiersFilled,
          test: (value, ctx) => {
            const allTiersAreEmpty =
              ctx.parent.tier1Amount === 0 &&
              ctx.parent.tier1Bps === 0 &&
              ctx.parent.tier2Amount === 0 &&
              ctx.parent.tier2Bps === 0 &&
              ctx.parent.tier3Amount === 0 &&
              ctx.parent.tier3Bps === 0 &&
              ctx.parent.tier4Amount === 0 &&
              ctx.parent.tier4Bps === 0 &&
              ctx.parent.tier5Amount === 0 &&
              ctx.parent.tier5Bps === 0 &&
              ctx.parent.tier6Amount === 0 &&
              ctx.parent.tier6Bps === 0 &&
              ctx.parent.tier7Amount === 0 &&
              ctx.parent.tier7Bps === 0;
            return !((value ? value > 0 : false) && !allTiersAreEmpty);
          },
        }),
      tier1Amount: yup.number().test({
        name: 'tier1Amount-allBps',
        message: msgAllBpsAndTiersFilled,
        test: (value, cpx) => !((value ? value > 0 : false) && cpx.parent.allBps > 0),
      }),
      tier1Bps: yup.number().test({
        name: 'tier1Bps-allBps',
        message: msgAllBpsAndTiersFilled,
        test: (value, cpx) => !((value ? value > 0 : false) && cpx.parent.allBps > 0),
      }),
      tier2Amount: yup.number().test({
        name: 'tier2Amount-allBps',
        message: msgAllBpsAndTiersFilled,
        test: (value, cpx) => !((value ? value > 0 : false) && cpx.parent.allBps > 0),
      }),
      tier2Bps: yup.number().test({
        name: 'tier2Bps-allBps',
        message: msgAllBpsAndTiersFilled,
        test: (value, cpx) => !((value ? value > 0 : false) && cpx.parent.allBps > 0),
      }),
      tier3Amount: yup.number().test({
        name: 'tier3Amount-allBps',
        message: msgAllBpsAndTiersFilled,
        test: (value, cpx) => !((value ? value > 0 : false) && cpx.parent.allBps > 0),
      }),
      tier3Bps: yup.number().test({
        name: 'tier3Bps-allBps',
        message: msgAllBpsAndTiersFilled,
        test: (value, cpx) => !((value ? value > 0 : false) && cpx.parent.allBps > 0),
      }),
      tier4Amount: yup.number().test({
        name: 'tier4Amount-allBps',
        message: msgAllBpsAndTiersFilled,
        test: (value, cpx) => !((value ? value > 0 : false) && cpx.parent.allBps > 0),
      }),
      tier4Bps: yup.number().test({
        name: 'tier4Bps-allBps',
        message: msgAllBpsAndTiersFilled,
        test: (value, cpx) => !((value ? value > 0 : false) && cpx.parent.allBps > 0),
      }),
      tier5Amount: yup.number().test({
        name: 'tier5Amount-allBps',
        message: msgAllBpsAndTiersFilled,
        test: (value, cpx) => !((value ? value > 0 : false) && cpx.parent.allBps > 0),
      }),
      tier5Bps: yup.number().test({
        name: 'tier5Bps-allBps',
        message: msgAllBpsAndTiersFilled,
        test: (value, cpx) => !((value ? value > 0 : false) && cpx.parent.allBps > 0),
      }),
      tier6Amount: yup.number().test({
        name: 'tier6Amount-allBps',
        message: msgAllBpsAndTiersFilled,
        test: (value, cpx) => !((value ? value > 0 : false) && cpx.parent.allBps > 0),
      }),
      tier6Bps: yup.number().test({
        name: 'tier6Bps-allBps',
        message: msgAllBpsAndTiersFilled,
        test: (value, cpx) => !((value ? value > 0 : false) && cpx.parent.allBps > 0),
      }),
      tier7Amount: yup.number().test({
        name: 'tier7Amount-allBps',
        message: msgAllBpsAndTiersFilled,
        test: (value, cpx) => !((value ? value > 0 : false) && cpx.parent.allBps > 0),
      }),
      tier7Bps: yup.number().test({
        name: 'tier7Bps-allBps',
        message: msgAllBpsAndTiersFilled,
        test: (value, cpx) => !((value ? value > 0 : false) && cpx.parent.allBps > 0),
      }),
    }),
  ),
});

export const shadowVehicleUpdateValitationSchema = () => {
  const currentDate = moment();

  return yup.object({
    vehicles: yup.array().of(
      yup.object().shape({
        returnInDecimal: yup.number().required(msgReturnInDecimalIsRequired),
        month: yup
          .number()
          .required()
          .test({
            name: 'monthFutureDate',
            message: msgFutureDate,
            test: (value, cpx) => {
              if (currentDate.month() < value && cpx.parent.year >= currentDate.year()) {
                return cpx.createError();
              }
              return true;
            },
          }),
        year: yup
          .number()
          .required()
          .test({
            name: 'yearFutureDate',
            message: msgFutureDate,
            test: (value, cpx) => {
              if (currentDate.year() <= value && cpx.parent.month > currentDate.month()) {
                return cpx.createError();
              }
              return true;
            },
          }),
      }),
    ),
  });
};
