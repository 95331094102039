import { useOktaAuth } from '@okta/okta-react';
import { Button } from '@mui/material';
import { ContainerHeader, ImageBackground, TitleHeader } from './styles';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../hooks';
import { selectIsFullAccess } from '../reducers';

const Header = (props: { pageName: string; btnRedirectToName: string; btnRedirectToURL: string }) => {
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();

  const isFullAccess = useAppSelector(selectIsFullAccess);

  const logout = async () => oktaAuth.signOut();

  return (
    <ImageBackground>
      <ContainerHeader>
        <img src="./squareLogo.jpg" alt="" width={70} height={61}></img>
        <TitleHeader>
          <h1>{props.pageName}</h1>
        </TitleHeader>
        {isFullAccess && (
          <Button
            onClick={() => history.push(props.btnRedirectToURL)}
            id={`button-to-${props.btnRedirectToName}`}
            variant="contained"
          >
            {props.btnRedirectToName}
          </Button>
        )}
        <Button variant="contained" onClick={logout}>
          Logout
        </Button>
      </ContainerHeader>
    </ImageBackground>
  );
};

export default Header;
