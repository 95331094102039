import { extendedApiNotificationsSlice } from '../reducers/notificationsSlice';

export type NotificationDeleteFunction = (notificationToDelete: number) => Promise<string | never>;
export const useDeleteNotification = () => {
  const [mutate, deleteNotificationResult] = extendedApiNotificationsSlice.useRunDeleteNotificationsMutation();

  const deleteNotification: NotificationDeleteFunction = async (NotificationToDelete) => {
    try {
      return await mutate(NotificationToDelete).unwrap();
    } catch (err: any) {
      console.error('Notification not deleted.');
      throw err;
    }
  };

  return {
    deleteNotificationResult,
    deleteNotification,
  };
};
