import { SxProps, Theme } from '@mui/material';
import { PartialSearch } from './';
import { useField } from 'formik';

interface PartialSearchFormikPropsType {
  disabled: boolean;
  disabledValue?: string;
  formatOptions?: (option: string) => string;
  id: string;
  label: string;
  loading: boolean;
  name: string;
  onChange?: (name: string, newValue: string) => void;
  optionKey: string;
  optionName: string;
  options?: any[];
  sx?: SxProps<Theme>;
}

export const PartialSearchFormik = (props: PartialSearchFormikPropsType) => {
  const { name, onChange, optionKey } = props;
  const [field, meta, helpers] = useField(name);
  const { touched, error } = meta;
  const { setValue } = helpers;

  return (
    <PartialSearch
      {...props}
      error={error}
      onChange={(newValueObj) => (onChange ? onChange(name, newValueObj[optionKey]) : setValue(newValueObj[optionKey]))}
      touched={touched}
      value={field.value}
    />
  );
};
