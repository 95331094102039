import { Container, FormControlLabel, Stack, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export const ContainerHeader = styled('div')`
  padding: 0.5rem 2rem;
  display: flex;
  gap: 2rem;
  align-items: flex-start;
`;

export const ImageBackground = styled('div')`
  position: relative;
  text-align: left;

  background-image: url('/background-header.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-origin: padding-box;
  background-position: 0px 30%;

  @media screen and (max-width: 1024px) {
    background-attachment: initial;
    background-position: 0% 35%;
  }

  justify-content: space-between;
  height: 80px;

  @media screen and (max-width: 800px) {
    padding: 0.3rem;
  }
`;

export const TitleHeader = styled('div')`
  display: flex;
  flex-direction: column;

  h1,
  h2 {
    margin: 0;
    color: #ffffff;
  }
  width: 85%;
`;

export const SelectionPage = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
  padding-right: 0;
  padding-left: 0;
`;

export const FieldDivider = styled(Stack)`
  display: flex;
  padding: 1rem;

  width: 95%;

  border: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: ${({ theme }) => theme.palette.primary.main};

  background-color: #ffffff;

  h4 {
    color: ${({ theme }) => theme.palette.primary.dark};
    margin: 0;
    padding: 0;
  }

  h5 {
    margin: 0;
  }
  h5.data-title {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const InformationCard = styled(FieldDivider)`
  width: calc(80% + 2rem);
`;

export const SubmissionGrid = styled(DataGrid)`
  .contact-email-cell {
    overflow-wrap: break-word;
    inline-size: 270px;
    white-space: break-spaces !important;
  }
`;

export const CheckBoxWLabel = styled(FormControlLabel)`
  margin-left: 0;
`;

export const DataLine = styled(Stack)`
  justify-content: flex-start;
  display: flex;
  width: 100%;
  gap: 0.5rem;
  label {
    text-wrap: nowrap;
    color: #002060;
    font-weight: bold;
    width: 10rem;
  }

  .pending-litigation-disclosure {
    min-height: 5rem;
  }
`;

export const BooleanDataLine = styled(DataLine)`
  flex-direction: row;
  .MuiCheckbox-root {
    padding: 0;
  }
  .MuiSvgIcon-root {
    padding: 0;
  }
`;

export const TextDataLine = styled(DataLine)`
  flex-direction: ${(props) => props.direction ?? 'row'};
`;

export const AwnserCardLine = styled(Stack)`
  border-radius: 4px;
  width: calc(100% - 10.5rem);
  padding: 0 0.25rem;
  min-height: 1rem;
  border-style: solid;
  border-width: thin;
  border-color: lightgrey;
`;

export const TextAreaAwnserCardLine = styled(Stack)`
  border-radius: 4px;
  width: 98%;
  padding: 0 0.25rem;
  border-style: solid;
  border-width: thin;
  border-color: lightgrey;
`;

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    span: {
      width: '100%',
    },
  },
}));

export const StyledDatagrid = styled(DataGrid)`
  box-shadow: 0;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .muiselect-select,.muiinputbase-input: {
    padding: 8px;
  }
  .muidatagrid-columnheadertitle: {
    max-width: 90px;
    text-wrap: wrap;
    text-align: center;
    line-height: 15px;
  }
  input.muiinputbase-input: {
    text-align: end;
  }
  .muidatagrid-cell: {
    padding: 0 0.5rem;
  }
  .muidatagrid-row--editing: {
    box-shadow: none;
  }
   & .Mui-error: {
      border: 'solid 1px red',
    },
`;
