import './App.css';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { useCallback } from 'react';
import Home from './components/Home';
import Shadow from './components/Shadow';
import { oktaAuth } from './application/common';

const App = () => {
  const restoreOriginalUri = useCallback(async (_oktaAuth: OktaAuth, originalUri: string) => {
    window.location.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  }, []);

  return (
    <Router>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Route path="/login/callback" component={LoginCallback} />
        <SecureRoute path="/" exact={true} component={Home} />
        <SecureRoute path="/shadow" exact={true} component={Shadow} />
      </Security>
    </Router>
  );
};

export default App;
