import DeleteIcon from '@mui/icons-material/Delete';
import { Checkbox, IconButton, MenuItem, Select, TextField } from '@mui/material';
import {
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowModel,
  useGridApiContext,
} from '@mui/x-data-grid';

import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import React, { useState } from 'react';
import { StyledDatagrid } from '../../../styles';
import { BaseColumns } from '../../Strategy/stepsSettings/CommonSettings';
import { performanceType } from '../ShadowVehicleSlice';
import { useVehiclesContext } from '../VehiclesForPerformance';
import { toast } from 'react-toastify';

export const ShadowVehiclesGrid = () => {
  const { setFieldValue, deleteVehicles, values, isVehiclesForPerformanceFetching, filterModel } = useVehiclesContext();
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

  const { vehicles = [] } = values;

  const _deleteRow = (id: number) => {
    if (vehicles.length > 0) {
      const filteredVehicles = [
        ...vehicles.filter((row) => {
          if (row.id === id && row.performanceId > 0) {
            deleteVehicles(row.performanceId);
            return false;
          } else if (row.id === id && row.performanceId < 0) {
            toast.success('Vehicles performance Deleted ');
            return false;
          }
          return true;
        }),
      ];
      setFieldValue('vehicles', filteredVehicles);
    }
  };

  const EditInputCell = (props: GridRenderEditCellParams) => {
    const { id, value, field, hasFocus } = props;
    const apiRef = useGridApiContext();
    const ref = React.useRef<HTMLInputElement>(null);

    const handleChange = (event: any) => {
      const numericalInput = typeof event.target.value === 'number';
      const parsedValue = numericalInput ? Number(event.target.value) : event.target.value.replaceAll(/([aA-zZ])/g, '');
      if (field === 'month' || field === 'year') {
        apiRef.current.setEditCellValue({ id, field, value: parsedValue });
        apiRef.current.setEditCellValue({ id, field: 'isTouched', value: true });
        return processRowUpdate({
          ...apiRef.current.getRow(id),
          [field]: parsedValue,
          isTouched: true,
        });
      }
      apiRef.current.setEditCellValue({ id, field, value: event.target.value });
      apiRef.current.setEditCellValue({ id, field: 'isTouched', value: true });
      processRowUpdate({
        ...apiRef.current.getRow(id),
        [field]: numericalInput ? parsedValue : event.target.value,
        isTouched: true,
      });
    };

    const handleBlur = (event: any) => {
      const numericalInput = typeof event.target.value === 'number';
      const newEvent = {
        ...event,
        target: {
          ...event.target,
          value: event.target.value === '' ? 0 : numericalInput ? Number(event.target.value) : event.target.value,
        },
      };
      return handleChange(newEvent);
    };

    useEnhancedEffect(() => {
      if (hasFocus && ref.current) {
        const input = ref.current.querySelector<HTMLInputElement>(`input[value="${value}"]`);
        input?.focus();
      }
    }, [hasFocus, value]);

    if (field === 'month') {
      return (
        <Select
          ref={ref}
          labelId="month-filter-select"
          id={`${id}-month-filter-select-id`}
          value={value?.toString()}
          onChange={handleChange}
          fullWidth
        >
          <MenuItem value={1} key={`1-Months-select-filter`}>
            1
          </MenuItem>
          <MenuItem value={2} key={`2-Months-select-filter`}>
            2
          </MenuItem>
          <MenuItem value={3} key={`3-Months-select-filter`}>
            3
          </MenuItem>
          <MenuItem value={4} key={`4-Months-select-filter`}>
            4
          </MenuItem>
          <MenuItem value={5} key={`5-Months-select-filter`}>
            5
          </MenuItem>
          <MenuItem value={6} key={`6-Months-select-filter`}>
            6
          </MenuItem>
          <MenuItem value={7} key={`7-Months-select-filter`}>
            7
          </MenuItem>
          <MenuItem value={8} key={`8-Months-select-filter`}>
            8
          </MenuItem>
          <MenuItem value={9} key={`9-Months-select-filter`}>
            9
          </MenuItem>
          <MenuItem value={10} key={`10-Months-select-filter`}>
            10
          </MenuItem>
          <MenuItem value={11} key={`11-Months-select-filter`}>
            11
          </MenuItem>
          <MenuItem value={12} key={`12-Months-select-filter`}>
            12
          </MenuItem>
        </Select>
      );
    }
    return (
      <TextField
        ref={ref}
        onBlur={handleBlur}
        onChange={handleChange}
        type={typeof value === 'number' ? 'number' : 'text'}
        value={value}
        id={`${id}-${field}-id`}
      />
    );
  };

  const EditInputBooleanCell = (props: GridRenderCellParams<any, boolean>) => {
    const { id, value, field, hasFocus } = props;
    const apiRef = useGridApiContext();
    const ref = React.useRef<HTMLElement>();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      apiRef.current.setEditCellValue({ id, field, value: event.target.checked });
      processRowUpdate({ ...apiRef.current.getRow(id), isLocked: event.target.checked });
    };

    useEnhancedEffect(() => {
      if (hasFocus && ref.current) {
        const input = ref.current.querySelector<HTMLInputElement>(`input[value="${value}"]`);
        input?.focus();
      }
    }, [hasFocus, value]);

    return <Checkbox checked={value} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />;
  };

  const shadowVehiclesColumns: GridColDef[] = BaseColumns([
    {
      field: 'asOfDate',
      sortable: false,
    },
    {
      field: 'grossOrNet',
      headerName: 'Gross Or Net',
      width: 125,
      headerAlign: 'center',
      sortable: false,
      align: 'right',
    },
    {
      field: 'returnInDecimal',
      headerName: 'Rtn In Decimal',
      width: 125,
      headerAlign: 'center',
      sortable: false,
      align: 'right',
      editable: true,
    },
  ]);

  const handleCellClick = React.useCallback((params: GridCellParams, event: React.MouseEvent) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if ((event.target as any).nodeType === 1 && !event.currentTarget.contains(event.target as Element)) {
      return;
    }

    // eslint-disable-next-line arrow-body-style
    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {},
            ),
          }),
          {},
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {},
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = React.useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);

  const processRowUpdate = (newRow: GridRowModel) => {
    const editedRows = vehicles.map((row: performanceType) => {
      if (row.performanceId === newRow.id) {
        return {
          ...newRow,
        };
      } else return row;
    });
    setFieldValue('vehicles', editedRows);
    return editedRows;
  };

  const deleteColumn: GridColDef = {
    field: 'deleteRow',
    headerName: '',
    headerAlign: 'center',
    width: 50,
    type: 'actions',
    cellClassName: 'actions',
    renderCell: (params: any) => (
      <IconButton
        id={`delete-btn-${params.row.id}`}
        disabled={params.row.dataSourceName !== 'NEPC Created'}
        onClick={() => _deleteRow(params.row.id)}
        aria-label="delete"
      >
        <DeleteIcon />
      </IconButton>
    ),
    sortable: false,
  };

  const getColumns = () => [
    deleteColumn,
    ...shadowVehiclesColumns.map((col: GridColDef) => {
      if (col.editable && col.type !== 'boolean') {
        return { ...col, renderEditCell: (params: GridRenderEditCellParams) => <EditInputCell {...params} /> };
      }
      if (col.editable && col.type === 'boolean') {
        return { ...col, renderEditCell: (params: GridRenderEditCellParams) => <EditInputBooleanCell {...params} /> };
      }
      return col;
    }),
  ];

  return (
    <StyledDatagrid
      rows={vehicles}
      columns={getColumns()}
      loading={isVehiclesForPerformanceFetching}
      cellModesModel={cellModesModel}
      onCellModesModelChange={handleCellModesModelChange}
      onCellClick={handleCellClick}
      initialState={{
        columns: {
          columnVisibilityModel: {
            compDate: false,
            asOfDate: false,
          },
        },
        sorting: {
          sortModel: [{ field: 'asOfDate', sort: 'desc' }],
        },
        pagination: { paginationModel: { pageSize: 7 } },
      }}
      pageSizeOptions={[10, 25, 100]}
      filterModel={filterModel}
      autoHeight
    />
  );
};
