import { apiSlice } from './apiSlice';
import * as Globals from '../application/common';
import { GridStrategyType } from './jobStrategySlice';
import { toast } from 'react-toastify';

export const extendedApiIncumbentSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getIncumbents: builder.query({
      transformResponse: (baseQueryReturnValue?: GridStrategyType[]): GridStrategyType[] => baseQueryReturnValue ?? [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting incumbent dropdown list. Error: ${response.status}`);
        return response;
      },
      query: (incumbentQueryData: { jobID: string; plansShortCode: string }) =>
        `${Globals.API.endpoints.Incumbent}/${incumbentQueryData.jobID}/${incumbentQueryData.plansShortCode}`,
      providesTags: (result, error, arg) => [{ type: 'Job', id: arg.jobID }],
    }),
  }),
});

export const { useGetIncumbentsQuery } = extendedApiIncumbentSlice;
