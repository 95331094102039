import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  Theme,
  Tooltip,
} from '@mui/material';
import { useField } from 'formik';

interface SelectFormikPropsType {
  disabled: boolean;
  disabledValue?: string;
  error?: string;
  formatOptions?: (option: string) => string;
  hideFormError?: boolean;
  id: string;
  label: string;
  loading?: boolean;
  name: string;
  onChange?: (name: string, newValue: string) => void;
  optionKey?: string;
  optionName?: string;
  options?: any[];
  showTooltipError?: boolean;
  sx?: SxProps<Theme>;
  touched?: boolean;
}

export const SelectFormik = (props: SelectFormikPropsType) => {
  const {
    disabled,
    disabledValue,
    error,
    formatOptions,
    hideFormError,
    id,
    label,
    loading,
    name,
    onChange,
    optionKey,
    optionName,
    options = [],
    showTooltipError,
    sx,
    touched,
  } = props;
  const [field, meta, helpers] = useField(name);
  const errorMessage = error ?? meta.error;
  const isTouched = touched ?? meta.touched;
  const { setValue } = helpers;

  const isValid = !(isTouched && errorMessage);

  const renderLoading = () =>
    loading && (
      <InputAdornment position="end" sx={{ marginRight: '1rem' }}>
        <CircularProgress size={20} disableShrink />
      </InputAdornment>
    );

  return (
    <Tooltip title={!isValid && showTooltipError && errorMessage}>
      <FormControl size="small" fullWidth error={!isValid}>
        <InputLabel id={`${label}-select-label`}>{label}</InputLabel>
        <Select
          disabled={loading ?? disabled}
          endAdornment={renderLoading()}
          id={id || name}
          label={label}
          name={name}
          onChange={({ target: { value } }) => (onChange ? onChange(name, value) : setValue(value))}
          size="small"
          sx={sx}
          type={name}
          value={field.value || ''}
        >
          {options.map((option) => {
            const key = optionKey ? option[optionKey] : option;
            const value = optionName ? option[optionName] : option;
            return (
              <MenuItem
                disabled={Boolean(disabledValue) && key === disabledValue}
                id={`${id}-${key}`}
                key={`${id}-${key}`}
                value={key}
              >
                {formatOptions ? formatOptions(value) : value}
              </MenuItem>
            );
          })}
        </Select>
        {!isValid && !hideFormError && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    </Tooltip>
  );
};
