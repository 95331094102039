import { toast } from 'react-toastify';
import {
  extendedApiStrategyCharacteristcsFixedDurationSlice,
  fixedDurationType,
} from '../../components/Shadow/Strategy/stepsSettings/fixedDuration';

type StrategyCharacteristicsFixedDurationSubmitFunction = (
  fixedDurationsToSubmit: fixedDurationType[],
) => Promise<fixedDurationType[] | never>;

type StrategyCharacteristicsFixedDurationDeleteFunction = (
  strategyCharEquityToDelete: fixedDurationType,
) => Promise<string | never>;
export const useMutateStrategyCharFixedDuration = () => {
  const [submitStrategiesCharFixedDuration, submitStrategiesCharFixedDurationResult] =
    extendedApiStrategyCharacteristcsFixedDurationSlice.useStrategyCharFixedDurationSubmitMutation();
  const [deleteStrategyFixedDuration, deleteStrateGyCharFixedDurationResult] =
    extendedApiStrategyCharacteristcsFixedDurationSlice.useStrategyCharFixedDurationDeleteMutation();

  const submitStrateGyCharFixedDuration: StrategyCharacteristicsFixedDurationSubmitFunction = async (
    equitysStyleToSubmit,
  ) => {
    try {
      const mappedFixedDuration = equitysStyleToSubmit.map((equityStyle) => ({
        ...equityStyle,
        modifiedDate: undefined,
      }));
      return await submitStrategiesCharFixedDuration(mappedFixedDuration)
        .unwrap()
        .then((data) => {
          toast.success('Fixed Duration submitted successfully');
          return data;
        });
    } catch (err: any) {
      console.error('Fixed Duration not submitted.');
      throw err;
    }
  };

  const deleteStrateGyCharFixedDuration: StrategyCharacteristicsFixedDurationDeleteFunction = async (
    strategyCharFixedDurationToDelete,
  ) => {
    try {
      return await deleteStrategyFixedDuration(strategyCharFixedDurationToDelete.strategyCharId)
        .unwrap()
        .then((data) => {
          toast.success('Fixed Duration Deleted');
          return data;
        });
    } catch (err: any) {
      toast.error('Strategy Characteristics Fixed Duration not deleted.');
      console.error('Strategy Characteristics Fixed Duration not deleted.');
      throw err;
    }
  };

  return {
    submitStrateGyCharFixedDuration,
    submitStrategiesCharFixedDurationResult,
    deleteStrateGyCharFixedDuration,
    deleteStrateGyCharFixedDurationResult,
  };
};
