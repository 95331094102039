import { useState } from 'react';
import { Divider, Button, TextField, Stack } from '@mui/material';
import { Fields, MainTabContent, ShadowFormControl } from '../StyledShadow';
import { BenchmarkType, blankBmk, useBenchmarkSubmitMutation, useGetShadowBenchmarksQuery } from '../../../reducers';
import { PartialSearch } from '../../Common';
import { toast } from 'react-toastify';

export const Benchmark = () => {
  const { data: benchmarks = [], isLoading: isGetBenchmarksLoading } = useGetShadowBenchmarksQuery('');
  const [selectedBench, setSelectedBench] = useState<BenchmarkType>(blankBmk);
  const [runBenchmarkSubmit] = useBenchmarkSubmitMutation();

  return (
    <MainTabContent>
      <PartialSearch
        id="shadow-bench-partial-search"
        label="Select a benchmark"
        loading={isGetBenchmarksLoading}
        onChange={setSelectedBench}
        optionKey="benchmarkShortCode"
        optionName="benchmarkName"
        options={benchmarks}
        value={selectedBench.benchmarkShortCode}
        sx={{ margin: '.5rem 0 .5rem 0' }}
      />
      <Divider />
      <Fields>
        <ShadowFormControl>
          <TextField
            id="input-benchmark-short-code"
            value={selectedBench.benchmarkShortCode}
            label="Benchmark Code"
            size="small"
            variant="outlined"
            fullWidth
            disabled
          />
        </ShadowFormControl>
        <ShadowFormControl>
          <TextField
            id="input-benchmark-name"
            value={selectedBench.benchmarkName}
            label="Benchmark Name"
            size="small"
            variant="outlined"
            fullWidth
            disabled
          />
        </ShadowFormControl>
        <ShadowFormControl>
          <TextField
            id="input-benchmark-short-name"
            value={selectedBench.benchmarkShortName || ''}
            label="Short Name"
            size="small"
            variant="outlined"
            inputProps={{ maxLength: 30 }}
            fullWidth
            onChange={(event) => setSelectedBench({ ...selectedBench, benchmarkShortName: event.target.value })}
          />
        </ShadowFormControl>
      </Fields>
      <Stack alignItems="flex-end">
        <Button
          disabled={!selectedBench.benchmarkShortCode}
          variant="contained"
          id="save-buttom"
          onClick={async () =>
            await runBenchmarkSubmit(selectedBench)
              .unwrap()
              .then(() => {
                toast.success('Benchmark saved successfully');
              })
              .catch(({ data }) => console.error(`Benchmark not saved. Error: ${data.error}`))
          }
        >
          Save
        </Button>
      </Stack>
    </MainTabContent>
  );
};
