import { MiddlewareAPI } from 'redux';
import * as Globals from '../application/common';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Middleware, isRejectedWithValue } from '@reduxjs/toolkit';
import { setIsGetMethodError } from './uiSlice';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: Globals.API.BaseURL,
    timeout: Globals.API.timeout,
    credentials: 'include',
    prepareHeaders: (headers) => {
      const tokens = Globals.oktaAuth.tokenManager.getTokensSync();
      headers.set('Content-Type', Globals.API.contentType);
      headers.set('Authorization', `${tokens.accessToken?.tokenType} ${tokens.accessToken?.accessToken}`);
      return headers;
    },
  }),
  tagTypes: [
    'Job',
    'QuarterEndDates',
    `shadowBmk`,
    'Fees',
    'Firm-pending-litigation',
    'Notifications',
    'Vehicles',
    'Strategy-char-aum',
    'Strategy-char-equity-cap',
    'Strategy-char-equity-sec',
    'Strategy-char-equity-style',
    'Strategy-char-fixed-income',
    'Strategy-char-fixed-rating',
    'Strategy-char-fixed-sector',
  ],
  endpoints: () => ({}),
});

export const rtkQueryGetHttpErrorEmitter: Middleware = (api: MiddlewareAPI) => (next) => (action: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (isRejectedWithValue(action) && String(action.meta?.arg?.endpointName).includes('get')) {
    api.dispatch(setIsGetMethodError(true));
  }

  return next(action);
};
