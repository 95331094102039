import { Divider, Stack } from '@mui/material';
import PlansGrid from './PlansGrid/PlansGrid';
import Contacts from './Contacts';
import Benchmarks from './Benchmarks';
import SearchBookType from './SearchBookType';
import SearchBookDates from './SearchBookDates';

export const MainSelection = () => (
  <>
    <Contacts />
    <Divider flexItem />
    <Stack
      direction="row"
      marginBottom="2rem"
      marginTop="1rem"
      spacing={2}
      divider={<Divider orientation="vertical" flexItem />}
    >
      <Stack spacing={2} width="50%">
        <SearchBookDates />
      </Stack>
      <Stack spacing={2} width="50%" marginTop="0.9rem !important">
        <SearchBookType />
      </Stack>
    </Stack>
    <Divider flexItem />
    <Stack spacing={2} marginBottom="1rem" marginTop="1rem">
      <Benchmarks />
    </Stack>
    <Divider flexItem />
    <Stack spacing={2} marginTop="2rem">
      <PlansGrid />
    </Stack>
  </>
);
