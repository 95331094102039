import { apiSlice } from './apiSlice';
import * as Globals from '../application/common';
import { GridStrategyType, emptyGridStrategyRow } from './jobStrategySlice';
import { toast } from 'react-toastify';

export interface FplType {
  fplGuid: string;
  fplName: string;
  isPerformanceOnlyBook: boolean;
}

export const emptyFpl: FplType = {
  fplGuid: '',
  fplName: '',
  isPerformanceOnlyBook: false,
};

export const extendedApiFplSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFpls: builder.query({
      transformResponse: (baseQueryReturnValue?: FplType[]): FplType[] => baseQueryReturnValue ?? [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting FPL dropdown list. Error: ${response.status}`);
        return response;
      },
      query: () => Globals.API.endpoints.FPL,
      keepUnusedDataFor: 600,
    }),
    getFplStrategies: builder.query({
      transformResponse: (baseQueryReturnValue?: GridStrategyType[]): GridStrategyType[] =>
        baseQueryReturnValue
          ? [{ ...emptyGridStrategyRow, strategyShortCode: 'All', strategyName: 'All' }, ...baseQueryReturnValue]
          : [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting strategies by fplGuid. Error: ${response.status}`);
        return response;
      },
      query: (fplGuid: string) => `${Globals.API.endpoints.FplStrategies}/${fplGuid}`,
      keepUnusedDataFor: 600,
    }),
  }),
});

export const { useGetFplsQuery, useGetFplStrategiesQuery } = extendedApiFplSlice;
