import { Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import { useAppSelector } from '../../../hooks';
import { selectIsReadOnly, JobType, useGetBenchmarksQuery, GridStrategyType } from '../../../reducers';
import { PartialSearchFormik } from '../../Common';

const Benchmarks = () => {
  const isReadOnly = useAppSelector(selectIsReadOnly);
  const { setFieldValue, values } = useFormikContext<JobType>();
  const { data: benchmarks = [], isLoading: isGetBenchmarksLoading } = useGetBenchmarksQuery('');

  const populateStrategiesBmk = (name: string, value: string) => {
    const newStrategies = values.strategies?.map((strategy: GridStrategyType) => ({
      ...strategy,
      benchmarkShortCode:
        name === 'fkRequestedBmk' ? value : strategy.benchmarkShortCode === value ? '' : strategy.benchmarkShortCode,
      benchmark2ShortCode:
        name === 'fkSecondaryBmk' ? value : strategy.benchmark2ShortCode === value ? '' : strategy.benchmark2ShortCode,
      benchmarkName: '',
      benchmark2Name: '',
    }));
    setFieldValue('strategies', newStrategies);
  };

  const handleBmkChange = (name: string, newValue: string) => {
    setFieldValue(name, newValue);
    populateStrategiesBmk(name, newValue);
  };

  return (
    <>
      <h4>Select the primary and secondary benchmark, if applicable.</h4>
      <Stack direction="row" spacing={2}>
        <PartialSearchFormik
          disabled={isReadOnly}
          disabledValue={values.fkSecondaryBmk}
          id="primary-benchmark-partial-search-formik"
          label="Primary benchmark"
          loading={isGetBenchmarksLoading}
          name="fkRequestedBmk"
          onChange={handleBmkChange}
          optionKey="benchmarkShortCode"
          optionName="benchmarkName"
          options={benchmarks}
        />
        <PartialSearchFormik
          disabled={isReadOnly}
          disabledValue={values.fkRequestedBmk}
          id="secondary-benchmark-partial-search-formik"
          label="Secondary Benchmark"
          loading={isGetBenchmarksLoading}
          name="fkSecondaryBmk"
          onChange={handleBmkChange}
          optionKey="benchmarkShortCode"
          optionName="benchmarkName"
          options={benchmarks}
        />
      </Stack>
    </>
  );
};

export default Benchmarks;
