import { SelectChangeEvent, Tooltip, Select, MenuItem, CircularProgress, InputAdornment } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useFormikContext } from 'formik';
import { useAppSelector } from '../../../../hooks';
import {
  PlanType,
  selectIsReadOnly,
  JobType,
  useGetPlansByClientShortCodeQuery,
  JobNestedErrorsType,
  JobNestedTouchedType,
} from '../../../../reducers';

const PlanSelect = ({ api, row: { id }, value }: GridRenderCellParams) => {
  const rowsData = api.getSortedRows() as PlanType[];
  const isReadOnly = useAppSelector(selectIsReadOnly);

  const { errors = {}, setFieldValue, touched = {}, values } = useFormikContext<JobType>();

  const getPlansDropdownListResult = useGetPlansByClientShortCodeQuery(values.clientShortCode, {
    skip: !values.clientShortCode,
  });

  const handleChange = (event: SelectChangeEvent) => {
    const selectedPlan = getPlansDropdownListResult.data?.find((p) => p.planShortCode === event.target.value);
    const editedRows = rowsData.map((row) => {
      if (row.id === id) {
        return {
          ...row,
          planShortCode: selectedPlan?.planShortCode ?? '',
          planAssetValue: selectedPlan?.planAssetValue ?? 0,
        };
      } else return row;
    });
    setFieldValue('plans', editedRows);
  };

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const errorMessage = (errors as JobNestedErrorsType).plans?.[id]?.planShortCode;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const isTouched = (touched as JobNestedTouchedType).plans?.[id]?.planShortCode;
  const isValid = !(isTouched && errorMessage);
  const loading = getPlansDropdownListResult.isFetching;

  const renderLoading = () =>
    loading && (
      <InputAdornment position="end" sx={{ marginRight: '1rem' }}>
        <CircularProgress size={20} disableShrink />
      </InputAdornment>
    );

  return (
    <Tooltip title={!isValid && errorMessage}>
      <Select
        disabled={loading || isReadOnly}
        endAdornment={renderLoading()}
        error={!isValid}
        id={`${value}-select-id`}
        onChange={handleChange}
        sx={{ minWidth: '100%' }}
        value={value}
      >
        {getPlansDropdownListResult.data?.map((rowPlan, index) => (
          <MenuItem
            value={rowPlan.planShortCode}
            key={`${index}-${rowPlan.clientShortCode}`}
            id={`plans-${index}-${rowPlan.clientShortCode}`}
          >
            {rowPlan.planName}
          </MenuItem>
        ))}
      </Select>
    </Tooltip>
  );
};

export default PlanSelect;
