import { Stack, TextField } from '@mui/material';
import { useStrategiesContext } from '../StrategiesContext/StrategiesContext';

export const ResearchComments = () => {
  const { isReadOnly, setFieldValue, values } = useStrategiesContext();
  return (
    <>
      <h4>Comments for Research*</h4>
      <Stack width="100%" spacing={2} marginBottom="1rem" marginTop="1rem">
        <TextField
          disabled={isReadOnly}
          id="outlined-multiline-flexible"
          label="Research Comments"
          name="notesForRA"
          multiline
          maxRows={5}
          value={values.notesForRA || ''}
          onChange={({ target: { value } }) => setFieldValue('notesForRA', value)}
        />
      </Stack>
    </>
  );
};
