import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { useField } from 'formik';

interface MultiselectFormikPropsType {
  disabled: boolean;
  formatOptions?: (option: string) => string;
  id: string;
  label: string;
  loading?: boolean;
  name: string;
  options?: any[];
}

export const MultiSelectFormik = (props: MultiselectFormikPropsType) => {
  const { disabled = false, formatOptions, id, label, loading, name, options = [] } = props;
  const [field, meta, helpers] = useField(name);
  const { touched, error } = meta;
  const { setValue } = helpers;

  const isValid = !(touched && error);

  const renderLoading = () =>
    loading && (
      <InputAdornment position="end" sx={{ marginRight: '1rem' }}>
        <CircularProgress size={20} disableShrink />
      </InputAdornment>
    );

  return (
    <FormControl size="small" fullWidth error={!isValid}>
      <InputLabel id={`${label}-select-label`}>{label}</InputLabel>
      <Select
        autoWidth
        disabled={loading ?? disabled}
        endAdornment={renderLoading()}
        id={id || name}
        input={<OutlinedInput label={label} />}
        label={label}
        multiple
        name={name}
        onChange={({ target: { value } }) => setValue(value)}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((strategy: any) => (
              <Chip key={strategy} label={strategy} />
            ))}
          </Box>
        )}
        size="small"
        type={name}
        value={field.value || []}
      >
        {options.map((option: string) => (
          <MenuItem value={option} key={`${id}-${option}`} id={`${id}-${option}`}>
            <ListItemText primary={formatOptions ? formatOptions(option) : option} />
          </MenuItem>
        ))}
      </Select>
      {!isValid && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
