import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector, useFirstFpl } from '../../hooks';
import { isDeepEqual, mergeStrategiesAttributes } from '../../application/common';
import {
  GridStrategyType,
  JobType,
  selectIsStrategiesMergeCompleted,
  setIsGetMethodError,
  setIsStrategiesMergeCompleted,
  useGetJobQuery,
  useGetJobStrategiesByJobIDQuery,
} from '../../reducers';

export const FormValidations = () => {
  const dispatch = useAppDispatch();
  const { values, setFieldValue, initialValues } = useFormikContext<JobType>();

  const isStrategiesMergeCompleted = useAppSelector(selectIsStrategiesMergeCompleted);

  const getJobResult = useGetJobQuery(initialValues.jobID, { skip: !initialValues.jobID });
  const getJobStrategiesByJobIDResult = useGetJobStrategiesByJobIDQuery(initialValues.jobID, {
    skip: !initialValues.jobID,
    refetchOnMountOrArgChange: true,
  });

  const { getFirstFpl } = useFirstFpl();

  const updateFirstFpl = (strategies: GridStrategyType[], strategiesDrivingPerfOnlyBook: GridStrategyType[] = []) =>
    strategies.map(
      (strategy): GridStrategyType => ({
        ...strategy,
        isDrivingPerfOnlyBook: strategiesDrivingPerfOnlyBook.includes(strategy),
      }),
    );

  useEffect(() => {
    if (
      !isStrategiesMergeCompleted &&
      !getJobResult.isFetching &&
      !getJobStrategiesByJobIDResult.isFetching &&
      getJobResult.isSuccess &&
      getJobStrategiesByJobIDResult.isSuccess
    ) {
      if (getJobResult.data.strategies?.length !== getJobStrategiesByJobIDResult.data.length) {
        toast.error(`Error getting strategies by jobID. Error: SQL versus Denodo clash`);
        dispatch(setIsGetMethodError(true));
        return;
      }
      const mergedStrategies = mergeStrategiesAttributes({
        oldStrategies: getJobResult.data.strategies,
        newStrategies: getJobStrategiesByJobIDResult.data,
      });
      const { strategiesDrivingPerfOnlyBook } = getFirstFpl({ strategies: mergedStrategies });
      const newStrategies = updateFirstFpl(mergedStrategies, strategiesDrivingPerfOnlyBook);
      if (!isDeepEqual(newStrategies, values.strategies)) {
        setFieldValue('strategies', newStrategies);
      }
      dispatch(setIsStrategiesMergeCompleted(true));
    }
  }, [
    dispatch,
    getFirstFpl,
    getJobResult,
    getJobStrategiesByJobIDResult,
    isStrategiesMergeCompleted,
    setFieldValue,
    values.strategies,
  ]);

  useEffect(() => {
    const isCompleted = (strategy: GridStrategyType) => (strategy.firmShortCode ? strategy.firmLegalName : true);
    const isCompletedAll = values.strategies?.every((strategy) => isCompleted(strategy));
    if (!isCompletedAll) dispatch(setIsStrategiesMergeCompleted(false));
  }, [dispatch, values.strategies]);

  return null;
};
