import { extendedApiFirmsSlice } from '../reducers/firmsSlice';

export type DeleteFirmFunction = (firmsToDelete: string) => Promise<string | never>;
export const useDeleteFirm = () => {
  const [mutate, deleteFirmResult] = extendedApiFirmsSlice.useRunFirmPendingLitigationDeleteMutation();

  const deleteFirm: DeleteFirmFunction = async (firmToDelete) => {
    try {
      return await mutate(firmToDelete).unwrap();
    } catch (err: any) {
      console.error('Firm not deleted.');
      throw err;
    }
  };

  return {
    deleteFirmResult,
    deleteFirm,
  };
};
