import { Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { useEffect } from 'react';
import { DateSchema } from 'yup';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  DUE_DATE_DAYS_FORWARD_REVIEW,
  DUE_DATE_DAYS_FORWARD_SEARCH_BOOK,
  MEETING_DATE_DAYS_FORWARD,
  validationSchemas,
} from '../../../application/common';
import {
  selectIsReadOnly,
  selectRootState,
  useLazyGetQuarterEndDatesQuery,
  JobType,
  FormSteps,
  extendedApiQuarterEndDatesSlice,
} from '../../../reducers';
import { DatePickerFormik, SelectFormik } from '../../Common';

const SearchBookDates = () => {
  const dispatch = useAppDispatch();

  const isReadOnly = useAppSelector(selectIsReadOnly);
  const rootState = useAppSelector(selectRootState);

  const [getQuarterEndDates, getQuarterEndDatesResult] = useLazyGetQuarterEndDatesQuery();

  const { initialValues, setValues: setFormikValues, values } = useFormikContext<JobType>();
  const { validationSchema } = validationSchemas[FormSteps.MainSelection];

  const lastQuarterEndDatesQueryArgs = extendedApiQuarterEndDatesSlice.util
    .selectInvalidatedBy(rootState, ['QuarterEndDates'])
    .pop();
  useEffect(() => {
    if (initialValues.jobID !== values.jobID) return;
    const fieldsValidationSchema = validationSchema.fields as { dueDate: DateSchema };
    const isDueDateValid = fieldsValidationSchema.dueDate.isValidSync(values.dueDate);
    if (isDueDateValid) {
      const formatedDate = moment(values.dueDate).format('YYYY-MM-DD');
      getQuarterEndDates(formatedDate).then(({ data: _quarterEndDates = [] }: { data?: string[] }) => {
        if (_quarterEndDates.length) {
          const isAsofdateCharInTheList = _quarterEndDates.includes(String(values.asofdateChar));
          const isAsofdatePerfInTheList = _quarterEndDates.includes(String(values.asofdatePerf));
          setFormikValues({
            ...values,
            asofdateChar: isAsofdateCharInTheList ? values.asofdateChar : _quarterEndDates[0],
            asofdatePerf: isAsofdatePerfInTheList ? values.asofdatePerf : _quarterEndDates[0],
          });
        }
      });
    } else {
      if (values.asofdateChar || values.asofdatePerf) {
        setFormikValues({
          ...values,
          asofdateChar: '',
          asofdatePerf: '',
        });
      }
      if (lastQuarterEndDatesQueryArgs?.originalArgs) {
        dispatch(
          extendedApiQuarterEndDatesSlice.util.updateQueryData(
            'getQuarterEndDates',
            lastQuarterEndDatesQueryArgs.originalArgs,
            (datesDraft: string[]) => {
              datesDraft.length = 0;
            },
          ),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.dueDate, values.jobID]);

  const formatQuarterEndDatesOptions = (option: string) => moment(option).format('MM/DD/YYYY');

  return (
    <>
      <h4>Search book dates and job information</h4>
      <h5>
        Due Date requires a minimum of {DUE_DATE_DAYS_FORWARD_REVIEW} business days for Review and{' '}
        {DUE_DATE_DAYS_FORWARD_SEARCH_BOOK} business days for Search Book.
      </h5>
      <Stack direction={{ xs: 'column', sm: 'row' }} width="100%" justifyContent="space-between" spacing={2}>
        <DatePickerFormik
          disabled={isReadOnly}
          name="dueDate"
          label="Due Date*"
          id="due-date-id"
          minDaysForward={
            values.searchTemplate === 'Search Book' ? DUE_DATE_DAYS_FORWARD_SEARCH_BOOK : DUE_DATE_DAYS_FORWARD_REVIEW
          }
        />
        <DatePickerFormik
          disabled={isReadOnly}
          name="meetingDate"
          label="Meeting Date*"
          id="meeting-date-id"
          minDaysForward={MEETING_DATE_DAYS_FORWARD}
        />
      </Stack>
      <Stack direction={{ xs: 'column', sm: 'row' }} width="100%" justifyContent="space-between" spacing={2}>
        <SelectFormik
          disabled={isReadOnly}
          formatOptions={formatQuarterEndDatesOptions}
          id="performance-quarter-end-id"
          label="Performance Quarter End*"
          loading={getQuarterEndDatesResult.isFetching}
          name="asofdatePerf"
          options={getQuarterEndDatesResult.data}
        />
        <SelectFormik
          disabled={isReadOnly}
          formatOptions={formatQuarterEndDatesOptions}
          id="character-quarter-end-id"
          label="Character Quarter End*"
          loading={getQuarterEndDatesResult.isFetching}
          name="asofdateChar"
          options={getQuarterEndDatesResult.data}
        />
      </Stack>
    </>
  );
};

export default SearchBookDates;
