import { FormControl } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useField } from 'formik';
import moment, { Moment } from 'moment';
import business from 'moment-business';

interface DatePickerFormikPropsType {
  disabled: boolean;
  name: string;
  label: string;
  id: string;
  minDaysForward: number;
}

export const DatePickerFormik = (props: DatePickerFormikPropsType) => {
  const { disabled, id, label, minDaysForward, name } = props;
  const [field, meta, helpers] = useField(name);
  const { touched, error } = meta;
  const { setValue } = helpers;

  const isValid = !(touched && error);
  const minDate = business.addWeekDays(moment(), minDaysForward);

  const setNewDateValue = (value: Moment | null) => {
    if (value?.isValid()) setValue(value);
  };

  const handleDateAccept = (value: Moment | null) => {
    setNewDateValue(value);
  };

  const handleTextFieldBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => {
    setNewDateValue(moment(value, 'MM/DD/YYYY'));
  };

  return (
    <FormControl size="small" fullWidth error={!isValid}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          disabled={disabled}
          label={label}
          minDate={minDate}
          onAccept={handleDateAccept}
          slotProps={{
            textField: {
              id: id,
              helperText: !isValid && error,
              size: 'small',
              error: !isValid,
              onBlur: handleTextFieldBlur,
            },
          }}
          value={field.value ? moment(field.value) : null}
        />
      </LocalizationProvider>
    </FormControl>
  );
};
