import { Stack, FormControl, Button } from '@mui/material';
import { GridStrategyType } from '../../../../reducers';
import { FirmType, useGetFirmStrategiesMutation, useGetFirmsQuery } from '../../../../reducers/firmsSlice';
import { PartialSearch } from '../../../Common';
import { useStrategiesContext } from '../StrategiesContext/StrategiesContext';
import { useState } from 'react';

export const ClientStrategySelection = () => {
  const { isReadOnly, isStrategyInTheGrid, populateAllMissingFieldsAsync, saveJob, setFieldValue, values } =
    useStrategiesContext();
  const { strategies = [] } = values;

  const [selectedFirmStrategyShortCode, setSelectedFirmStrategyShortCode] = useState('');
  const [selectedFirmShortCode, setSelectedFirmShortCode] = useState('');
  const [firmStrategies, setFirmStrategies] = useState<GridStrategyType[]>([]);

  const getFirmsResult = useGetFirmsQuery('');
  const [getFirmStrategies, GetFirmStrategiesResult] = useGetFirmStrategiesMutation();

  const fetchFirmStrategies = async (newFirmShortCode: string) => {
    const result = await getFirmStrategies({
      jobID: values.jobID,
      firmShortCode: newFirmShortCode,
      fplStrategyGuids: strategies.map((strategy: GridStrategyType) => strategy.fplGuid),
    }).unwrap();
    setFirmStrategies(result);
  };

  const handleFirmChange = (newValueObj: FirmType) => {
    const { firmShortCode } = newValueObj;
    setSelectedFirmShortCode(firmShortCode);
    fetchFirmStrategies(firmShortCode);
  };

  const handleSelectFirmStrategiesFilter = async () => {
    const selectedFirmStrategy = firmStrategies.find(
      (firm: GridStrategyType) => firm.strategyShortCode === selectedFirmStrategyShortCode,
    );
    const alreadyOnGrid = strategies.some(
      (strategyToSearch: GridStrategyType) =>
        strategyToSearch.strategyShortCode === selectedFirmStrategy?.strategyShortCode,
    );

    if (!alreadyOnGrid && selectedFirmStrategy) {
      const newStrategies: GridStrategyType[] = [...strategies, selectedFirmStrategy];
      const newStrategyToTheGrid = await populateAllMissingFieldsAsync(newStrategies);

      setFieldValue('strategies', newStrategyToTheGrid);
      saveJob({ job: { ...values, strategies: newStrategyToTheGrid } });
    }
    if (!selectedFirmStrategy) console.warn(`Firm strategy is not found!`);
  };

  return (
    <>
      <h4>Select client requested strategies.</h4>
      <Stack direction="row" alignItems="flex-end" width="100%" spacing={2} marginBottom="1rem" marginTop="1rem">
        <Stack direction="column" width="100%" spacing={2}>
          <FormControl size="small" fullWidth>
            <PartialSearch
              disabled={isReadOnly}
              id="firm-selection-partial-search"
              label="Firm Selection"
              loading={getFirmsResult.isLoading}
              noOptionsText="No Firm"
              onChange={handleFirmChange}
              optionKey="firmShortCode"
              optionName="firmLegalName"
              options={getFirmsResult.data}
              value={selectedFirmShortCode}
            />
          </FormControl>
          <FormControl size="small" fullWidth>
            <PartialSearch
              disabled={!selectedFirmShortCode || isReadOnly}
              id="firm-strategy-partial-search"
              label="Firm Strategy"
              loading={GetFirmStrategiesResult.isLoading}
              noOptionsText="No Firm Strategies"
              onChange={(newValueObj: GridStrategyType) =>
                setSelectedFirmStrategyShortCode(newValueObj.strategyShortCode)
              }
              optionKey="strategyShortCode"
              optionName="strategyName"
              options={firmStrategies}
              value={selectedFirmStrategyShortCode}
            />
          </FormControl>
        </Stack>
        <Button
          variant="contained"
          onClick={handleSelectFirmStrategiesFilter}
          disabled={!selectedFirmStrategyShortCode || isStrategyInTheGrid(selectedFirmStrategyShortCode)}
        >
          Select
        </Button>
      </Stack>
    </>
  );
};
