import { Stack, Button } from '@mui/material';
import { GridStrategyType, PlanType, useGetIncumbentsQuery, emptyGridStrategyRow } from '../../../../reducers';
import { PartialSearch } from '../../../Common';
import { useStrategiesContext } from '../StrategiesContext/StrategiesContext';

export const IncumbentSelection = () => {
  const {
    errors,
    initialValues,
    isReadOnly,
    isStrategyInTheGrid,
    populateMissingFieldsAsync,
    saveJob,
    setFieldValue,
    touched,
    values,
  } = useStrategiesContext();

  const { plans = [], strategies = [] } = values;

  const handleIncumbent = (newValueObj: GridStrategyType) => {
    setFieldValue('strategyShortCode', newValueObj.strategyShortCode);
    setFieldValue('incumbentName', newValueObj.strategyName);
  };

  const plansShortCode = plans.map((plan: PlanType) => plan.planShortCode).toString();

  const getIncumbentsResult = useGetIncumbentsQuery(
    {
      jobID: initialValues.jobID,
      plansShortCode,
    },
    { skip: !(initialValues.jobID || plansShortCode) },
  );

  const addStrategyToTheGridByShortCodeAsync = async (
    listStrategies: GridStrategyType[] = [],
    strategyShortCode: string,
  ) => {
    const gridStrategyToAdd = listStrategies.find((strategy) => strategy.strategyShortCode === strategyShortCode);
    if (gridStrategyToAdd) {
      const newStrategy = {
        ...emptyGridStrategyRow,
        ...gridStrategyToAdd,
        type: gridStrategyToAdd.type.toUpperCase(),
      };
      const newStrategyToTheGrid = await populateMissingFieldsAsync(newStrategy);
      const newStrategies = [...strategies, newStrategyToTheGrid];
      setFieldValue('strategies', newStrategies);
      saveJob({ job: { ...values, strategies: newStrategies } });
    }
  };

  const handleSelectBtnClick = () => {
    addStrategyToTheGridByShortCodeAsync(getIncumbentsResult.data, values.strategyShortCode);
  };

  return (
    <>
      <h4>Select an Incumbent.</h4>
      <Stack direction="row" width="100%" spacing={2} marginBottom="1rem" marginTop="1rem" alignItems="flex-start">
        <PartialSearch
          disabled={isReadOnly}
          error={errors.strategyShortCode}
          id="incumbent-selection-partial-search"
          label="Incumbent Selection"
          loading={getIncumbentsResult.isFetching}
          noOptionsText="No Incumbents"
          onChange={handleIncumbent}
          optionKey="strategyShortCode"
          optionName="strategyName"
          options={getIncumbentsResult.data}
          touched={touched.strategyShortCode}
          value={values.strategyShortCode}
        />
        <Button
          variant="contained"
          onClick={handleSelectBtnClick}
          disabled={!values.strategyShortCode || isStrategyInTheGrid(values.strategyShortCode)}
        >
          Select
        </Button>
      </Stack>
    </>
  );
};
