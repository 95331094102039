import { apiSlice } from './apiSlice';
import * as Globals from '../application/common';
import { GridStrategyType } from './jobStrategySlice';
import { toast } from 'react-toastify';

export interface FirmType {
  firmLegalName: string;
  firmShortCode: string;
}

export interface FirmLitigationType {
  firmShortCode: string | undefined;
  dataSourceName: string;
  isLocked: boolean;
  dateLocked?: string;
  modifiedBy?: string;
  modifiedDate?: string;
  pendingLitigationDisclosure?: string | undefined;
}

export const emptyFirm = {
  firmLegalName: '',
  firmShortCode: '',
};

const firmTypeToInvalidate = 'Firm-pending-litigation';

export const extendedApiFirmsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFirms: builder.query({
      transformResponse: (baseQueryReturnValue?: FirmType[]): FirmType[] => baseQueryReturnValue ?? [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting firms dropdown list. Error: ${response.status}`);
        return response;
      },
      keepUnusedDataFor: 600,
      query: () => `${Globals.API.endpoints.Firms}`,
    }),
    getFirmStrategies: builder.mutation({
      transformResponse: (baseQueryReturnValue?: GridStrategyType[]): GridStrategyType[] => baseQueryReturnValue ?? [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting firm strategies. Error: ${response.status}`);
        return response;
      },
      query: (firmBody: object) => ({
        url: Globals.API.endpoints.FirmStrategies,
        method: 'POST',
        body: firmBody,
      }),
    }),
    getShadowFirmIntigation: builder.query({
      transformResponse: (baseQueryReturnValue: FirmLitigationType): FirmLitigationType =>
        baseQueryReturnValue as FirmLitigationType,
      transformErrorResponse: (response) => {
        toast.error(`Error getting firm lintigation. Error: ${response.status}`);
        return response;
      },
      query: (firmShortCode: string) => ({
        url: `${Globals.API.endpoints.ShadowFirmData}/${firmShortCode}`,
      }),
      providesTags: () => [{ type: firmTypeToInvalidate }],
    }),
    getFirmPendingLitigation: builder.mutation({
      transformResponse: (baseQueryReturnValue?: FirmLitigationType): FirmLitigationType =>
        baseQueryReturnValue! as FirmLitigationType,
      transformErrorResponse: (response) => {
        toast.error(`Error saving firm litigastion. Error: ${response.status}`);
        return response;
      },
      query: (firmBody: FirmLitigationType) => ({
        url: Globals.API.endpoints.ShadowFirmPendingLitigation,
        method: 'POST',
        body: firmBody,
      }),
      invalidatesTags: (result, error, arg) => [{ type: firmTypeToInvalidate }],
    }),
    runFirmPendingLitigationDelete: builder.mutation({
      transformResponse: (baseQueryReturnValue?: string): string => baseQueryReturnValue ?? '',
      transformErrorResponse: (response) => {
        toast.error(`Error running firm pending litigation delete. Error: ${response.status}`);
        return response;
      },
      query: (firmToDelete: string) => ({
        url: `${Globals.API.endpoints.ShadowFirmPendingLitigation}/${firmToDelete}`,
        method: 'DELETE',
        responseHandler: (response) => response.text(),
      }),
      invalidatesTags: (result, error, arg) => [{ type: firmTypeToInvalidate }],
    }),
  }),
});

export const {
  useGetFirmsQuery,
  useGetFirmStrategiesMutation,
  useGetShadowFirmIntigationQuery,
  useGetFirmPendingLitigationMutation,
  useRunFirmPendingLitigationDeleteMutation,
} = extendedApiFirmsSlice;
