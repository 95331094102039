import { Divider, FormControl, styled as MuiStyled, Stack } from '@mui/material';
import styled from 'styled-components';

export const MainTabContent = styled.div`
  min-width: 10rem;
  padding: 1rem;
  > *:not(:first-child) {
    margin: 1rem 0;
  }
  h3 {
    color: #002060;
  }
`;

export const Fields = MuiStyled(Stack)`
  width: 100%;
  margin-bottom: 1rem;
  .MuiStack-root {
    width: 100%;
    .MuiButtonBase-root {
      text-wrap: nowrap;
    }
  }
`;

export const ShadowFormControl = MuiStyled(FormControl)`
  margin: 0.5rem 0 0.5rem 0;
`;

export const FormDivider = MuiStyled(Divider)`
  margin: 1rem 0;
`;

export const ShadowStack = MuiStyled(Stack)`
  display: flex;
  padding: 1rem;

  width: 90%;

  border: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: ${({ theme }) => theme.palette.primary.main};

  background-color: #ffffff;
`;

export const SelectGroup = styled.div`
  display: inline-flex;
  gap: 1rem;
  button {
    align-self: center;
  }
`;
