import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton, Stack } from '@mui/material';
import { DataGrid, GridColDef, GridValueFormatterParams, useGridApiRef } from '@mui/x-data-grid';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useAppSelector } from '../../../../hooks';
import { inputSequentialIds } from '../../../../application/common';
import { ClientType, JobType, PlanType, selectIsReadOnly, useGetClientsQuery } from '../../../../reducers';
import { PartialSearchFormik } from '../../../Common';
import MandateSizeInput from './MandateSizeInput';
import PlanSelect from './PlanSelect';

const PlansGrid = () => {
  const isReadOnly = useAppSelector(selectIsReadOnly);
  const { data: clients = [], isLoading: isClientLoading } = useGetClientsQuery('') as {
    data: ClientType[];
    isLoading: boolean;
  };
  const { values, setValues: setFormikValues } = useFormikContext<JobType>();

  const apiRef = useGridApiRef();

  const { plans = [] } = values;

  const [plansWithId, setPlansWithID] = useState<PlanType[]>([]);

  const emptyRow: PlanType = {
    clientShortCode: '',
    id: plans.length,
    jobID: values.jobID,
    jobPlanID: null,
    planAssetValue: 0,
    planMandateSize: 0,
    planShortCode: '',
  };

  useEffect(() => {
    setPlansWithID(inputSequentialIds(plans) as PlanType[]);
  }, [plans]);

  const columns: GridColDef[] = [
    {
      field: 'deleteRow',
      headerName: '',
      width: 50,
      renderCell: (params: any) => (
        <IconButton disabled={isReadOnly} aria-label="delete" onClick={() => deleteRow(params.row.id)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
    {
      field: 'planShortCode',
      headerName: 'Plan*',
      headerAlign: 'center',
      width: 600,
      cellClassName: 'plan-grid-dropdown',
      renderCell: PlanSelect,
    },
    {
      field: 'planAssetValue',
      headerName: 'Plan size',
      headerAlign: 'center',
      cellClassName: 'plan-size-value',
      width: 150,
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        params.value ? Number(params.value).toLocaleString() : '',
    },
    {
      field: 'planMandateSize',
      headerName: 'Mandate Size*',
      headerAlign: 'center',
      width: 110,
      renderCell: MandateSizeInput,
    },
  ];

  const handleClientChange = (name: string, newValue: string) => {
    if (plans.some((p) => p.planMandateSize > 0 || p.planShortCode)) {
      toast.warn('Client changed. The old plans were removed.');
    }
    setFormikValues({
      ...values,
      [name]: newValue,
      plans: [{ ...emptyRow, clientShortCode: newValue, planMandateSize: 0 }],
    });
  };

  const addRow = () => {
    if (values.clientShortCode !== '') {
      setFormikValues({ ...values, plans: [...plans, { ...emptyRow, clientShortCode: values.clientShortCode }] });
    }
  };

  const deleteRow = (id: number) => {
    if (plans.length === 1) {
      return;
    }

    apiRef.current.updateRows([{ id: id, _action: 'delete' }]);
    return setFormikValues({
      ...values,
      plans: [...plans.slice(0, id), ...plans.slice(id + 1)],
    });
  };

  return (
    <>
      <h4>Select the plan(s) and enter the mandate size</h4>
      <Stack direction="row" justifyContent="space-between" alignItems="self-start" width="100%">
        <PartialSearchFormik
          disabled={isReadOnly}
          id="client-partial-search-formik"
          label="Client*"
          loading={isClientLoading}
          name="clientShortCode"
          onChange={handleClientChange}
          optionKey="clientShortCode"
          optionName="clientName"
          options={clients}
          sx={{ width: '20rem' }}
        />
        <Button
          disabled={!values.clientShortCode || isReadOnly}
          onClick={addRow}
          sx={{ width: '8rem' }}
          variant="contained"
        >
          Add row
        </Button>
      </Stack>
      <DataGrid
        apiRef={apiRef}
        rows={plansWithId}
        columns={columns}
        editMode="row"
        autoHeight
        hideFooter
        sx={{
          boxShadow: 0,
          '.MuiSelect-select,.MuiInputBase-input': {
            padding: '8px',
          },
          'input.MuiInputBase-input:not(.MuiAutocomplete-input)': {
            textAlign: 'end',
          },
          '.plan-size-value': {
            paddingRight: '1rem !important',
          },
          '.MuiDataGrid-cell': {
            padding: 0,
          },
        }}
        isCellEditable={() => !isReadOnly}
      />
    </>
  );
};

export default PlansGrid;
