import { Checkbox, FormControlLabel, Stack, TextField, Tooltip } from '@mui/material';
import { ChangeEvent } from 'react';
import { setObjectProperty } from '../../../../application/common';
import {
  BenchmarkType,
  FeeType,
  GridStrategyType,
  VehicleType,
  useGetBenchmarksQuery,
  useGetFeesQuery,
  useGetVehiclesByStrategyIDQuery,
} from '../../../../reducers';
import { PartialSearch } from '../../../Common';
import { useStrategiesContext } from '../StrategiesContext';

export const StrategiesGridEdit = () => {
  const {
    isReadOnly,
    isStrategiesGridLocked,
    saveJob,
    selectedJobStrategyRow,
    setFieldValue,
    setSelectedJobStrategyRow,
    values,
  } = useStrategiesContext();

  const { data: benchmarks = [], isFetching: isGetBenchmarksFetching } = useGetBenchmarksQuery('');
  const { data: vehicles = [], isFetching: isGetVehiclesFetching } = useGetVehiclesByStrategyIDQuery(
    selectedJobStrategyRow.strategyShortCode,
    { skip: !selectedJobStrategyRow.strategyShortCode },
  );
  const { data: fees = [], isFetching: isGetFeesFetching } = useGetFeesQuery(selectedJobStrategyRow.strategyShortCode, {
    skip: !selectedJobStrategyRow.strategyShortCode,
  });

  const { strategies = [] } = values;

  const setStrategiesFieldValue = (editedRow: GridStrategyType) => {
    const newStrategies = [
      ...strategies.slice(0, selectedJobStrategyRow.id),
      editedRow,
      ...strategies.slice(selectedJobStrategyRow.id + 1),
    ];
    setFieldValue('strategies', newStrategies);
    saveJob({ job: { ...values, strategies: newStrategies } });
  };

  const handleTextFieldChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const _selectedRow = setObjectProperty(selectedJobStrategyRow, name, value) as GridStrategyType;
    setSelectedJobStrategyRow(_selectedRow);
  };

  const handleTextFieldBlur = (name: string, value: string) => {
    const _selectedRow = setObjectProperty(selectedJobStrategyRow, name, value) as GridStrategyType;
    setSelectedJobStrategyRow(_selectedRow);
    setStrategiesFieldValue(_selectedRow);
  };

  const handleVehicleSelectChange = ({ vehicleID, grossOrNet, planHoldingID, valueName, value }: any) => {
    const _selectedRow: GridStrategyType = {
      ...selectedJobStrategyRow,
      vehicleID,
      grossOrNet,
      planHoldingID,
      [valueName]: vehicleID ? value : '',
    };
    setSelectedJobStrategyRow(_selectedRow);
    setStrategiesFieldValue(_selectedRow);
  };

  const handleSelectChange = ({ keyName, key, valueName, value }: any) => {
    const _selectedRow = {
      ...selectedJobStrategyRow,
      [keyName]: key,
      [valueName]: key ? value : '',
    };
    setSelectedJobStrategyRow(_selectedRow);
    setStrategiesFieldValue(_selectedRow);
  };

  const handlePerfOnlyToggle = () => {
    const _selectedRow = setObjectProperty(
      selectedJobStrategyRow,
      'isPerformanceOnly',
      !selectedJobStrategyRow.isPerformanceOnly,
    ) as GridStrategyType;
    setSelectedJobStrategyRow(_selectedRow);
    setStrategiesFieldValue(_selectedRow);
  };

  const isAnyRowSelected = Boolean(selectedJobStrategyRow.jobID);
  const isEditableFieldsDisabled = isStrategiesGridLocked || !isAnyRowSelected || isReadOnly;

  return (
    <>
      <Stack width="100%" spacing={2} marginBottom="1rem" marginTop="1rem">
        <Stack spacing={1}>
          <Stack direction="row" spacing={1} justifyContent="flex-start">
            <h5 className="data-title">Strategy Details:</h5>
            <h5>{selectedJobStrategyRow.strategyShortCode}</h5>
          </Stack>
          <Stack direction="row" spacing={1} justifyContent="flex-start">
            <h5 className="data-title">Strategy:</h5>
            <h5>{selectedJobStrategyRow.strategyName}</h5>
          </Stack>
          <Stack direction="row" spacing={1} justifyContent="flex-start">
            <Tooltip title={isAnyRowSelected && values.isPerformanceOnlyBook && 'Performance Only Book'}>
              <FormControlLabel
                value="Perf Only"
                control={
                  <Checkbox
                    id="perf-only-chk"
                    checked={selectedJobStrategyRow.isPerformanceOnly || false}
                    disabled={values.isPerformanceOnlyBook || isEditableFieldsDisabled}
                    onChange={() => handlePerfOnlyToggle()}
                  />
                }
                label="Perf Only"
                sx={{ whiteSpace: 'nowrap' }}
              />
            </Tooltip>
          </Stack>
        </Stack>
        <PartialSearch
          disabled={isEditableFieldsDisabled}
          id="performance-vehicle-partial-search"
          label="Performance Vehicle"
          loading={isGetVehiclesFetching}
          onChange={(newValueObj: VehicleType) =>
            handleVehicleSelectChange({
              vehicleID: newValueObj.vehicleID,
              grossOrNet: newValueObj.grossOrNet,
              planHoldingID: newValueObj.planHoldingID,
              valueName: 'vehicleName',
              value: newValueObj.vehicleName,
            })
          }
          optionKey="vehicleIDWithGrossOrNet"
          optionName="vehicleName"
          options={vehicles.map((vehicle) => ({
            ...vehicle,
            vehicleIDWithGrossOrNet: `${Number(vehicle.planHoldingID)}-${vehicle.vehicleID}-${vehicle.grossOrNet}`,
          }))}
          value={`${Number(selectedJobStrategyRow.planHoldingID)}-${selectedJobStrategyRow.vehicleID}-${selectedJobStrategyRow.grossOrNet}`}
        />
        <PartialSearch
          disabled={isEditableFieldsDisabled}
          id="fees-for-performance-partial-search"
          label="Fees for performance"
          loading={isGetFeesFetching}
          onChange={(newValueObj: FeeType) =>
            handleSelectChange({
              keyName: 'feeID',
              key: newValueObj.feeID,
              valueName: 'feeName',
              value: newValueObj.feeName,
            })
          }
          optionKey="feeID"
          optionName="feeName"
          options={fees}
          value={selectedJobStrategyRow.feeID}
        />
        <PartialSearch
          disabled={isEditableFieldsDisabled}
          disabledValue={selectedJobStrategyRow.benchmark2ShortCode}
          id="strategy-benchmark-partial-search"
          label="Strategy Benchmark"
          loading={isGetBenchmarksFetching}
          onChange={(newValueObj: BenchmarkType) =>
            handleSelectChange({
              keyName: 'benchmarkShortCode',
              key: newValueObj.benchmarkShortCode,
              valueName: 'benchmarkName',
              value: newValueObj.benchmarkName,
            })
          }
          optionKey="benchmarkShortCode"
          optionName="benchmarkName"
          options={benchmarks}
          value={selectedJobStrategyRow.benchmarkShortCode}
        />
        <PartialSearch
          disabled={isEditableFieldsDisabled}
          disabledValue={selectedJobStrategyRow.benchmarkShortCode}
          id="secondary-benchmark-partial-search"
          label="Secondary Benchmark"
          loading={isGetBenchmarksFetching}
          onChange={(newValueObj: BenchmarkType) =>
            handleSelectChange({
              keyName: 'benchmark2ShortCode',
              key: newValueObj.benchmarkShortCode,
              valueName: 'benchmark2Name',
              value: newValueObj.benchmarkName,
            })
          }
          optionKey="benchmarkShortCode"
          optionName="benchmarkName"
          options={benchmarks}
          value={selectedJobStrategyRow.benchmark2ShortCode}
        />
        <TextField
          disabled={isEditableFieldsDisabled}
          id="outlined-multiline-flexible"
          label="Grouping"
          name="grouping"
          onBlur={({ target: { name, value } }) => handleTextFieldBlur(name, value)}
          onChange={handleTextFieldChange}
          size="small"
          value={selectedJobStrategyRow.grouping || ''}
        />
      </Stack>
    </>
  );
};
