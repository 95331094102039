import { Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Tooltip } from '@mui/material';
import { GridFilterModel } from '@mui/x-data-grid';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import moment from 'moment';
import { createContext, useContext, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { GetMinId, shadowVehicleUpdateValitationSchema } from '../../../application/common';
import { GridStrategyType, useGetVehiclesByStrategyIDQuery, VehicleType } from '../../../reducers';
import { useGetFirmsQuery } from '../../../reducers/firmsSlice';
import { useGetFirmStrategiesCharQuery } from '../../../reducers/strategyCharacteristicsSlice';
import { PartialSearch } from '../../Common';
import { useShadowContext } from '../Shadow';
import { Fields, MainTabContent } from '../StyledShadow';
import { DeleteVehiclesFunction, useDeleteVehicle } from './ShadowDeleteVehicleHook';
import { SaveVehiclesFunction, useSubmitVehicle } from './ShadowSubmitVehicleHook';
import { performanceType, useGetVehiclesForPerformanceQuery } from './ShadowVehicleSlice';
import { ShadowVehiclesGrid } from './ShadowVehiclesGrid';

export interface ShadowVehicleTypeValues {
  selectedFirmShortCode: string;
  selectedFirmStrategyShortCode: string;
  selectedVehicleShortCode: string;
  vehicles: GridPerformanceType[];
}

export interface GridPerformanceType extends performanceType {
  id: number;
  isTouched: boolean;
}

export interface VehiclesContextType {
  initialValues: ShadowVehicleTypeValues;
  isReadOnly: boolean;
  isSaveVehiclesLoading: boolean;
  isVehiclesGridLocked: boolean;
  submitVehicles: SaveVehiclesFunction;
  deleteVehicles: DeleteVehiclesFunction;
  isSaveVehiclesDeleteLoading: boolean;
  isVehiclesForPerformanceFetching: boolean;
  setFieldValue: FormikHelpers<ShadowVehicleTypeValues>['setFieldValue'];
  setIsVehiclesGridLocked: React.Dispatch<React.SetStateAction<boolean>>;
  values: ShadowVehicleTypeValues;
  filterModel: GridFilterModel;
}

const emptyVehiclesValues: ShadowVehicleTypeValues = {
  selectedFirmShortCode: '',
  selectedFirmStrategyShortCode: '',
  selectedVehicleShortCode: '',
  vehicles: [],
};

const emptyVehicleContext: VehiclesContextType = {
  ...emptyVehiclesValues,
  initialValues: emptyVehiclesValues,
  isReadOnly: false,
  isSaveVehiclesLoading: false,
  isVehiclesGridLocked: false,
  submitVehicles: async () => [],
  deleteVehicles: async () => '',
  isSaveVehiclesDeleteLoading: false,
  isVehiclesForPerformanceFetching: false,
  setFieldValue: () => new Promise(() => ({})),
  setIsVehiclesGridLocked: () => ({}),
  values: emptyVehiclesValues,
  filterModel: {
    items: [],
  },
};

export const VehiclesContext = createContext(emptyVehicleContext);
export const useVehiclesContext = () => useContext(VehiclesContext);

export const VehiclesForPerformance = () => {
  const currentYear = moment().year();
  const [year, setYear] = useState<number>(0);
  const [month, setMonth] = useState<number>(0);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  const [selectedVehicleKey, setSelectedVehicleKey] = useState<string>();
  const {
    selectedFirmShortCode,
    setSelectedFirmShortCode,
    selectedFirmStrategyShortCode,
    setSelectedFirmStrategyShortCode,
    selectedVehicleShortCode,
    setSelectedVehicleShortCode,
    selectedVehicleGrossOrNet,
    setSelectedVehicleGrossOrNet,
  } = useShadowContext();

  const newRow = (vehicles: GridPerformanceType[]) => {
    const performanceId = GetMinId(vehicles) - 1;
    return {
      id: performanceId,
      asOfDate: moment().format('YYYY-MM-DD'),
      performanceId,
      dataSourceName: 'NEPC Created',
      firmShortCode: selectedFirmShortCode,
      strategyShortCode: selectedFirmStrategyShortCode,
      vehicleId: Number(selectedVehicleShortCode),
      grossOrNet: selectedVehicleGrossOrNet,
      returnInDecimal: 0,
      year: currentYear,
      month: moment().month(),
      compDate: `${moment().month()}-${currentYear}`,
      isTouched: true,
    };
  };

  const getFirmsStrategiesResult = useGetFirmStrategiesCharQuery(selectedFirmShortCode, {
    skip: !selectedFirmShortCode,
  });

  const getVehiclesResult = useGetVehiclesByStrategyIDQuery(selectedFirmStrategyShortCode, {
    skip: !selectedFirmStrategyShortCode,
  });

  const getFirmsResult = useGetFirmsQuery('');

  const getVehiclesForPerformanceResult = useGetVehiclesForPerformanceQuery(
    { selectedFirmShortCode, selectedFirmStrategyShortCode, selectedVehicleShortCode, selectedVehicleGrossOrNet },
    {
      skip: !selectedVehicleShortCode,
    },
  );

  const { submitVehicles, submitVehiclesResult } = useSubmitVehicle();
  const { isLoading: isSaveVehiclesLoading } = submitVehiclesResult;

  const { deleteVehicles, deleteVehicleResult } = useDeleteVehicle();
  const { isLoading: isSaveVehiclesDeleteLoading } = deleteVehicleResult;

  const vehiclesRows = useMemo(() => {
    if (getVehiclesForPerformanceResult.data && selectedVehicleShortCode) {
      return [...getVehiclesForPerformanceResult.data]
        .map((vehicle) => ({ ...vehicle, id: vehicle.performanceId }))
        .sort((a, b) => a.performanceId - b.performanceId);
    }
    return [];
  }, [getVehiclesForPerformanceResult, selectedVehicleShortCode]);

  const hasErrors = (errors: FormikErrors<ShadowVehicleTypeValues>) =>
    Boolean(errors.vehicles?.length && errors.vehicles.length > 0 && selectedVehicleShortCode);

  const errorMessage = (errors: FormikErrors<ShadowVehicleTypeValues>): string => {
    let errorMessage = null;
    errorMessage = errors.selectedFirmShortCode ?? errors.selectedFirmStrategyShortCode;
    const vehiclesMessage = Object.values(errors.vehicles?.[errors.vehicles.length - 1] ?? [])[0];
    return errorMessage ?? vehiclesMessage;
  };

  const initialVehiclesValues: ShadowVehicleTypeValues = {
    selectedFirmShortCode: selectedFirmShortCode,
    selectedFirmStrategyShortCode: selectedFirmStrategyShortCode,
    selectedVehicleShortCode: selectedVehicleShortCode,
    vehicles: vehiclesRows as GridPerformanceType[],
  };

  const avaliableYears = useMemo<number[]>(() => {
    const trinnedYears: number[] = [];
    vehiclesRows.forEach((vehicleRow: any) => {
      if (trinnedYears.includes(vehicleRow.year)) {
        return;
      }
      trinnedYears.push(vehicleRow.year);
    });
    return trinnedYears;
  }, [vehiclesRows]);

  const applyFilter = (_year: number, _month: number) => {
    const filterValue = `${_month === 0 ? '' : _month}-${_year === 0 ? '' : _year}`;
    setFilterModel({
      items: [
        {
          field: 'compDate',
          operator: 'contains',
          value: filterValue,
        },
      ],
    });
  };

  const MonthFilterSelector = () => (
    <FormControl fullWidth size="small">
      <InputLabel id="month-filter-select-label">Month</InputLabel>
      <Select
        labelId="month-filter-select"
        id="month-filter-select-id"
        defaultValue={0}
        value={month}
        label="Month"
        onChange={(event: SelectChangeEvent<number>) => {
          const monthValue = event.target.value as number;
          setMonth(monthValue);
          applyFilter(year, monthValue);
        }}
        disabled={!selectedVehicleShortCode}
      >
        <MenuItem value={0} key={`AllMonths-select-filter`}>
          All
        </MenuItem>
        <MenuItem value={3} key={`3-Months-select-filter`}>
          3
        </MenuItem>
        <MenuItem value={6} key={`6-Months-select-filter`}>
          6
        </MenuItem>
        <MenuItem value={9} key={`9-Months-select-filter`}>
          9
        </MenuItem>
        <MenuItem value={12} key={`12-Months-select-filter`}>
          12
        </MenuItem>
      </Select>
    </FormControl>
  );

  const YearFilterSelector = () => (
    <FormControl fullWidth size="small">
      <InputLabel id="year-filter-select-label">Year</InputLabel>
      <Select
        labelId="year-filter-select"
        id="year-filter-select-id"
        value={year}
        defaultValue={0}
        label="Year"
        onChange={(event: SelectChangeEvent<number>) => {
          const yearValue = event.target.value as number;
          setYear(yearValue);
          applyFilter(yearValue, month);
        }}
        disabled={!selectedVehicleShortCode}
      >
        <MenuItem value={0} key={`AllYears-select-filter`}>
          All
        </MenuItem>
        {avaliableYears.map((year: number) => (
          <MenuItem value={year} key={`${year}-year-select-filter`}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <Formik
      initialValues={initialVehiclesValues}
      validationSchema={shadowVehicleUpdateValitationSchema}
      onSubmit={() => undefined}
      enableReinitialize
      validateOnMount
      validateOnChange
      validateOnBlur
    >
      {({ errors, values, setFieldValue, touched }) => (
        <VehiclesContext.Provider
          value={{
            ...emptyVehicleContext,
            filterModel,
            submitVehicles,
            isSaveVehiclesLoading,
            deleteVehicles,
            isSaveVehiclesDeleteLoading,
            isVehiclesForPerformanceFetching: getVehiclesForPerformanceResult.isFetching,
            setFieldValue,
            values,
          }}
        >
          <MainTabContent>
            <Fields spacing={2} direction="column">
              <Stack direction="row" gap={1}>
                <Stack gap={1} direction="column" whiteSpace="nowrap" alignItems="start" minWidth="70%">
                  <PartialSearch
                    disabled={!getFirmsResult.data}
                    id="firm-partial-search"
                    label="Firm"
                    loading={getFirmsResult.isLoading}
                    onChange={(newValueObj: GridStrategyType) => {
                      setFieldValue('selectedFirmShortCode', newValueObj.firmShortCode);
                      setSelectedFirmShortCode(newValueObj.firmShortCode);
                      setSelectedFirmStrategyShortCode('');
                      setSelectedVehicleShortCode('');
                      setSelectedVehicleKey('');
                    }}
                    noOptionsText="No Firms"
                    optionKey="firmShortCode"
                    optionName="firmLegalName"
                    options={getFirmsResult.data}
                    value={selectedFirmShortCode}
                    sx={{ minWidth: '100%' }}
                  />
                  <PartialSearch
                    disabled={!getFirmsStrategiesResult.data || selectedFirmShortCode === ''}
                    id="strategy-partial-search"
                    label="Strategy"
                    loading={getFirmsStrategiesResult.isLoading}
                    onChange={(newValueObj: GridStrategyType) => {
                      setFieldValue('selectedFirmStrategyShortCode', newValueObj.strategyShortCode);
                      setSelectedFirmStrategyShortCode(newValueObj.strategyShortCode);
                      setSelectedVehicleKey('');
                      setSelectedVehicleShortCode('');
                    }}
                    noOptionsText="No strategies"
                    optionKey="strategyShortCode"
                    optionName="strategyName"
                    options={getFirmsStrategiesResult.data}
                    value={selectedFirmStrategyShortCode}
                    sx={{ minWidth: '100%' }}
                  />
                  <PartialSearch
                    disabled={!getVehiclesResult.data || selectedFirmStrategyShortCode === ''}
                    id="vehicle-partial-search"
                    label="Vehicle"
                    loading={getVehiclesResult.isFetching}
                    onChange={(vehicle: VehicleType) => {
                      setFieldValue('selectedVehicleShortCode', vehicle.vehicleID);
                      setFieldValue('grossOrNet', vehicle.grossOrNet);
                      setSelectedVehicleShortCode(vehicle.vehicleID);
                      setSelectedVehicleKey(`${vehicle.planHoldingID}${vehicle.grossOrNet}${vehicle.vehicleID}`);
                      setSelectedVehicleGrossOrNet(vehicle.grossOrNet);
                    }}
                    noOptionsText="No Vehicles"
                    optionKey="vehicleKey"
                    optionName="vehicleName"
                    options={getVehiclesResult.data?.map((vehicle) => ({
                      ...vehicle,
                      vehicleKey: `${vehicle.planHoldingID}${vehicle.grossOrNet}${vehicle.vehicleID}`,
                    }))}
                    value={selectedVehicleKey ?? ''}
                    sx={{ minWidth: '100%' }}
                  />
                </Stack>
                <Stack direction="column" justifyContent="space-around" width="30%">
                  <div>
                    Firm ShortCode: <strong>{selectedFirmShortCode}</strong>
                  </div>
                  <div>
                    Firm Strategy ShortCode: <strong>{selectedFirmStrategyShortCode}</strong>
                  </div>
                  <div>
                    Firm Vehicle ShortCode: <strong>{selectedVehicleShortCode}</strong>
                  </div>
                </Stack>
              </Stack>
            </Fields>
            <Stack direction="row" justifyContent="space-between" alignItems="baseline" p={1}>
              <Stack direction="row" width={{ xs: '50%', sm: '50%', md: '35%', lg: '25%', xl: '20%' }} p={1} gap={2}>
                {YearFilterSelector()}
                {MonthFilterSelector()}
              </Stack>
              <Stack alignItems="flex-end">
                <Button
                  id="add-fee-btn-id"
                  disabled={!values.selectedVehicleShortCode}
                  variant="contained"
                  size="small"
                  onClick={() =>
                    values.selectedVehicleShortCode
                      ? setFieldValue('vehicles', [...values.vehicles, newRow(values.vehicles)])
                      : null
                  }
                >
                  Add Row
                </Button>
              </Stack>
            </Stack>
            <ShadowVehiclesGrid />
            <Stack alignItems="flex-end">
              <Tooltip title={errorMessage(errors)}>
                <span>
                  <Button
                    id="save-fee-btn-id"
                    disabled={hasErrors(errors)}
                    variant="contained"
                    onClick={() =>
                      submitVehicles(values.vehicles.filter((a: GridPerformanceType) => a.isTouched)).then(() =>
                        toast.success('Vehicles Saved Successfully'),
                      )
                    }
                  >
                    Save
                  </Button>
                </span>
              </Tooltip>
            </Stack>
          </MainTabContent>
        </VehiclesContext.Provider>
      )}
    </Formik>
  );
};
