import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

export const FormSteps = {
  MainSelection: 0,
  StrategySelections: 1,
  ManagerSubmission: 2,
};

export interface UiType {
  activeStep: number;
  dvEmailBody: string;
  isGetMethodError: boolean;
  isReadOnly: boolean;
  isStrategiesMergeCompleted: boolean;
}

const initialState: UiType = {
  activeStep: FormSteps.MainSelection,
  dvEmailBody: '',
  isGetMethodError: false,
  isReadOnly: false,
  isStrategiesMergeCompleted: false,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setActiveStep: (state, action: PayloadAction<number>) => {
      if (action.payload >= 0) {
        state.activeStep = action.payload;
      }
    },
    setDvEmailBody: (state, action: PayloadAction<string>) => {
      state.dvEmailBody = action.payload;
    },
    setIsGetMethodError: (state, action: PayloadAction<boolean>) => {
      state.isGetMethodError = action.payload;
    },
    setIsReadOnly: (state, action: PayloadAction<boolean>) => {
      state.isReadOnly = action.payload;
    },
    setIsStrategiesMergeCompleted: (state, action: PayloadAction<boolean>) => {
      state.isStrategiesMergeCompleted = action.payload;
    },
  },
});

export const selectDvEmailBody = (state: RootState): string => state.ui.dvEmailBody;
export const selectIsGetMethodError = (state: RootState): boolean => state.ui.isGetMethodError;
export const selectIsReadOnly = (state: RootState): boolean => state.ui.isReadOnly;
export const selectIsStrategiesMergeCompleted = (state: RootState): boolean => state.ui.isStrategiesMergeCompleted;
export const selectUiActiveStep = (state: RootState): number => state.ui.activeStep;

export const selectRootState = (state: RootState): RootState => state;

export const { setActiveStep, setDvEmailBody, setIsGetMethodError, setIsReadOnly, setIsStrategiesMergeCompleted } =
  uiSlice.actions;
export default uiSlice.reducer;
