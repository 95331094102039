import { GridStrategyType, FplType, useGetFplsQuery } from '../reducers';

export type GetFirstFplFunction = (arg: { strategies?: GridStrategyType[] }) => {
  firstFpl?: FplType;
  strategiesDrivingPerfOnlyBook?: GridStrategyType[];
};
export const useFirstFpl = () => {
  const getFplsResult = useGetFplsQuery('');

  const getFirstFpl: GetFirstFplFunction = ({
    strategies = [],
  }: {
    strategies?: GridStrategyType[];
  }): { firstFpl?: FplType; strategiesDrivingPerfOnlyBook?: GridStrategyType[] } => {
    const firstFplFirstStrategy = strategies
      .filter((strategy) => strategy.type === 'FPL')
      .sort((a, b) => a.jobStrategyID - b.jobStrategyID)
      .find((strategy) => strategy.strategyShortCode);
    const firstFpl = getFplsResult.data?.find((fpl) => fpl.fplGuid === firstFplFirstStrategy?.fplGuid);
    const secondFplfirstStrategy = strategies
      .filter((strategy) => strategy.type === 'FPL' && strategy.fplGuid !== firstFpl?.fplGuid)
      .sort((a, b) => a.jobStrategyID - b.jobStrategyID)
      .find((strategy) => strategy.strategyShortCode);
    const strategiesDrivingPerfOnlyBook = strategies.filter(
      (strategy) =>
        firstFpl?.isPerformanceOnlyBook &&
        strategy.fplGuid === firstFpl.fplGuid &&
        (secondFplfirstStrategy ? strategy.jobStrategyID < secondFplfirstStrategy.jobStrategyID : true),
    );
    return {
      firstFpl,
      strategiesDrivingPerfOnlyBook,
    };
  };
  return { getFirstFpl };
};
