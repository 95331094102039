import { useAppSelector } from '../../../../hooks';
import {
  MIN_STRATEGIES,
  MAX_STRATEGIES,
  MIN_BENCHMARKS,
  MAX_BENCHMARKS,
  MIN_FPL_STRATEGIES,
  getBenchmarksCounter,
} from '../../../../application/common';
import { GridStrategyType, JobType, selectIsFullAccess } from '../../../../reducers';

export class StrategiesGridValidationError extends Error {
  readonly errors: string[];

  constructor(message: string, errors: string[]) {
    super(message);
    this.errors = errors;
  }
}

const getFplStrategiesCounter = (strategies: GridStrategyType[] = []): number =>
  strategies.filter((strategy) => strategy.type === 'FPL').length;

const getStrategiesGridErrors = (params: { isFullAccess: boolean; job: JobType }): string[] => {
  const { isFullAccess = false, job } = params;
  const { strategies = [], fkRiskFreeProxyBmk } = job;

  const benchmarksCounter = getBenchmarksCounter(strategies);
  const strategiesCounter = strategies.length;

  const isMinStrategiesValid = strategiesCounter >= MIN_STRATEGIES;
  const isMaxStrategiesValid = strategiesCounter <= MAX_STRATEGIES;
  const isMinBenchmarksValid = benchmarksCounter >= MIN_BENCHMARKS;
  const isMaxBenchmarksValid = benchmarksCounter <= MAX_BENCHMARKS;
  const isMinFplStrategiesValid = getFplStrategiesCounter(strategies) >= MIN_FPL_STRATEGIES;
  const isRiskFreeBenchmarkValid = fkRiskFreeProxyBmk;
  const isStrategiesPrimaryBenchmarkValid = strategies.every((strategy) => strategy.benchmarkShortCode);
  const isStrategiesVehicleForPerformanceValid = strategies.every((strategy) => strategy.vehicleID);
  const primaryStrategiesShortCodeList = strategies
    .map((strategy) => strategy.benchmarkShortCode)
    .filter((benchmarkShortCode) => benchmarkShortCode);
  const isStrategiesSecondaryBenchmarkInvalid = strategies.some((strategy) =>
    primaryStrategiesShortCodeList.includes(strategy.benchmark2ShortCode),
  );
  const isCommentsForResearchValid = Boolean(job.notesForRA);

  const minStrategiesErrorMsg = !isMinStrategiesValid
    ? `You must have at least ${MIN_STRATEGIES} Strategies. You only have ${strategiesCounter}.`
    : '';
  const maxStrategiesErrorMsg = !isMaxStrategiesValid
    ? `There is a limit of ${MAX_STRATEGIES} Strategies. You have ${strategiesCounter}.`
    : '';
  const minBenchmarksErrorMsg = !isMinBenchmarksValid
    ? `You must have at least ${MIN_BENCHMARKS} Benchmark(s). You only have ${benchmarksCounter}.`
    : '';
  const maxBenchmarksErrorMsg = !isMaxBenchmarksValid
    ? `There is a limit of ${MAX_BENCHMARKS} Distinct Benchmarks [Primary & Secondary]. You have ${benchmarksCounter}.`
    : '';
  const minFplStrategiesErrorMsg = !isMinFplStrategiesValid ? 'At least one FPL Strategy is required.' : '';
  const riskFreeBenchmarkErrorMsg = !isRiskFreeBenchmarkValid ? 'Please Select a Risk Free Proxy Benchmark.' : '';
  const strategiesPrimaryBenchmarkErrorMsg = !isStrategiesPrimaryBenchmarkValid
    ? 'Each Strategy must have a Primary Benchmark identified.'
    : '';
  const strategiesVehicleForPerformanceErrorMsg = !isStrategiesVehicleForPerformanceValid
    ? 'Please assign all strategies a vehicle for performance.' +
      'If one is unavailable, contact Data Management via a data ' +
      'support email datasupport@nepc.com and ask them to check' +
      'product master for vehicle assignments and eVestment IDs OR delete the strategy.'
    : '';
  const strategiesSecondaryBenchmarkErrorMsg = isStrategiesSecondaryBenchmarkInvalid
    ? 'Secondary benchmarks must be unique from the Primary benchmarks'
    : '';

  const commentsForResearchErrorMsg = !isCommentsForResearchValid ? 'Comments for Research is a required field.' : '';

  return (
    isFullAccess
      ? [
          commentsForResearchErrorMsg,
          maxBenchmarksErrorMsg,
          maxStrategiesErrorMsg,
          minBenchmarksErrorMsg,
          minFplStrategiesErrorMsg,
          minStrategiesErrorMsg,
          riskFreeBenchmarkErrorMsg,
          strategiesPrimaryBenchmarkErrorMsg,
          strategiesSecondaryBenchmarkErrorMsg,
          strategiesVehicleForPerformanceErrorMsg,
        ]
      : [commentsForResearchErrorMsg, minStrategiesErrorMsg, minBenchmarksErrorMsg]
  ).filter((msg) => msg);
};

export const useValidateStrategiesGrid = () => {
  const isFullAccess = useAppSelector(selectIsFullAccess);

  const validateStrategiesGrid = (job: JobType): boolean | never => {
    const strategiesGridErros = getStrategiesGridErrors({ isFullAccess, job });
    if (strategiesGridErros.length) {
      throw new StrategiesGridValidationError('Strategies Grid not valid', strategiesGridErros);
    }
    return true;
  };

  return { validateStrategiesGrid };
};
