import { apiSlice } from './apiSlice';
import * as Globals from '../application/common';
import { toast } from 'react-toastify';

export interface BenchmarkType {
  benchmarkShortCode: string;
  benchmarkName: string;
  benchmarkShortName: string;
}

export const blankBmk = { benchmarkShortCode: '', benchmarkName: '', benchmarkShortName: '' };

export const extendedApiBenchmarksSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBenchmarks: builder.query({
      transformResponse: (baseQueryReturnValue?: BenchmarkType[]): BenchmarkType[] =>
        baseQueryReturnValue
          ? [{ benchmarkShortCode: '', benchmarkName: '-none-', benchmarkShortName: '' }, ...baseQueryReturnValue]
          : [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting benchmarks dropdown list. Error: ${response.status}`);
        return response;
      },
      query: () => Globals.API.endpoints.Benchmarks,
      keepUnusedDataFor: 600,
    }),
    getShadowBenchmarks: builder.query({
      transformResponse: (baseQueryReturnValue?: BenchmarkType[]): BenchmarkType[] =>
        baseQueryReturnValue ? [blankBmk, ...baseQueryReturnValue] : [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting benchmarks dropdown list from shadow. Error: ${response.status}`);
        return response;
      },
      query: () => Globals.API.endpoints.DenodoShadowBenchmarks,
      providesTags: ['shadowBmk'],
      keepUnusedDataFor: 600,
    }),
    benchmarkSubmit: builder.mutation({
      transformErrorResponse: (response) => {
        toast.error(`Update benchmark action failed. Error: ${response.status}`);
        return response;
      },
      query: (benchmark: BenchmarkType) => ({
        url: `${Globals.API.endpoints.SqlBench}`,
        method: 'PUT',
        body: benchmark,
      }),
      invalidatesTags: ['shadowBmk'],
    }),
  }),
});

export const { useGetBenchmarksQuery, useGetShadowBenchmarksQuery, useBenchmarkSubmitMutation } =
  extendedApiBenchmarksSlice;
