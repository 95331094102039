import { extendedApiJobsSlice, selectUserProfile, emptyJob } from '../reducers';
import { useAppSelector } from './AppStoreHooks';

export const useCreateNewJob = () => {
  const [mutate, createNewJobResult] = extendedApiJobsSlice.useCreateNewJobMutation({
    fixedCacheKey: 'use-create-new-job-api',
  });
  const user = useAppSelector(selectUserProfile);
  const triggerCreateNewJob = async () => {
    await mutate({ job: emptyJob, userGroup: user.group });
  };

  return {
    createNewJobResult,
    triggerCreateNewJob,
  };
};
