import { useEffect } from 'react';
import { Box, LinearProgress, Stack, TextField } from '@mui/material';
import StrategiesContactsGrid from './StrategiesContactsGrid';
import {
  JobType,
  emptyDVEmail,
  selectDvEmailBody,
  setDvEmailBody,
  useGetDiligenceVaultEmailByJobIDQuery,
} from '../../../reducers';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useFormikContext } from 'formik';

export const ManagerSubmission = () => {
  const dispatch = useAppDispatch();

  const { initialValues } = useFormikContext<JobType>();

  const dvEmailBody = useAppSelector(selectDvEmailBody);

  const dvEmailByJobIDResult = useGetDiligenceVaultEmailByJobIDQuery(initialValues.jobID, {
    skip: !initialValues.jobID,
  });

  const addCommasToNumberString = (str: string) => Number(str).toLocaleString();
  const addSpaceAfterComma = (str: string) => String(str).split(',').join(', ');

  useEffect(() => {
    const { data: dvEmail = emptyDVEmail } = dvEmailByJobIDResult;
    const getEmailBody = () => {
      const beginningStages = `NEPC is in the beginning stages of a(n) ${dvEmail.fpl} manager search for one of our ${
        dvEmail.region
      } based clients. The client's total mandate size is ${addCommasToNumberString(dvEmail.totalMandateSize)}.\n\n\n`;
      const descriptions = dvEmail.plans
        .map(
          (plan) =>
            `Descriptions of Plan(s):\nPlan Type: ${plan.planType}\nPlan Size: ${addCommasToNumberString(
              plan.planSize,
            )}\nMandate Size: ${addCommasToNumberString(plan.mandateSize)}\nAre these ERISA Assets? ${
              plan.coveredByErisa
            }\n\n\n`,
        )
        .join('');
      const participationDisclaimer = `The client will consider the following vehicles: ${addSpaceAfterComma(
        dvEmail.vehiclesUnderConsideration,
      )}. For this search NEPC will be using data provided by eVestment, www.evestment.com. If you wish to participate, please make sure your performance is updated through ${
        dvEmail.perfDate
      } and your firm and product characteristics are updated through ${
        dvEmail.charDate
      }. Your response must be received by the noted due date. If you do not wish to participate, please check the box in the form referenced in the request. Should you have any questions, please reference this search ID number: NEPC SEARCH ID: ${
        dvEmail.nepcSearchId
      }. You can track the status of the search using the above search ID# at this link on our website: https://www.nepc.com/investment-managers/`;

      return beginningStages + descriptions + participationDisclaimer;
    };
    dispatch(setDvEmailBody(getEmailBody()));
  }, [dispatch, dvEmailByJobIDResult]);

  return (
    <Stack padding={1} spacing={2}>
      {dvEmailByJobIDResult.isLoading ? (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      ) : null}
      <TextField
        id="DiligenceVault-eMail-Body-text-area"
        label="DiligenceVault eMail Body"
        value={dvEmailBody}
        InputProps={{ readOnly: true, disabled: dvEmailByJobIDResult.isLoading }}
        rows={20}
        sx={{ fontSize: '0.5rem' }}
        multiline
      />
      <StrategiesContactsGrid />
    </Stack>
  );
};
