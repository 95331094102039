import { Moment } from 'moment';
import * as Globals from '../application/common';
import { apiSlice } from './apiSlice';
import { GridStrategyType } from './jobStrategySlice';
import { PlanType } from './plansSlice';
import { toast } from 'react-toastify';
import { FormikErrors, FormikTouched } from 'formik';

export interface JobLiteType {
  createdBy: string;
  createdDate?: Moment | null;
  jobID: string;
  jobName: string;
  jobStatus: string;
  modifiedBy: string;
  modifiedDate?: Moment | null;
}

export interface JobType {
  asofdateChar?: string | '' | null;
  asofdatePerf?: string | '' | null;
  clientName?: string;
  clientShortCode: string;
  createdBy: string;
  createdDate?: Moment | null;
  dueDate?: Moment | string | null;
  fkRequestedBmk: string;
  fkRiskFreeProxyBmk: string;
  fkSecondaryBmk: string;
  fplGuid: string;
  fplName: string;
  incumbentName: string;
  isCanceled: boolean;
  isDraft?: boolean;
  isIntegratedToDV: boolean;
  isPerformanceOnlyBook: boolean;
  jiraIssueKey: string;
  jobID: string;
  jobName: string;
  jobStatus: string;
  meetingDate?: Moment | null;
  modifiedBy: string;
  modifiedDate?: Moment | null;
  notesForRA: string;
  plans?: PlanType[];
  portfolioMgmtStrategy: string;
  primaryConsultantEmail: string;
  requestorEmail: string;
  searchSourceOfCandidate: string;
  searchTemplate: string;
  strategies?: GridStrategyType[];
  strategyShortCode: string;
  totalNumberOfColumns: number;
  vehiclesUnderConsideration: string[];
}

export const emptyJob: JobType = {
  asofdateChar: '',
  asofdatePerf: '',
  clientName: '',
  clientShortCode: '',
  createdBy: '',
  createdDate: null,
  dueDate: null,
  fkRequestedBmk: '',
  fkRiskFreeProxyBmk: '',
  fkSecondaryBmk: '',
  fplGuid: '',
  fplName: '',
  incumbentName: '',
  isCanceled: false,
  isIntegratedToDV: false,
  isPerformanceOnlyBook: false,
  jiraIssueKey: '',
  jobID: '',
  jobName: '',
  jobStatus: '',
  meetingDate: null,
  modifiedBy: '',
  modifiedDate: null,
  notesForRA: '',
  plans: [],
  portfolioMgmtStrategy: '',
  primaryConsultantEmail: '',
  requestorEmail: '',
  searchSourceOfCandidate: '',
  searchTemplate: '',
  strategies: [],
  strategyShortCode: '',
  totalNumberOfColumns: 0,
  vehiclesUnderConsideration: [],
};

interface SaveJobApiType {
  job: JobType;
  userGroup: string;
}

export interface JobNestedErrorsType {
  plans?: FormikErrors<PlanType>[];
  strategies?: FormikErrors<GridStrategyType>[];
}

export interface JobNestedTouchedType {
  plans?: FormikTouched<PlanType>[];
  strategies?: FormikTouched<GridStrategyType>[];
}

export const extendedApiJobsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getJobs: builder.query({
      transformResponse: (baseQueryReturnValue?: JobLiteType[]): JobLiteType[] => baseQueryReturnValue ?? [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting jobs dropdown list. Error: ${response.status}`);
        return response;
      },
      query: () => Globals.API.endpoints.Jobs,
    }),
    getJob: builder.query({
      transformResponse: (baseQueryReturnValue?: JobType): JobType => baseQueryReturnValue ?? emptyJob,
      transformErrorResponse: (response) => {
        toast.error(`Error loading job data. Error: ${response.status}`);
        return response;
      },
      query: (jobID: string) => `${Globals.API.endpoints.Jobs}/${jobID}`,
      providesTags: (result, error, arg) => [{ type: 'Job', id: arg }],
    }),
    runJobSubmit: builder.mutation({
      transformResponse: (baseQueryReturnValue?: string[]): string[] => baseQueryReturnValue ?? [],
      transformErrorResponse: (response) => {
        toast.error(`Error running job submit. Error: ${response.status}`);
        return response;
      },
      query: (jobID: string) => ({
        url: `${Globals.API.endpoints.Jobs}/${jobID}/run`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Job', id: arg }],
    }),
    createNewJob: builder.mutation({
      transformResponse: (baseQueryReturnValue?: JobType): JobType => baseQueryReturnValue ?? emptyJob,
      transformErrorResponse: (response) => {
        toast.error(`Add new Job action failed. Error: ${response.status}`);
        return response;
      },
      query: ({ job: initialJob, userGroup }: SaveJobApiType) => ({
        url: `${Globals.API.endpoints.Jobs}/?userGroup=${userGroup}`,
        method: 'POST',
        body: initialJob,
      }),
    }),
    editJob: builder.mutation({
      transformResponse: (baseQueryReturnValue?: JobType): JobType => baseQueryReturnValue ?? emptyJob,
      transformErrorResponse: (response) => {
        toast.error(`Job not saved. Error: ${response.status}`);
        return response;
      },
      query: ({ job, userGroup }: SaveJobApiType) => ({
        url: `${Globals.API.endpoints.Jobs}/${job.jobID}/?userGroup=${userGroup}`,
        method: 'PUT',
        body: job,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Job', id: arg.job.jobID }],
    }),
  }),
});

export const { useGetJobQuery, useGetJobsQuery, useRunJobSubmitMutation } = extendedApiJobsSlice;
