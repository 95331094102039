import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { apiSlice } from './apiSlice';
import * as Globals from '../application/common';

export const UserGroups = {
  Unauthorized: 'unauthorized',
  Consultant: 'RDRP_Consulting',
  Researcher: 'RDRP_Research',
  Team_IT: 'team_INformationTechnology',
};

export interface UserProfileType {
  email: string;
  given_name: string;
  family_name: string;
  group: string;
}

const initialState: UserProfileType = {
  email: '',
  given_name: '',
  family_name: '',
  group: UserGroups.Consultant,
};

export interface UserGroupsType {
  groups: string[] | undefined;
}

export const emptyUserGroup: UserGroupsType = {
  groups: undefined,
};

export const extendedApiUsersSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserGroups: builder.query({
      transformResponse: (baseQueryReturnValue?: UserGroupsType): UserGroupsType =>
        baseQueryReturnValue ?? emptyUserGroup,
      query: (userId: string) => `${Globals.API.endpoints.UserGroups}?userId=${userId}`,
    }),
  }),
});

/**
 * @description Users may be granted access to more than one group.
 * This function checks the user's groups and returns the highest granted permission.
 * @param userGroups This is the user's groups from AD -> Okta.
 * @returns The highest permission granted to the user.
 */
const parseUserGroup = (userGroups: string[]) => {
  if (userGroups.includes(UserGroups.Team_IT)) return UserGroups.Team_IT;
  if (userGroups.includes(UserGroups.Researcher)) return UserGroups.Researcher;
  if (userGroups.includes(UserGroups.Consultant)) return UserGroups.Consultant;
  return UserGroups.Unauthorized;
};

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    setUserProfile: (state, action) => ({
      email: action.payload.payload.email,
      given_name: action.payload.payload.given_name,
      family_name: action.payload.payload.family_name,
      group: state.group,
    }),
    setUserGroup: (state, action) => {
      state.group = parseUserGroup(action.payload);
    },
  },
});

export const { useGetUserGroupsQuery } = extendedApiUsersSlice;

export const selectUserProfile = (state: RootState): UserProfileType => state.userProfile;
export const selectIsUnauthorized = (state: RootState): boolean => state.userProfile.group === UserGroups.Unauthorized;
export const selectIsLimitedAccess = (state: RootState): boolean => state.userProfile.group === UserGroups.Consultant;
export const selectIsFullAccess = (state: RootState): boolean =>
  state.userProfile.group === UserGroups.Researcher || state.userProfile.group === UserGroups.Team_IT;

export const { setUserGroup, setUserProfile } = userProfileSlice.actions;
export default userProfileSlice.reducer;
