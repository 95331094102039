/* eslint jsdoc/require-jsdoc: [warn, {require: {ArrowFunctionExpression: true}}] */
import { toast } from 'react-toastify';
import { FplType, GridStrategyType } from '../../reducers';
import moment from 'moment';
import { GridValueFormatterParams } from '@mui/x-data-grid';
import { useMediaQuery } from '@mui/material';

/**
 * @description This function sets a sequential 'id' property for all elements of an array
 * @param items This is the array to be modified
 * @returns New copy of the array containing sequential 'id' property in each element
 */
export const inputSequentialIds = (items: object[] = []): object[] =>
  items.map((item) => ({ ...item, id: items.indexOf(item) }));

/**
 * @description This function sets the property returning it as a new object
 * @param obj This is the object to be mutated
 * @param prop A string specifying the key of the property to be defined or modified.
 * @param value This is the new value for the property
 * @returns New copy of the object with the property set
 */
export const setObjectProperty = (obj: object, prop: string, value: string | number | boolean): object => ({
  ...obj,
  [prop]: value,
});

/**
 * @description This function removes duplicated items in an array
 * @param arr This is the array to be mutated
 * @param attribute The attribute that must be compared for finding duplicates
 * @returns New copy of the array without duplicates
 */
export const removeDuplicates = (
  arr: { planShortCode?: string; strategyShortCode?: string }[],
  attribute: 'planShortCode' | 'strategyShortCode',
) =>
  arr.filter(
    (filterItem, index) =>
      arr.findIndex((findIndexItem) => findIndexItem[attribute] === filterItem[attribute]) === index,
  );

/**
 * @description This function removes falsy attributes from an object
 * @param obj The object to remove falsy attributes
 * @returns A new object without falsy attributes
 */
const removeFalsyAttributes = (obj: object = {}) =>
  Object.entries(obj).reduce(
    (newObj, [key, value]) => ({
      ...newObj,
      ...(value || typeof value === 'boolean' ? { [key]: value } : {}),
    }),
    {},
  );
/**
 * @description This function merges attributes due to denodo and sql APIs difference
 * @param arg1 Object with parameters {oldStrategies, newStrategies}
 * @param arg1.oldStrategies List to get attributes from the other API that aren't in the new one
 * @param arg1.newStrategies List to replace the old one
 * @returns New copy of the array with attributes of both APIs (new attributes have priority over the old ones)
 */
export const mergeStrategiesAttributes = ({
  oldStrategies = [],
  newStrategies = [],
}: {
  oldStrategies: GridStrategyType[];
  newStrategies: GridStrategyType[];
}) =>
  newStrategies.map((newStrategy) => {
    const oldStrategy = oldStrategies.find((strategy) => strategy.strategyShortCode === newStrategy.strategyShortCode);
    return oldStrategy ? { ...removeFalsyAttributes(oldStrategy), ...newStrategy } : newStrategy;
  });

/**
 * @description This function toast a standardized message for when the job becomes Performance Only Book
 * @param firstFpl The first FPL added to the Job
 */
export const toastPerformanceOnlyBookMsg = (firstFpl: FplType) => {
  toast.success(`The FPL "${firstFpl.fplName}" is a Performance Only Book`);
};

/**
 * @description This function makes sure the value is a string
 * @param str It may be a string or not
 * @returns The string or empty string
 */
const getStringOnly = (str: unknown): string => (typeof str === 'string' ? str : '');

/**
 * @description This function counts end returns the number of distinct benchmarks in the given strategies list
 * @param strategies List of strategies
 * @returns The number of distinct benchmarks in the given strategies list
 */
export const getBenchmarksCounter = (strategies: GridStrategyType[] = []): number => {
  if (!strategies.length) return 0;
  const auxArray = new Set<string>();
  strategies.forEach((strategy: GridStrategyType) => {
    auxArray.add(getStringOnly(strategy.benchmark2ShortCode));
    auxArray.add(getStringOnly(strategy.benchmarkShortCode));
  });
  auxArray.delete('');
  return auxArray.size;
};

/**
 * This function deeply compares two objects and returns if they are equal.
 * @param obj1 First object to be compared.
 * @param obj2 Second object to be compared.
 * @returns Returns true if all attributes and nested objects match, otherwise, false.
 */
export const isDeepEqual = (obj1: object = {}, obj2: object = {}) => {
  const str1 = JSON.stringify(obj1);
  const str2 = JSON.stringify(obj2);
  return str1 === str2;
};

/**
 * This function format a date typed as string to a DateType date.
 * @param params grid params.
 * @returns Returns the right grid format of the date.
 */
export const DateFormatter = (params: GridValueFormatterParams) =>
  params.value ? moment(params.value, 'YYYY-MM-DD').format('MM/DD/YYYY') : '';

/**
 * This function removes , from year values inside grid.
 * @param params grid params.
 * @returns Returns the right grid format for the year.
 */
export const YearFormatter = (params: GridValueFormatterParams) => params.value;

/**
 * This function returns the lowest ID of collection.
 * @param rows rows with id.
 * @returns Returns the lowest ID of collection
 */
export const GetMinId = (rows: any[]) => {
  let min = 0;
  rows.forEach((row) => (min = row.id < min ? row.id : min));
  return min;
};

/**
 * This function returns the orientation based on screen width.
 * @returns Returns the orientation based on screen width
 */
export const TabsOrientation = () => {
  const smallScreen = useMediaQuery('(max-width: 821px)');
  if (smallScreen) {
    return 'vertical';
  } else {
    return 'horizontal';
  }
};

/**
 * This function returns the string replacing the spaces to "-".
 * @param string string to be slugged.
 * @returns Returns the argument sluged to ID format
 */
export const SlugId = (string: string) => string.replaceAll(' ', '-').replaceAll('(', '-').replaceAll(')', '');

/**
 * This function check if the month is a valid quarter.
 * @param month number representing the mont.
 * @returns Returns if is a quarter end
 */
export const isValidQuarter = (month: number) =>
  month ? Boolean(month === 3 || month === 6 || month === 9 || month === 12) : false;

/**
 * This function check if the month is a valid quarter.
 * @param value number representing the mont.
 * @returns Returns if is a quarter end
 */
export const parseFullName = (value: any) =>
  String(value)
    .split(' ')
    .map((str) => (str.length > 0 ? str[0].toUpperCase() + str.slice(1) : ''))
    .join(' ');
