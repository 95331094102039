import { apiSlice } from './apiSlice';
import * as Globals from '../application/common';
import { toast } from 'react-toastify';

export const extendedApiQuarterEndDatesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getQuarterEndDates: builder.query({
      transformResponse: (baseQueryReturnValue?: []): [] => baseQueryReturnValue ?? [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting quarter end dates dropdown list. Error: ${response.status}`);
        return response;
      },
      query: (dueDate: string) => `${Globals.API.endpoints.QuarterEnds}/${dueDate}/-14`,
      keepUnusedDataFor: 600,
      providesTags: ['QuarterEndDates'],
    }),
  }),
});

export const { useLazyGetQuarterEndDatesQuery } = extendedApiQuarterEndDatesSlice;
