import { createContext, useEffect } from 'react';
import { emptyUserGroup, selectIsUnauthorized, setUserGroup, setUserProfile, useGetUserGroupsQuery } from '../reducers';
import { useAppDispatch, useAppSelector } from './AppStoreHooks';
import { useOktaAuth } from '@okta/okta-react';
import { toast } from 'react-toastify';
import { UserClaims } from '@okta/okta-auth-js';

type UserProfileExtra = Pick<UserClaims, 'locale' | 'preferred_username'>;

const emptyUserContext = {
  preferred_username: '',
  locale: '',
} as UserProfileExtra;

export const UserContext = createContext(emptyUserContext);

export const useAppAuth = () => {
  const dispatch = useAppDispatch();
  const { oktaAuth, authState } = useOktaAuth();
  const isUnauthorized = useAppSelector(selectIsUnauthorized);

  const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage') ?? '');
  const uid = oktaTokenStorage?.accessToken?.claims?.uid;
  const { data: userGroupsData = emptyUserGroup } = useGetUserGroupsQuery(uid, { skip: !uid });

  useEffect(() => {
    if (userGroupsData.groups) {
      dispatch(setUserGroup(userGroupsData.groups));
    }
  }, [dispatch, userGroupsData]);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      oktaAuth
        .getUser()
        .then((userInfo: UserProfileExtra) => {
          dispatch(
            setUserProfile({
              type: 'userProfile/userProfileSet',
              payload: userInfo,
            }),
          );
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  }, [authState?.isAuthenticated, dispatch, oktaAuth]);

  useEffect(() => {
    if (isUnauthorized) {
      toast.error('Unauthorized');
      oktaAuth.signOut();
    }
  }, [isUnauthorized, oktaAuth]);

  return { isUnauthorized };
};
