import { Button, Stack, Tooltip } from '@mui/material';
import { Formik, FormikErrors, FormikHelpers, FormikTouched } from 'formik';
import { createContext, useContext, useMemo, useState } from 'react';

import { toast } from 'react-toastify';
import { GetMinId, shadowFeeUpdateValitationSchema } from '../../../application/common';
import { DeleteFeesFunction, GetFirstFplFunction, SaveFeesFunction, useDeleteFee, useSaveFees } from '../../../hooks';
import { GridStrategyType, JobLiteType, ShadowFeeType, useGetFeesForPerformanceQuery } from '../../../reducers';
import { PartialSearch } from '../../Common';
import { useShadowContext } from '../Shadow';
import { Fields, MainTabContent } from '../StyledShadow';
import { ShadowFeesGrid } from './ShadowFeesGrid';

export interface FeeValuesType {
  selectedJobID: string;
  selectedStrategy: string;
  fees: ShadowFeeType[];
}
export interface FeesContextType extends FeeValuesType {
  errors: FormikErrors<FeeValuesType>;
  getFirstFpl: GetFirstFplFunction;
  initialValues: FeeValuesType;
  isReadOnly: boolean;
  isSaveFeesLoading: boolean;
  isFeesGridLocked: boolean;
  saveFees: SaveFeesFunction;
  deleteFee: DeleteFeesFunction;
  isSaveFeesDeleteLoading: boolean;
  isFeesForPerformanceFetching: boolean;
  setFieldValue: FormikHelpers<FeeValuesType>['setFieldValue'];
  setIsFeesGridLocked: React.Dispatch<React.SetStateAction<boolean>>;
  touched: FormikTouched<FeeValuesType>;
  values: FeeValuesType;
}

const emptyFeesValues: FeeValuesType = {
  selectedJobID: '',
  selectedStrategy: '',
  fees: [],
};

const emptyFeeContext: FeesContextType = {
  ...emptyFeesValues,
  errors: {},
  getFirstFpl: () => ({}),
  initialValues: emptyFeesValues,
  isReadOnly: false,
  isSaveFeesLoading: false,
  isFeesGridLocked: false,
  saveFees: async () => [],
  deleteFee: async () => '',
  isSaveFeesDeleteLoading: false,
  isFeesForPerformanceFetching: false,
  setFieldValue: () => new Promise(() => ({})),
  setIsFeesGridLocked: () => ({}),
  touched: {},
  values: emptyFeesValues,
};

export const FeesContext = createContext(emptyFeeContext);
export const useFeesContext = () => useContext(FeesContext);

export const Fees = () => {
  const { jobId, strategyShortCode, setStrategyShortCode, setJobId, getJobsResult, avaliableStrategies } =
    useShadowContext();
  const [isFeesGridLocked, setIsFeesGridLocked] = useState(false);

  const { saveFees, saveFeesResult } = useSaveFees();
  const { isLoading: isSaveFeesLoading } = saveFeesResult;

  const { deleteFee, deleteFeeResult } = useDeleteFee();
  const { isLoading: isSaveFeesDeleteLoading } = deleteFeeResult;

  const filteredJobs = getJobsResult.data?.filter((job: JobLiteType) => `Job Canceled` !== job.jobStatus);

  const newRow = (fees: ShadowFeeType[]) => {
    const newFeeId = GetMinId(fees) - 1;
    return {
      id: newFeeId,
      feeId: newFeeId,
      strategyNepcShortCode: strategyShortCode,
      jobId: jobId,
      feeTypeId: 0,
      feeIdFromSource: jobId,
      feeName: '',
      allBps: 0,
      balanceBps: 0,
      tier1Amount: 0,
      tier1Bps: 0,
      tier2Amount: 0,
      tier2Bps: 0,
      tier3Amount: 0,
      tier3Bps: 0,
      tier4Amount: 0,
      tier4Bps: 0,
      tier5Amount: 0,
      tier5Bps: 0,
      tier6Amount: 0,
      tier6Bps: 0,
      tier7Amount: 0,
      tier7Bps: 0,
    };
  };

  const getFeesForPerformanceResult = useGetFeesForPerformanceQuery(
    { jobId, strategyShortCode },
    {
      skip: !strategyShortCode,
      refetchOnMountOrArgChange: true,
    },
  );

  const feeRows = useMemo(() => {
    if (getFeesForPerformanceResult.data && strategyShortCode) {
      return [...getFeesForPerformanceResult.data]
        .map((fee) => ({ ...fee, id: fee.feeId }))
        .sort((a, b) => a.feeId - b.feeId);
    }
    return [];
  }, [getFeesForPerformanceResult, strategyShortCode]);

  const hasErrors = (errors: FormikErrors<FeeValuesType>) =>
    Boolean(errors.fees?.length && errors.fees.length > 0 && strategyShortCode);

  const initialFeesValues: FeeValuesType = {
    selectedJobID: jobId,
    selectedStrategy: strategyShortCode,
    fees: feeRows as ShadowFeeType[],
  };

  const errorMessage = (errors: FormikErrors<FeeValuesType>): string => {
    let errorMessage = null;
    errorMessage = errors.selectedJobID ?? errors.selectedStrategy;
    const feesMessage = Object.values(errors.fees?.[0] ?? [])[0];
    return errorMessage ?? feesMessage;
  };

  return (
    <Formik
      initialValues={initialFeesValues}
      validationSchema={shadowFeeUpdateValitationSchema}
      onSubmit={() => undefined}
      enableReinitialize
      validateOnMount
      validateOnChange
      validateOnBlur
    >
      {({ errors, values, setFieldValue, touched }) => (
        <FeesContext.Provider
          value={{
            ...emptyFeeContext,
            touched,
            errors,
            isFeesGridLocked,
            isSaveFeesLoading,
            saveFees,
            deleteFee,
            isFeesForPerformanceFetching: getFeesForPerformanceResult.isFetching,
            isSaveFeesDeleteLoading,
            setIsFeesGridLocked,
            setFieldValue,
            values,
          }}
        >
          <MainTabContent>
            <Fields spacing={2} direction="column">
              <Stack direction="row" gap={1}>
                <Stack gap={1} direction="column" whiteSpace="nowrap" alignItems="start" minWidth="70%">
                  <PartialSearch
                    disabled={!getJobsResult.data}
                    id="job-partial-search"
                    label="Job"
                    loading={getJobsResult.isLoading}
                    onChange={(e: JobLiteType) => {
                      setFieldValue('selectedJobID', e.jobID);
                      setFieldValue('selectedStrategy', '');
                      setStrategyShortCode('');
                      setFieldValue('fees', []);
                      setJobId(e.jobID);
                    }}
                    noOptionsText="No jobs"
                    optionKey="jobID"
                    optionName="jobName"
                    options={filteredJobs}
                    value={jobId}
                    sx={{ minWidth: '100%' }}
                  />
                  <PartialSearch
                    disabled={!avaliableStrategies.data || jobId === ''}
                    id="strategy-partial-search"
                    label="Strategy"
                    loading={avaliableStrategies.isLoading}
                    onChange={(newValueObj: GridStrategyType) => {
                      setFieldValue('selectedStrategy', newValueObj.strategyShortCode);
                      setStrategyShortCode(newValueObj.strategyShortCode);
                      setIsFeesGridLocked(true);
                    }}
                    noOptionsText="No strategies"
                    optionKey="strategyShortCode"
                    optionName="strategyName"
                    options={avaliableStrategies.data}
                    value={strategyShortCode}
                    sx={{ minWidth: '100%' }}
                  />
                </Stack>
                <Stack direction="column" justifyContent="space-around" width="30%">
                  <div>
                    Job ID: <strong>{jobId}</strong>
                  </div>
                  <div>
                    Strategy ID: <strong>{strategyShortCode}</strong>
                  </div>
                </Stack>
              </Stack>
            </Fields>
            <Stack alignItems="flex-end">
              <Button
                id="add-fee-btn-id"
                disabled={!values.selectedStrategy}
                variant="contained"
                size="small"
                onClick={() =>
                  values.selectedStrategy ? setFieldValue('fees', [...values.fees, newRow(values.fees)]) : null
                }
              >
                Add Row
              </Button>
            </Stack>
            <ShadowFeesGrid />
            <Stack alignItems="flex-end">
              <Tooltip title={errorMessage(errors)}>
                <span>
                  <Button
                    id="save-fee-btn-id"
                    disabled={hasErrors(errors)}
                    variant="contained"
                    onClick={() =>
                      saveFees({ feesToSubmit: values.fees }).then(() => toast.success('Fees Saved Successfully'))
                    }
                  >
                    Save
                  </Button>
                </span>
              </Tooltip>
            </Stack>
          </MainTabContent>
        </FeesContext.Provider>
      )}
    </Formik>
  );
};
