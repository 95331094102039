import { toast } from 'react-toastify';
import { EquitySecType } from '../../components/Shadow/Strategy/StrategiesColumnDefs';
import { extendedApiStrategyCharacteristcsSlice } from '../../reducers/strategyCharacteristicsSlice';

type StrategyCharacteristicsEquitysSecSubmitFunction = (
  equitysSecsToSubmit: EquitySecType[],
) => Promise<EquitySecType[] | never>;

type StrategyCharacteristicsEquitySecDeleteFunction = (
  strategyCharEquityToDelete: EquitySecType,
) => Promise<string | never>;
export const useMutateStrategyCharEquitySec = () => {
  const [submitStrategiesCharEquitySec, submitStrategiesCharEquitySecResult] =
    extendedApiStrategyCharacteristcsSlice.useStrategyCharEquitySecSubmitMutation();
  const [deleteStrategyEquitySec, deleteStrateGyCharEquitySecResult] =
    extendedApiStrategyCharacteristcsSlice.useStrategyCharEquitySecDeleteMutation();

  const submitStrateGyCharEquitySec: StrategyCharacteristicsEquitysSecSubmitFunction = async (equitysSecsToSubmit) => {
    try {
      const mappedEquitysCap = equitysSecsToSubmit.map((aum) => ({
        ...aum,
        modifiedDate: undefined,
      }));
      return await submitStrategiesCharEquitySec(mappedEquitysCap)
        .unwrap()
        .then((data) => {
          toast.success('Equities Sectors submitted successfully');
          return data;
        });
    } catch (err: any) {
      console.error('Equities Sectors not submitted.');
      throw err;
    }
  };

  const deleteStrateGyCharEquitySec: StrategyCharacteristicsEquitySecDeleteFunction = async (
    strategyCharEquityMktToDelete,
  ) => {
    try {
      return await deleteStrategyEquitySec(strategyCharEquityMktToDelete.strategyCharId)
        .unwrap()
        .then((data) => {
          toast.success('Equity Sector Deleted');
          return data;
        });
    } catch (err: any) {
      toast.error('Strategy Characteristics Equity Sector not deleted.');
      console.error('Strategy Characteristics Equity Sector not deleted.');
      throw err;
    }
  };

  return {
    submitStrateGyCharEquitySec,
    submitStrategiesCharEquitySecResult,
    deleteStrateGyCharEquitySec,
    deleteStrateGyCharEquitySecResult,
  };
};
