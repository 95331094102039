import { apiSlice } from './apiSlice';
import * as Globals from '../application/common';

export interface StrategyContactType {
  id: number;
  jobID: string;
  asOfDatePerf: string;
  firm: string;
  strategy: string;
  contactName: string;
  contactEmailAddress: string;
  status: string;
}

export const extendedApiStrategiesContactsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStrategiesContactsByJobID: builder.query({
      transformResponse: (baseQueryReturnValue?: StrategyContactType[]): StrategyContactType[] =>
        baseQueryReturnValue ?? [],
      query: (jobID: string) => `${Globals.API.endpoints.StrategyContact}/${jobID}`,
      providesTags: (result, error, arg) => [{ type: 'Job', id: arg }],
    }),
  }),
});

export const { useGetStrategiesContactsByJobIDQuery } = extendedApiStrategiesContactsSlice;
