import { GridColDef } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import * as Globals from '../../../../application/common';
import { apiSlice } from '../../../../reducers';
import { BaseColumns } from '../stepsSettings/CommonSettings';
import { EmptyBaseType, StrategyCharacteristicsBaseType } from './CommonSettings';

const STRATEGIES_CHARACTERISTICS_FIXED_SECTOR_TAG = 'Strategy-char-fixed-sector';

export interface fixedSectorType extends StrategyCharacteristicsBaseType {
  govTreasury: number;
  govInflation: number;
  govrelAgency: number;
  govrelSupra: number;
  usmuniTotal: number;
  usmuniTaxexemptRevenue: number;
  usmuniTaxexemptGen: number;
  usmuniTaxexamptInsured: number;
  usmuniTaxexemptPre: number;
  bankSenior: number;
  bankFirstLien: number;
  bankSecondLien: number;
  convertibles: number;
  investIndustrial: number;
  investUtility: number;
  investFininst: number;
  prefStock: number;
  secAbs: number;
  secAgency: number;
  secNonAgency: number;
  secCmbs: number;
  secCovBond: number;
  secColMort: number;
  secColLoan: number;
  secColBond: number;
  sukuk: number;
  hiyieldFininst: number;
  hiyieldIndustrial: number;
  hiyieldUtility: number;
  other: number;
}

const FixedSector = {
  govTreasury: 0,
  govInflation: 0,
  govrelAgency: 0,
  govrelSupra: 0,
  usmuniTotal: 0,
  usmuniTaxexemptRevenue: 0,
  usmuniTaxexemptGen: 0,
  usmuniTaxexamptInsured: 0,
  usmuniTaxexemptPre: 0,
  bankSenior: 0,
  bankFirstLien: 0,
  bankSecondLien: 0,
  convertibles: 0,
  investIndustrial: 0,
  investUtility: 0,
  investFininst: 0,
  prefStock: 0,
  secAbs: 0,
  secAgency: 0,
  secNonAgency: 0,
  secCmbs: 0,
  secCovBond: 0,
  secColMort: 0,
  secColLoan: 0,
  secColBond: 0,
  sukuk: 0,
  hiyieldFininst: 0,
  hiyieldIndustrial: 0,
  hiyieldUtility: 0,
  other: 0,
};

export const EmptyfixedSectorType: fixedSectorType = {
  ...EmptyBaseType,
  ...FixedSector,
};

export const FixedSectorColumnDefs: GridColDef[] = BaseColumns([
  {
    field: 'govTreasury',
    headerName: 'Gov Treasury',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'govInflation',
    headerName: 'Gov Inflation',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'govrelAgency',
    headerName: 'Gov Rel Agency',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'govrelSupra',
    headerName: 'Gov rel Supra',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'usmuniTotal',
    headerName: 'Usmuni Total',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'usmuniTaxexemptRevenue',
    headerName: 'Usmuni Taxexempt Revenue',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'usmuniTaxexemptGen',
    headerName: 'Usmuni Taxexempt Gen',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'usmuniTaxexamptInsured',
    headerName: 'Usmuni Taxexampt Insured',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'usmuniTaxexemptPre',
    headerName: 'Usmuni Taxexempt Pre',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'bankSenior',
    headerName: 'Bank Senior',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'bankFirstLien',
    headerName: 'Bank First Lien',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'bankSecondLien',
    headerName: 'Bank Second Lien',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'convertibles',
    headerName: 'Convertibles',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'investIndustrial',
    headerName: 'Invest Industrial',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'investUtility',
    headerName: 'Invest Utility',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'investFininst',
    headerName: 'Invest Fininst',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'prefStock',
    headerName: 'Pref Stock',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'secAbs',
    headerName: 'Sec Abs',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'secAgency',
    headerName: 'Sec Agency',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'secNonAgency',
    headerName: 'Sec Non Agency',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'secCmbs',
    headerName: 'Sec Cmbs',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'secCovBond',
    headerName: 'Sec Cov Bond',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'secColMort',
    headerName: 'Sec Col Mort',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'secColLoan',
    headerName: 'Sec Col Loan',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'secColBond',
    headerName: 'Sec Col Bond',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'sukuk',
    headerName: 'Sukuk',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'hiyieldFininst',
    headerName: 'Hiyield Fininst',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'hiyieldIndustrial',
    headerName: 'Hiyield Industrial',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'hiyieldUtility',
    headerName: 'Hiyield Utility',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'other',
    headerName: 'Other',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
]);

export const extendedApiStrategyCharacteristcsFixedSectorSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStrategyCharFixedSector: builder.query({
      transformResponse: (baseQueryReturnValue?: fixedSectorType[]): fixedSectorType[] => baseQueryReturnValue ?? [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting Equity style data. Error: ${response.status}`);
        return response;
      },
      query: (strategyShortCode: string) => `${Globals.API.endpoints.strategyCharFixedSector}/${strategyShortCode}`,
      providesTags: [STRATEGIES_CHARACTERISTICS_FIXED_SECTOR_TAG],
    }),
    strategyCharFixedSectorSubmit: builder.mutation({
      transformErrorResponse: (response) => {
        toast.error(`Update Strategy Characteristics Fixed Sector action failed. Error: ${response.status}`);
        return response;
      },
      query: (equityMkt: fixedSectorType[]) => ({
        url: `${Globals.API.endpoints.shadowStrategyCharFixedSector}`,
        method: 'POST',
        body: equityMkt,
      }),
      invalidatesTags: [STRATEGIES_CHARACTERISTICS_FIXED_SECTOR_TAG],
    }),
    strategyCharFixedSectorDelete: builder.mutation({
      transformResponse: (baseQueryReturnValue?: string): string => baseQueryReturnValue ?? '',
      transformErrorResponse: (response) => {
        toast.error(`Delete Strategy Characteristics Fixed Sector action failed. Error: ${response.status}`);
        return response;
      },
      query: (FixedSectorToDelete: number) => ({
        url: `${Globals.API.endpoints.shadowStrategyCharFixedSector}/${FixedSectorToDelete}`,
        method: 'DELETE',
        responseHandler: (response) => response.text(),
      }),
      invalidatesTags: [STRATEGIES_CHARACTERISTICS_FIXED_SECTOR_TAG],
    }),
  }),
});

export const {
  useGetStrategyCharFixedSectorQuery,
  useStrategyCharFixedSectorDeleteMutation,
  useStrategyCharFixedSectorSubmitMutation,
} = extendedApiStrategyCharacteristcsFixedSectorSlice;
