import { GridColDef } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import * as Globals from '../../../../application/common';
import { apiSlice } from '../../../../reducers';
import { BaseColumns } from '../stepsSettings/CommonSettings';
import { EmptyBaseType, StrategyCharacteristicsBaseType } from './CommonSettings';

const STRATEGIES_CHARACTERISTICS_FIXED_RATING_TAG = 'Strategy-char-fixed-rating';

export interface fixedRatingType extends StrategyCharacteristicsBaseType {
  govGuaranteed: number;
  aaa: string;
  aa: string;
  a: string;
  bbb: string;
  bb: string;
  b: string;
  ccc: string;
  cc: string;
  c: string;
  distressedDebt: number;
  otherRated: number;
  notRated: number;
}

export const EmptyfixedRatingType: fixedRatingType = {
  ...EmptyBaseType,
  govGuaranteed: 0,
  aaa: '',
  aa: '',
  a: '',
  bbb: '',
  bb: '',
  b: '',
  ccc: '',
  cc: '',
  c: '',
  distressedDebt: 0,
  otherRated: 0,
  notRated: 0,
};

export const FixedRatingColumnDefs: GridColDef[] = BaseColumns([
  {
    field: 'govGuaranteed',
    headerName: 'Gov Guaranteed',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'aaa',
    headerName: 'AAA',
    type: 'string',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    align: 'right',
    editable: true,
  },
  {
    field: 'aa',
    headerName: 'AA',
    type: 'string',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    align: 'right',
    editable: true,
  },
  {
    field: 'a',
    headerName: 'A',
    type: 'string',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'bbb',
    headerName: 'BBB',
    type: 'string',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'bb',
    headerName: 'BB',
    type: 'string',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'b',
    headerName: 'B',
    type: 'string',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'ccc',
    headerName: 'CCC',
    type: 'string',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'cc',
    headerName: 'CC',
    type: 'string',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'c',
    headerName: 'C',
    type: 'string',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'distressedDebt',
    headerName: 'Distressed Debt',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'otherRated',
    headerName: 'Other Rated',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'notRated',
    headerName: 'Not Rated',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
]);

export const extendedApiStrategyCharacteristcsFixedRatingSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStrategyCharFixedRating: builder.query({
      transformResponse: (baseQueryReturnValue?: fixedRatingType[]): fixedRatingType[] => baseQueryReturnValue ?? [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting Equity style data. Error: ${response.status}`);
        return response;
      },
      query: (strategyShortCode: string) => `${Globals.API.endpoints.strategyCharFixedRating}/${strategyShortCode}`,
      providesTags: [STRATEGIES_CHARACTERISTICS_FIXED_RATING_TAG],
    }),
    strategyCharFixedRatingSubmit: builder.mutation({
      transformErrorResponse: (response) => {
        toast.error(`Update Strategy Characteristics Fixed inc action failed. Error: ${response.status}`);
        return response;
      },
      query: (equityMkt: fixedRatingType[]) => ({
        url: `${Globals.API.endpoints.shadowStrategyCharFixedRating}`,
        method: 'POST',
        body: equityMkt,
      }),
      invalidatesTags: [STRATEGIES_CHARACTERISTICS_FIXED_RATING_TAG],
    }),
    strategyCharFixedRatingDelete: builder.mutation({
      transformResponse: (baseQueryReturnValue?: string): string => baseQueryReturnValue ?? '',
      transformErrorResponse: (response) => {
        toast.error(`Delete Strategy Characteristics Fixed Inc action failed. Error: ${response.status}`);
        return response;
      },
      query: (FixedRatingToDelete: number) => ({
        url: `${Globals.API.endpoints.shadowStrategyCharFixedRating}/${FixedRatingToDelete}`,
        method: 'DELETE',
        responseHandler: (response) => response.text(),
      }),
      invalidatesTags: [STRATEGIES_CHARACTERISTICS_FIXED_RATING_TAG],
    }),
  }),
});

export const {
  useGetStrategyCharFixedRatingQuery,
  useStrategyCharFixedRatingDeleteMutation,
  useStrategyCharFixedRatingSubmitMutation,
} = extendedApiStrategyCharacteristcsFixedRatingSlice;
