import { apiSlice } from './apiSlice';
import * as Globals from '../application/common';
import { toast } from 'react-toastify';

export interface VehicleType {
  dataSourceID: number;
  grossOrNet: string;
  planHoldingID: number | string;
  vehicleID: string;
  vehicleName: string;
  vehicleKey?: string;
}

export const extendedApiVehiclesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVehiclesByStrategyID: builder.query({
      transformResponse: (baseQueryReturnValue?: VehicleType[]): VehicleType[] =>
        baseQueryReturnValue
          ? [
              { dataSourceID: 0, grossOrNet: '', planHoldingID: 0, vehicleID: '', vehicleName: '-none-' },
              ...baseQueryReturnValue,
            ]
          : [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting vehicles dropdown list. Error: ${response.status}`);
        return response;
      },
      query: (strategyID: string) => `${Globals.API.endpoints.Vehicles}/${strategyID}`,
      keepUnusedDataFor: 600,
    }),
    getVehicleDataSourceID: builder.query({
      transformResponse: (baseQueryReturnValue: number): number => baseQueryReturnValue,
      transformErrorResponse: (response) => {
        toast.error(`Error getting vehicle data source id. Error: ${response.status}`);
        return response;
      },
      query: ({ vehicleID, grossOrNet }: { vehicleID: number | string; grossOrNet: string }) =>
        `${Globals.API.endpoints.VehiclesDataSource}/${vehicleID}/${grossOrNet}`,
      keepUnusedDataFor: 600,
    }),
  }),
});

export const { useGetVehiclesByStrategyIDQuery, useLazyGetVehicleDataSourceIDQuery } = extendedApiVehiclesSlice;
