import { toast } from 'react-toastify';
import * as Globals from '../application/common';
import { EquityMktCapType, EquitySecType } from '../components/Shadow/Strategy/StrategiesColumnDefs';
import { apiSlice } from './apiSlice';
import { FirmType } from './firmsSlice';
import { AUMType } from '../components/Shadow/Strategy/stepsSettings/Aum';

const STRATEGIES_CHARACTERISTICS_AUM_TAG = 'Strategy-char-aum';
const STRATEGIES_CHARACTERISTICS_EQUITY_TAG = 'Strategy-char-equity-cap';
const STRATEGIES_CHARACTERISTICS_EQUITY_SEC_TAG = 'Strategy-char-equity-sec';

export const extendedApiStrategyCharacteristcsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStrategyCharAum: builder.query({
      transformResponse: (baseQueryReturnValue?: AUMType[]): AUMType[] => baseQueryReturnValue ?? [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting AUM data. Error: ${response.status}`);
        return response;
      },
      query: (strategyShortCode: string) => `${Globals.API.endpoints.strategyCharAUM}/${strategyShortCode}`,
      providesTags: [STRATEGIES_CHARACTERISTICS_AUM_TAG],
    }),
    getFirmStrategiesChar: builder.query({
      transformResponse: (baseQueryReturnValue?: FirmType[]): FirmType[] => baseQueryReturnValue ?? [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting Strategies data. Error: ${response.status}`);
        return response;
      },
      query: (strategyShortCode: string) =>
        `${Globals.API.endpoints.ShadowFirmStrategies}/${strategyShortCode}/strategies`,
    }),
    strategyCharSubmit: builder.mutation({
      transformErrorResponse: (response) => {
        toast.error(`Update  Strategy Characteristics AUM action failed. Error: ${response.status}`);
        return response;
      },
      query: (aum: AUMType[]) => ({
        url: `${Globals.API.endpoints.strategyCharAUMSql}`,
        method: 'POST',
        body: aum,
      }),
      invalidatesTags: [STRATEGIES_CHARACTERISTICS_AUM_TAG],
    }),
    strategyCharDelete: builder.mutation({
      transformResponse: (baseQueryReturnValue?: string): string => baseQueryReturnValue ?? '',
      transformErrorResponse: (response) => {
        toast.error(`Delete Strategy Characteristics AUM action failed. Error: ${response.status}`);
        return response;
      },
      query: (charAumToDelete: number) => ({
        url: `${Globals.API.endpoints.strategyCharAUMSql}/${charAumToDelete}`,
        method: 'DELETE',
        responseHandler: (response) => response.text(),
      }),
      invalidatesTags: [STRATEGIES_CHARACTERISTICS_AUM_TAG],
    }),
    getStrategyCharEquityMktCap: builder.query({
      transformResponse: (baseQueryReturnValue?: EquityMktCapType[]): EquityMktCapType[] => baseQueryReturnValue ?? [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting Equity mkt cap data. Error: ${response.status}`);
        return response;
      },
      query: (strategyShortCode: string) =>
        `${Globals.API.endpoints.shadowStrategyCharEquityMktCap}/${strategyShortCode}`,
      providesTags: [STRATEGIES_CHARACTERISTICS_EQUITY_TAG],
    }),
    strategyCharEquityMktCapSubmit: builder.mutation({
      transformErrorResponse: (response) => {
        toast.error(`Update Strategy Characteristics Equity mkt Cap action failed. Error: ${response.status}`);
        return response;
      },
      query: (equityMkt: EquityMktCapType[]) => ({
        url: `${Globals.API.endpoints.strategyCharEquityMktCap}`,
        method: 'POST',
        body: equityMkt,
      }),
      invalidatesTags: [STRATEGIES_CHARACTERISTICS_EQUITY_TAG],
    }),
    strategyCharEquityMktCapDelete: builder.mutation({
      transformResponse: (baseQueryReturnValue?: string): string => baseQueryReturnValue ?? '',
      transformErrorResponse: (response) => {
        toast.error(`Delete Strategy Characteristics Equity marketing action failed. Error: ${response.status}`);
        return response;
      },
      query: (EquityMktToDelete: number) => ({
        url: `${Globals.API.endpoints.strategyCharEquityMktCap}/${EquityMktToDelete}`,
        method: 'DELETE',
        responseHandler: (response) => response.text(),
      }),
      invalidatesTags: [STRATEGIES_CHARACTERISTICS_EQUITY_TAG],
    }),
    getStrategyCharEquitySec: builder.query({
      transformResponse: (baseQueryReturnValue?: EquitySecType[]): EquitySecType[] => baseQueryReturnValue ?? [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting Equity section data. Error: ${response.status}`);
        return response;
      },
      query: (strategyShortCode: string) => `${Globals.API.endpoints.strategyCharEquitySec}/${strategyShortCode}`,
      providesTags: [STRATEGIES_CHARACTERISTICS_EQUITY_SEC_TAG],
    }),
    strategyCharEquitySecSubmit: builder.mutation({
      transformErrorResponse: (response) => {
        toast.error(`Update Strategy Characteristics Equity mkt Cap action failed. Error: ${response.status}`);
        return response;
      },
      query: (equityMkt: EquitySecType[]) => ({
        url: `${Globals.API.endpoints.shadowStrategyCharEquitySec}`,
        method: 'POST',
        body: equityMkt,
      }),
      invalidatesTags: [STRATEGIES_CHARACTERISTICS_EQUITY_SEC_TAG],
    }),
    strategyCharEquitySecDelete: builder.mutation({
      transformResponse: (baseQueryReturnValue?: string): string => baseQueryReturnValue ?? '',
      transformErrorResponse: (response) => {
        toast.error(`Delete Strategy Characteristics Equity marketing action failed. Error: ${response.status}`);
        return response;
      },
      query: (EquitySecToDelete: number) => ({
        url: `${Globals.API.endpoints.shadowStrategyCharEquitySec}/${EquitySecToDelete}`,
        method: 'DELETE',
        responseHandler: (response) => response.text(),
      }),
      invalidatesTags: [STRATEGIES_CHARACTERISTICS_EQUITY_SEC_TAG],
    }),
  }),
});

export const {
  useGetStrategyCharAumQuery,
  useStrategyCharSubmitMutation,
  useStrategyCharDeleteMutation,
  useGetFirmStrategiesCharQuery,
  useGetStrategyCharEquityMktCapQuery,
  useStrategyCharEquityMktCapSubmitMutation,
  useStrategyCharEquityMktCapDeleteMutation,
  useGetStrategyCharEquitySecQuery,
  useStrategyCharEquitySecSubmitMutation,
  useStrategyCharEquitySecDeleteMutation,
} = extendedApiStrategyCharacteristcsSlice;
