import { extendedApiFeesSlice, ShadowFeeType } from '../reducers';

export type SaveFeesFunction = (arg: { feesToSubmit: ShadowFeeType[] }) => Promise<ShadowFeeType[] | never>;
export const useSaveFees = () => {
  const [mutate, saveFeesResult] = extendedApiFeesSlice.useRunFeesSubmitMutation({
    fixedCacheKey: 'use-save-fees-api',
  });

  const saveFees: SaveFeesFunction = async ({ feesToSubmit }) => {
    try {
      const mappedFees = feesToSubmit.map((fee) => ({
        ...fee,
        dateLocked: undefined,
        modifiedDate: undefined,
      }));
      return await mutate(mappedFees).unwrap();
    } catch (err: any) {
      console.error('Fees not saved.');
      throw err;
    }
  };

  return {
    saveFeesResult,
    saveFees,
  };
};
