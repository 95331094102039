import { toast } from 'react-toastify';
import {
  fixedSectorType,
  extendedApiStrategyCharacteristcsFixedSectorSlice,
} from '../../components/Shadow/Strategy/stepsSettings/FixedSector';

type StrategyCharacteristicsFixedSectorSubmitFunction = (
  fixedSectorsToSubmit: fixedSectorType[],
) => Promise<fixedSectorType[] | never>;

type StrategyCharacteristicsFixedSectorDeleteFunction = (
  strategyCharEquityToDelete: fixedSectorType,
) => Promise<string | never>;
export const useMutateStrategyCharFixedSector = () => {
  const [submitStrategiesCharFixedSector, submitStrategiesCharFixedSectorResult] =
    extendedApiStrategyCharacteristcsFixedSectorSlice.useStrategyCharFixedSectorSubmitMutation();
  const [deleteStrategyFixedSector, deleteStrateGyCharFixedSectorResult] =
    extendedApiStrategyCharacteristcsFixedSectorSlice.useStrategyCharFixedSectorDeleteMutation();

  const submitStrateGyCharFixedSector: StrategyCharacteristicsFixedSectorSubmitFunction = async (
    equitysStyleToSubmit,
  ) => {
    try {
      const mappedFixedSector = equitysStyleToSubmit.map((equityStyle) => ({
        ...equityStyle,
        modifiedDate: undefined,
      }));
      return await submitStrategiesCharFixedSector(mappedFixedSector)
        .unwrap()
        .then((data) => {
          toast.success('Fixed Sector submitted successfully');
          return data;
        });
    } catch (err: any) {
      console.error('Fixed Sector not submitted.');
      throw err;
    }
  };

  const deleteStrateGyCharFixedSector: StrategyCharacteristicsFixedSectorDeleteFunction = async (
    strategyCharFixedSectorToDelete,
  ) => {
    try {
      return await deleteStrategyFixedSector(strategyCharFixedSectorToDelete.strategyCharId)
        .unwrap()
        .then((data) => {
          toast.success('Fixed Sector Deleted');
          return data;
        });
    } catch (err: any) {
      toast.error('Strategy Characteristics Fixed Sector not deleted.');
      console.error('Strategy Characteristics Fixed Sector not deleted.');
      throw err;
    }
  };

  return {
    submitStrateGyCharFixedSector,
    submitStrategiesCharFixedSectorResult,
    deleteStrateGyCharFixedSector,
    deleteStrateGyCharFixedSectorResult,
  };
};
