import { apiSlice } from './apiSlice';
import * as Globals from '../application/common';
import { toast } from 'react-toastify';

export interface managerNotificationsType {
  id?: number;
  jobId?: string;
  strategyShortCode?: string;
  jobStrategyId?: number;
  saProposed?: boolean;
  cfProposed?: boolean;
  mfProposed?: boolean;
  modifiedBy?: string;
  modifiedDate?: string;
}
export const extendedApiNotificationsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query({
      transformResponse: (baseQueryReturnValue?: managerNotificationsType): managerNotificationsType =>
        baseQueryReturnValue ?? {},
      transformErrorResponse: (response) => {
        toast.error(`Error getting notifications. Error: ${response.status}`);
        return response;
      },
      query: ({ selectedStrategyShortCode, jobID }) =>
        `${Globals.API.endpoints.ManagerNotifications}/${jobID}/${selectedStrategyShortCode}`,
      providesTags: (result, error, arg) => [{ type: 'Notifications' }],
    }),
    runSaveNotifications: builder.mutation({
      transformResponse: (baseQueryReturnValue?: managerNotificationsType): managerNotificationsType =>
        baseQueryReturnValue ?? {},
      transformErrorResponse: (response) => {
        toast.error(`Error Saving notifications. Error: ${response.status}`);
        return response;
      },
      query: (notifications: managerNotificationsType) => ({
        url: `${Globals.API.endpoints.ManagerNotificationsPost}`,
        method: 'POST',
        body: notifications,
      }),
      invalidatesTags: (result, error, arg) => (error ? [] : [{ type: 'Notifications' }]),
    }),
    runDeleteNotifications: builder.mutation({
      transformResponse: (baseQueryReturnValue?: string): string => baseQueryReturnValue ?? '',
      transformErrorResponse: (response) => {
        toast.error(`Error Deleting the notifications. Error: ${response.status}`);
        return response;
      },
      query: (id: number) => ({
        url: `${Globals.API.endpoints.ManagerNotificationsPost}/${id}`,
        method: 'DELETE',
        responseHandler: (response) => response.text(),
      }),
      invalidatesTags: () => [{ type: 'Notifications' }],
    }),
  }),
});

export const { useGetNotificationsQuery, useRunSaveNotificationsMutation, useRunDeleteNotificationsMutation } =
  extendedApiNotificationsSlice;
