import { toast } from 'react-toastify';

import { extendedApiFeesSlice } from '../reducers';

export type DeleteFeesFunction = (feesToSubmit: number) => Promise<string | never>;
export const useDeleteFee = () => {
  const [mutate, deleteFeeResult] = extendedApiFeesSlice.useRunFeesDeleteMutation();

  const deleteFee: DeleteFeesFunction = async (feeToDelete) => {
    try {
      return await mutate(feeToDelete)
        .unwrap()
        .then((data) => {
          toast.success('Fee Deleted ');
          return data;
        });
    } catch (err: any) {
      console.error('Fees not deleted.');
      throw err;
    }
  };

  return {
    deleteFeeResult,
    deleteFee,
  };
};
