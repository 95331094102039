export const API = {
  BaseURL: process.env.REACT_APP_API_BASE_URI,
  timeout: parseInt(process.env.REACT_APP_API_TIMEOUT!),
  contentType: 'application/json',
  endpoints: {
    Benchmarks: process.env.REACT_APP_API_DENODO + '/api/v1/benchmarks',
    DenodoShadowBenchmarks: process.env.REACT_APP_API_DENODO + '/api/v1/shadow/benchmarks',
    SqlBench: process.env.REACT_APP_API_SQL + '/api/v1/benchmark',
    Clients: process.env.REACT_APP_API_DENODO + '/api/v1/clients',
    DenodoDiligenceVault: process.env.REACT_APP_API_DENODO + '/api/v1/dv',
    Fees: process.env.REACT_APP_API_DENODO + '/api/v1/fees',
    FeeSubmit: process.env.REACT_APP_API_SQL + '/api/v1/fee/bulk',
    FeeDelete: process.env.REACT_APP_API_SQL + '/api/v1/fee',
    ShadowFees: process.env.REACT_APP_API_DENODO + '/api/v1/shadow/fees-for-performance',
    ShadowFirmStrategies: process.env.REACT_APP_API_DENODO + '/api/v1/shadow/firm',
    Firms: process.env.REACT_APP_API_DENODO + '/api/v1/firms',
    FirmStrategies: process.env.REACT_APP_API_DENODO + '/api/v1/strategies/firm',
    ShadowFirmData: process.env.REACT_APP_API_DENODO + '/api/v1/shadow/firm-pending-litigation',
    ShadowFirmPendingLitigation: process.env.REACT_APP_API_SQL + '/api/v1/firm/pending-litigation',
    FPL: process.env.REACT_APP_API_DENODO + '/api/v1/fpl',
    FplStrategies: process.env.REACT_APP_API_DENODO + '/api/v1/strategies/fpl',
    Incumbent: process.env.REACT_APP_API_DENODO + '/api/v1/strategies/incumbent',
    strategyCharAUM: process.env.REACT_APP_API_DENODO + '/api/v1/shadow/strategy-char-aum',
    strategyCharAUMSql: process.env.REACT_APP_API_SQL + '/api/v1/strategy-char/aum',
    strategyCharEquityMktCap: process.env.REACT_APP_API_SQL + '/api/v1/strategy-char/equity-mkt-cap',
    shadowStrategyCharEquityMktCap: process.env.REACT_APP_API_DENODO + '/api/v1/shadow/equity-mkt-cap',
    strategyCharEquitySec: process.env.REACT_APP_API_DENODO + '/api/v1/shadow/equity-sector-allocation',
    shadowStrategyCharEquitySec: process.env.REACT_APP_API_SQL + '/api/v1/strategy-char/equity-sector-allocation',
    strategyCharEquityStyle: process.env.REACT_APP_API_DENODO + '/api/v1/shadow/equity-style',
    shadowStrategyCharEquityStyle: process.env.REACT_APP_API_SQL + '/api/v1/strategy-char/equity-style',
    strategyCharFixedIncome: process.env.REACT_APP_API_DENODO + '/api/v1/shadow/fixed-income-chars',
    shadowStrategyCharFixedIncome: process.env.REACT_APP_API_SQL + '/api/v1/strategy-char/fixed-income-chars',
    strategyCharFixedRating: process.env.REACT_APP_API_DENODO + '/api/v1/shadow/fixed-income-rating',
    shadowStrategyCharFixedRating: process.env.REACT_APP_API_SQL + '/api/v1/strategy-char/fixed-income-rating',
    strategyCharFixedDuration: process.env.REACT_APP_API_DENODO + '/api/v1/shadow/fixed-income-duration',
    shadowStrategyCharFixedDuration: process.env.REACT_APP_API_SQL + '/api/v1/strategy-char/fixed-income-duration',
    strategyCharFixedSector: process.env.REACT_APP_API_DENODO + '/api/v1/shadow/fixed-income-sector',
    shadowStrategyCharFixedSector: process.env.REACT_APP_API_SQL + '/api/v1/strategy-char/fixed-income-sector',
    shadowVehiclesForPerformance: process.env.REACT_APP_API_SQL + '/api/v1/vehicles-for-performance',
    vehiclesForPerformance: process.env.REACT_APP_API_DENODO + '/api/v1/shadow/vehicles-for-performance',
    Jira: process.env.REACT_APP_API_SQL + '/api/v1/jira',
    Jobs: process.env.REACT_APP_API_SQL + '/api/v1/jobs',
    JobStrategies: process.env.REACT_APP_API_DENODO + '/api/v1/strategies',
    Plans: process.env.REACT_APP_API_DENODO + '/api/v1/plans',
    QuarterEnds: process.env.REACT_APP_API_SQL + '/api/v1/date/quarterEnd',
    Requestors: process.env.REACT_APP_API_DENODO + '/api/v1/requestors',
    SqlDiligenceVault: process.env.REACT_APP_API_SQL + '/api/v1/dv',
    StrategyContact: process.env.REACT_APP_API_DENODO + '/api/v1/strategy/contact',
    UserGroups: process.env.REACT_APP_API_CLIENTHUB_OKTA + '/api/v1/groups',
    Vehicles: process.env.REACT_APP_API_DENODO + '/api/v1/vehicles',
    VehiclesDataSource: process.env.REACT_APP_API_DENODO + '/api/v1/vehicles/dataSource',
    ManagerNotifications: process.env.REACT_APP_API_DENODO + '/api/v1/shadow/manager-notification',
    ManagerNotificationsPost: process.env.REACT_APP_API_SQL + '/api/v1/manager-notification',
  },
  errorMessages: {
    none2xx: 'The request was made and the server responded with a status code that falls out of the range of 2xx',
    noResponse: 'The request was made but no response was received',
    timeout: "We are unable to fetch data at this time, kindly check your internet connection and we'll reconnect you.",
    unexpectedError: 'Something happened in setting up the request that triggered an Error',
  },
};
