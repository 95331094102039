import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { GridRowId, GridEventListener, GridColDef, GridRenderCellParams, DataGrid } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import { useAppSelector } from '../../../../hooks';
import { inputSequentialIds, toastPerformanceOnlyBookMsg } from '../../../../application/common';
import {
  emptyGridStrategyRow,
  GridStrategyType,
  selectIsStrategiesMergeCompleted,
  useGetJobStrategiesByJobIDQuery,
} from '../../../../reducers';
import { useStrategiesContext } from '../StrategiesContext';

export const StrategiesGrid = () => {
  const {
    getFirstFpl,
    initialValues,
    isReadOnly,
    isSaveJobLoading,
    isStrategiesGridLocked,
    saveJob,
    selectedJobStrategyRow,
    setFieldValue,
    setIsStrategiesGridLocked,
    setSelectedJobStrategyRow,
    values,
  } = useStrategiesContext();

  const { strategies = [] } = values;
  const [jobStrategyRows, setJobStrategyRows] = useState<GridStrategyType[]>([]);
  const [isGridUpdating, setIsGridUpdating] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);

  const isStrategiesMergeCompleted = useAppSelector(selectIsStrategiesMergeCompleted);

  const getStrategiesResult = useGetJobStrategiesByJobIDQuery(initialValues.jobID, {
    skip: !initialValues.jobID,
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!isGridUpdating && strategies) {
      setJobStrategyRows(inputSequentialIds(strategies) as GridStrategyType[]);
      setIsStrategiesGridLocked(false);
    }
  }, [isGridUpdating, setIsStrategiesGridLocked, strategies]);

  useEffect(
    () => () => {
      setSelectedJobStrategyRow(emptyGridStrategyRow);
    },
    [setSelectedJobStrategyRow],
  );

  useEffect(() => {
    const _isGridUpdating = !isStrategiesMergeCompleted || getStrategiesResult.isFetching || isSaveJobLoading;
    setIsGridUpdating(_isGridUpdating);
    if (_isGridUpdating) setIsStrategiesGridLocked(true);
  }, [getStrategiesResult.isFetching, isSaveJobLoading, isStrategiesMergeCompleted, setIsStrategiesGridLocked]);

  let isDeletingRow = false;

  const deleteRow = (id: number) => {
    if (!isStrategiesGridLocked) {
      isDeletingRow = true;
      setSelectionModel([]);
      setSelectedJobStrategyRow(emptyGridStrategyRow);
      const newStrategies = [...strategies.slice(0, id), ...strategies.slice(id + 1)];
      const { firstFpl: firstFplBefore } = getFirstFpl({ strategies: strategies });
      const { firstFpl: firstFplAfter } = getFirstFpl({ strategies: newStrategies });
      const isFirstFplChanged = firstFplBefore !== firstFplAfter;
      if (isFirstFplChanged && firstFplAfter?.isPerformanceOnlyBook) {
        const newStrategiesToSave = newStrategies.map((strategy) => ({
          ...strategy,
          isPerformanceOnly: true,
        }));
        setFieldValue('strategies', newStrategiesToSave);
        toastPerformanceOnlyBookMsg(firstFplAfter);
        saveJob({ job: { ...values, strategies: newStrategiesToSave } });
      } else {
        setFieldValue('strategies', newStrategies);
        saveJob({ job: { ...values, strategies: newStrategies } });
      }
      setIsStrategiesGridLocked(true);
    }
  };

  const handleRowClick: GridEventListener<'rowClick'> = (params, event) => {
    if (!isDeletingRow && !isStrategiesGridLocked) {
      const isRowDeselected = params.row.id === selectedJobStrategyRow.id && event.ctrlKey;
      const newValueToDispatch = isRowDeselected ? emptyGridStrategyRow : params.row;
      setSelectedJobStrategyRow(newValueToDispatch);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'deleteRow',
      headerName: '',
      headerAlign: 'center',
      width: 50,
      renderCell: (params: any) => (
        <IconButton
          aria-label="delete"
          disabled={isStrategiesGridLocked || isReadOnly}
          onClick={() => deleteRow(params.row.id)}
          sx={{ width: '100%', justifyContent: 'center' }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
    {
      align: 'center',
      field: 'isPerformanceOnly',
      headerAlign: 'center',
      headerName: 'Perf Only',
      renderCell: (params: GridRenderCellParams<GridStrategyType>) =>
        params.value ? `Y${params.row.isDrivingPerfOnlyBook ? '*' : ''}` : '',
      width: 100,
    },
    {
      field: 'grouping',
      headerName: 'Grouping',
      headerAlign: 'left',
      width: 250,
    },
    {
      field: 'firmLegalName',
      headerName: 'Investment Firm',
      headerAlign: 'left',
      width: 400,
    },
    {
      field: 'strategyName',
      headerName: 'Strategy',
      headerAlign: 'left',
      width: 350,
    },
    {
      field: 'type',
      headerName: 'Strategy Type',
      headerAlign: 'left',
      width: 150,
    },
    {
      field: 'vehicleName',
      headerName: 'Performance Vehicle',
      headerAlign: 'left',
      width: 400,
    },
    {
      field: 'feeName',
      headerName: 'Fees for Performance',
      headerAlign: 'left',
      width: 175,
    },
    {
      field: 'benchmarkName',
      headerName: 'Strategy Benchmark',
      headerAlign: 'left',
      width: 350,
    },
    {
      field: 'benchmark2Name',
      headerName: 'Secondary Benchmark',
      headerAlign: 'left',
      width: 350,
    },
    {
      field: 'dueDiligenceStatus',
      headerName: 'Due Diligence Status',
      headerAlign: 'left',
      width: 200,
    },
  ];

  return (
    <DataGrid
      rows={jobStrategyRows}
      columns={columns}
      onRowClick={handleRowClick}
      loading={isStrategiesGridLocked}
      autoHeight
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={(selectionModel) => setSelectionModel(selectionModel)}
      sx={{
        boxShadow: 0,
        '.MuiSelect-select,.MuiInputBase-input': {
          padding: '8px',
        },
        'input.MuiInputBase-input': {
          textAlign: 'end',
        },
        '.plan-size-value': {
          paddingRight: '1rem !important',
        },
        '.MuiDataGrid-cell': {
          padding: '0 0.5rem',
        },
      }}
    />
  );
};
