import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userProfileReducer from '../reducers/userProfileSlice';
import { apiSlice, rtkQueryGetHttpErrorEmitter } from '../reducers/apiSlice';
import uiReducer from '../reducers/uiSlice';

export const store = configureStore({
  reducer: {
    userProfile: userProfileReducer,
    ui: uiReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware).concat(rtkQueryGetHttpErrorEmitter),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action>;
