export const staticData = {
  bookTypes: ['Search Book', 'Review'],
  JOB_STATUS_NEW: 'New',
  JOB_STATUS_NO_FILTER: 'All',
  jobsStatus: [
    'All',
    'Job Canceled',
    'Request Assigned',
    'Request Started',
    'Request Submitted',
    'SearchBook Generated',
    'SearchBook Submitted',
  ],
  managementStrategies: ['Active', 'Passive', 'Enhanced'],
  sourcesCandidates: ['Advertised Search', 'NEPC Best Thoughts', 'Focus Placement Lists'],
  feeTypes: [
    { value: 0, name: '' },
    { value: 5, name: 'Search Specific (SA)' },
    { value: 8, name: 'Search Specific (CF)' },
    { value: 9, name: 'Search Specific (MF)' },
  ],
  NEPC_CREATED_DS_ID: 5,
};
