import {
  Autocomplete,
  CircularProgress,
  FormControl,
  FormHelperText,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

interface PartialSearchPropsType {
  disabled?: boolean;
  disabledValue?: string;
  error?: string;
  formatOptions?: (option: string) => string;
  id: string;
  label: string;
  loading: boolean;
  noOptionsText?: string;
  onChange: (newValueObj: any) => void;
  optionKey: string;
  optionName: string;
  options?: any[];
  sx?: SxProps<Theme>;
  touched?: boolean;
  value: string | number;
}

export const PartialSearch = (props: PartialSearchPropsType) => {
  const {
    disabled,
    disabledValue,
    error,
    formatOptions,
    id,
    label,
    loading,
    onChange,
    optionKey,
    optionName,
    options = [],
    sx,
    touched,
    value,
  } = props;

  const isValid = !(touched && error);
  const [valueObj, setValueObj] = useState(null);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const option = options.find((option: any) => option[optionKey] === value);
    setValueObj(option || null);
    setInputValue(option ? option[optionName] : '');
  }, [optionKey, optionName, options, value]);

  return (
    <FormControl size="small" sx={sx} fullWidth error={!isValid}>
      <Autocomplete
        disableClearable
        disabled={disabled}
        disableListWrap
        getOptionDisabled={(option: any) => option[optionKey] === disabledValue}
        getOptionLabel={(option: any) => option[optionName] || ''}
        id={id}
        inputValue={loading ? '' : inputValue}
        isOptionEqualToValue={(option, value) => value && option[optionKey] === value[optionKey]}
        loading={loading}
        onChange={(_, newValueObj, reason) => {
          if (reason === 'selectOption') onChange(newValueObj);
        }}
        onInputChange={(_, newInputValue, reason) => {
          if (reason === 'input') setInputValue(newInputValue);
        }}
        openOnFocus
        options={loading ? [] : options}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ textAlign: 'left' }}
            label={label}
            error={!isValid}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option) => {
          const key = option[optionKey];
          const value = option[optionName];
          return (
            <Typography component="li" {...props} id={key} key={key} noWrap>
              {formatOptions ? formatOptions(value) : value}
            </Typography>
          );
        }}
        size="small"
        value={valueObj}
      />
      {!isValid ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  );
};
