import { toast } from 'react-toastify';

import { extendedApiShadowVehiclesSlice } from './ShadowVehicleSlice';

export type DeleteVehiclesFunction = (vehicleToDelete: number) => Promise<string | never>;
export const useDeleteVehicle = () => {
  const [deleteVehicle, deleteVehicleResult] = extendedApiShadowVehiclesSlice.useVehiclesDeleteMutation();

  const deleteVehicles: DeleteVehiclesFunction = async (vehicleToDelete) => {
    try {
      return await deleteVehicle(vehicleToDelete)
        .unwrap()
        .then((data) => {
          toast.success('Vehicles performance Deleted ');
          return data;
        });
    } catch (err: any) {
      console.error('Vehicles performance not deleted.');
      throw err;
    }
  };

  return {
    deleteVehicleResult,
    deleteVehicles,
  };
};
