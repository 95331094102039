import { Stack } from '@mui/material';
import { useAppSelector } from '../../../hooks';
import { selectIsReadOnly, useGetRequestorsQuery } from '../../../reducers';
import { PartialSearchFormik } from '../../Common';

const Contacts = () => {
  const isReadOnly = useAppSelector(selectIsReadOnly);
  const { data: requestors = [], isLoading: isGetRequestorsLoading } = useGetRequestorsQuery('');
  return (
    <>
      <h4>Select the users who should be contacted about this search request.</h4>
      <Stack direction="row" width="100%" spacing={2} marginBottom="1rem" marginTop="1rem">
        <PartialSearchFormik
          disabled={isReadOnly}
          id="requestor-partial-search-formik"
          label="Requestor*"
          loading={isGetRequestorsLoading}
          name="requestorEmail"
          optionKey="workEmail"
          optionName="fullName"
          options={requestors}
        />
        <PartialSearchFormik
          disabled={isReadOnly}
          id="primary-consultant-partial-search-formik"
          label="Primary consultant*"
          loading={isGetRequestorsLoading}
          name="primaryConsultantEmail"
          optionKey="workEmail"
          optionName="fullName"
          options={requestors}
        />
      </Stack>
    </>
  );
};

export default Contacts;
