import { Tooltip, TextField } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useFormikContext } from 'formik';
import { ChangeEvent } from 'react';
import { useAppSelector } from '../../../../hooks';
import { PlanType, selectIsReadOnly, JobType, JobNestedErrorsType, JobNestedTouchedType } from '../../../../reducers';

const MandateSizeInput = ({ api, row: { id }, value }: GridRenderCellParams) => {
  const rowsData = api.getSortedRows() as PlanType[];
  const isReadOnly = useAppSelector(selectIsReadOnly);

  const { errors = {}, setFieldValue, touched = {} } = useFormikContext<JobType>();

  const currentValue = String(value).replaceAll(',', '');

  const handleMandateSizeBlur = () => {
    const editedRows = rowsData.map((_row) => {
      if (_row.id === id) {
        return {
          ..._row,
          planMandateSize: Number(currentValue),
        };
      } else return _row;
    });
    setFieldValue('plans', editedRows);
  };

  const handleMandateSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    api.updateRows([{ id: id, planMandateSize: event.target.value }]);
  };

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const errorMessage = (errors as JobNestedErrorsType).plans?.[id]?.planMandateSize;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const isTouched = (touched as JobNestedTouchedType).plans?.[id]?.planMandateSize;
  const isValid = !(isTouched && errorMessage);

  return (
    <Tooltip title={!isValid && errorMessage}>
      <TextField
        disabled={isReadOnly}
        error={!isValid}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9,]*' }}
        onBlur={handleMandateSizeBlur}
        onChange={handleMandateSizeChange}
        value={Number(currentValue).toLocaleString()}
        id={`${id}-mandate-size-id`}
      ></TextField>
    </Tooltip>
  );
};

export default MandateSizeInput;
