import { apiSlice } from './apiSlice';
import * as Globals from '../application/common';
import { toast } from 'react-toastify';

export interface DVEmailPlanType {
  planType: string;
  planSize: string;
  mandateSize: string;
  coveredByErisa: string;
  planCode: string;
}

export interface DVProjectType {
  asOfDate: string;
  dueAt: string;
  emailText: string;
  jobID: string;
  name: string;
  strategiesShortCode: string[];
}

export interface DVProjectResultType {
  as_of_date: string;
  due_at: string;
  entity_id: number;
  entity_type: string;
  error: string;
  is_internal: boolean;
  name: string;
  project_id: number;
  project_purpose_type: string;
  status: string;
  template_id: number;
}

export interface DVEmailType {
  jobName: string;
  fpl: string;
  region: string;
  mandateSize: string;
  totalMandateSize: string;
  vehiclesUnderConsideration: string;
  practiceGroup: string;
  alternatePracticeGroup: string;
  perfDate: string;
  charDate: string;
  nepcSearchId: string;
  plans: DVEmailPlanType[];
}

export const emptyDVEmail: DVEmailType = {
  jobName: '',
  fpl: '',
  region: '',
  mandateSize: '',
  totalMandateSize: '',
  vehiclesUnderConsideration: '',
  practiceGroup: '',
  alternatePracticeGroup: '',
  perfDate: '',
  charDate: '',
  nepcSearchId: '',
  plans: [],
};

export const extendedApiDiligenceVaultSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDiligenceVaultEmailByJobID: builder.query({
      transformResponse: (baseQueryReturnValue?: DVEmailType): DVEmailType => baseQueryReturnValue ?? emptyDVEmail,
      transformErrorResponse: (response) => {
        toast.error(`Error getting Diligence Vault e-mail body. Error: ${response.status}`);
        return response;
      },
      query: (jobID: string) => `${Globals.API.endpoints.DenodoDiligenceVault}/email/${jobID}`,
      providesTags: (result, error, arg) => [{ type: 'Job', id: arg }],
    }),
    createDiligenceVaultProjects: builder.mutation({
      transformResponse: (baseQueryReturnValue?: DVProjectResultType[]): DVProjectResultType[] =>
        baseQueryReturnValue ?? [],
      transformErrorResponse: (response) => {
        const results = response.data as DVProjectResultType[];
        const allResultsFailed = results.every((res) => res.status === 'fail');
        const strategiesNotFound = !results[0].status;
        if (allResultsFailed) {
          toast.error(`Create Diligence Vault Projects action failed. Error: ${results[0].error}`);
        }
        if (strategiesNotFound) {
          toast.warning(results[0].error);
        }
        toast.error(`Create Diligence Vault Projects action failed. Error: ${response.status}`);
        return response;
      },
      query: (projects: DVProjectType) => ({
        url: `${Globals.API.endpoints.SqlDiligenceVault}/projects/${projects.jobID}`,
        method: 'POST',
        body: projects,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Job', id: arg.jobID }],
    }),
  }),
});

export const { useGetDiligenceVaultEmailByJobIDQuery, useCreateDiligenceVaultProjectsMutation } =
  extendedApiDiligenceVaultSlice;
