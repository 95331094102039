import { useEffect, useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';

import { JobType, StrategyContactType, useGetStrategiesContactsByJobIDQuery } from '../../../reducers';
import { inputSequentialIds } from '../../../application/common';
import { SubmissionGrid } from '../../styles';
import { useFormikContext } from 'formik';

const columns: GridColDef[] = [
  {
    field: 'firm',
    headerName: 'Firm',
    headerAlign: 'left',
    width: 400,
  },
  {
    field: 'strategy',
    headerName: 'Strategy',
    headerAlign: 'left',
    width: 350,
  },
  {
    field: 'contactName',
    headerName: 'Contact Name',
    headerAlign: 'left',
    width: 350,
  },
  {
    field: 'contactEmailAddress',
    headerName: 'Contact Email Address',
    headerAlign: 'left',
    width: 310,
    cellClassName: 'contact-email-cell',
  },
  {
    field: 'status',
    headerName: 'Status',
    headerAlign: 'left',
    width: 165,
  },
];

const StrategiesContactsGrid = () => {
  const [strategyContactsRows, setStrategiesContactsRows] = useState<StrategyContactType[]>([]);

  const { initialValues } = useFormikContext<JobType>();

  const { data: strategyContacts = [] } = useGetStrategiesContactsByJobIDQuery(initialValues.jobID, {
    skip: !initialValues.jobID,
  });

  useEffect(() => {
    if (strategyContacts.length) {
      setStrategiesContactsRows(inputSequentialIds(strategyContacts) as StrategyContactType[]);
    }
  }, [strategyContacts]);
  return <SubmissionGrid columns={columns} rows={strategyContactsRows} autoHeight hideFooter />;
};

export default StrategiesContactsGrid;
