import { Stack, Tab, Tabs } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { createContext, useContext, useState } from 'react';

import { staticData, TabsOrientation } from '../../../application/common';
import { useMutateStrategyCharAum, useMutateStrategyCharEquityCap } from '../../../hooks/strategiesCharHooks';
import { useMutateStrategyCharEquitySec } from '../../../hooks/strategiesCharHooks/EquitySecHooks';
import { useMutateStrategyCharEquityStyle } from '../../../hooks/strategiesCharHooks/EquityStyleHooks';
import { useMutateStrategyCharFixedDuration } from '../../../hooks/strategiesCharHooks/FixedDurationHooks';
import { useMutateStrategyCharFixedIncome } from '../../../hooks/strategiesCharHooks/FixedIncomeHooks';
import { useMutateStrategyCharFixedRating } from '../../../hooks/strategiesCharHooks/FixedRatingHooks';
import { useMutateStrategyCharFixedSector } from '../../../hooks/strategiesCharHooks/FixedSectorHooks';
import { GridStrategyType } from '../../../reducers';
import { FirmType, useGetFirmsQuery } from '../../../reducers/firmsSlice';
import {
  useGetFirmStrategiesCharQuery,
  useGetStrategyCharAumQuery as UseGetStrategyCharAumQuery,
  useGetStrategyCharEquityMktCapQuery,
  useGetStrategyCharEquitySecQuery,
} from '../../../reducers/strategyCharacteristicsSlice';
import { PartialSearch } from '../../Common';
import { useShadowContext } from '../Shadow';
import { Fields, MainTabContent } from '../StyledShadow';
import { StrategyCharGrid } from './ShadowStrategyGrid';
import { AUMColumnDefs, AUMType, emptyAUMType } from './stepsSettings/Aum';
import {
  EmptyEquityStyleType,
  EquityStyleColumnDefs,
  EquityStyleType,
  useGetStrategyCharEquityStyleQuery,
} from './stepsSettings/EquityStyle';
import {
  EmptyfixedDurationType,
  FixedDurationColumnDefs,
  fixedDurationType,
  useGetStrategyCharFixedDurationQuery,
} from './stepsSettings/fixedDuration';
import {
  EmptyfixedIncomeType,
  FixedIncomeColumnDefs,
  fixedIncomeType,
  useGetStrategyCharFixedIncomeQuery,
} from './stepsSettings/fixedIncome';
import {
  EmptyfixedRatingType,
  FixedRatingColumnDefs,
  fixedRatingType,
  useGetStrategyCharFixedRatingQuery,
} from './stepsSettings/fixedRating';
import {
  EmptyfixedSectorType,
  FixedSectorColumnDefs,
  fixedSectorType,
  useGetStrategyCharFixedSectorQuery,
} from './stepsSettings/FixedSector';
import {
  emptyEquityMktCapType,
  emptyEquitySecType,
  EquityMktCapDefs,
  EquityMktCapType,
  EquitySecColumnDefs,
  EquitySecType,
} from './StrategiesColumnDefs';
interface strategyStepType {
  title: string;
  stepId: string;
  columnDefs: GridColDef[];
  result: any;
  submitQuery: (dataRows: any[]) => Promise<any[] | never>;
  deleteQuery: (itemToDelete: any) => Promise<string | never>;
  deleteValidation?: (itemToDelete: any) => boolean;
  emptyObj: object;
}

export interface strategyStepDataType {
  aum: AUMType[];
  equityMkt: EquityMktCapType[];
  equitySector: EquitySecType[];
  equityStyle: EquityStyleType[];
  fixedChars: fixedIncomeType[];
  fixedDuration: fixedDurationType[];
  fixedRating: fixedRatingType[];
  fixedSector: fixedSectorType[];
}

export const StrategyCharacteristicsContext = createContext<strategyStepType>({
  title: '',
  stepId: 'aum',
  columnDefs: [] as GridColDef[],
  submitQuery: () => new Promise(() => ({})),
  result: () => UseGetStrategyCharAumQuery(''),
  deleteQuery: (itemTodelete: string) => new Promise(() => ''),
  emptyObj: {},
});

export const useStrategyCharacteristicsContext = () => useContext(StrategyCharacteristicsContext);

export const Strategy = () => {
  const {
    selectedFirmShortCode,
    setSelectedFirmShortCode,
    selectedFirmStrategyShortCode,
    setSelectedFirmStrategyShortCode,
  } = useShadowContext();
  const [activeStep, setActiveStep] = useState(0);

  const getFirmsResult = useGetFirmsQuery('');

  const { submitStrateGyCharAUM, deleteStrateGyCharAUM } = useMutateStrategyCharAum();
  const { submitStrateGyCharEquity, deleteStrateGyCharEquityMkt } = useMutateStrategyCharEquityCap();
  const { submitStrateGyCharEquitySec, deleteStrateGyCharEquitySec } = useMutateStrategyCharEquitySec();
  const { submitStrateGyCharEquityStyle, deleteStrateGyCharEquityStyle } = useMutateStrategyCharEquityStyle();
  const { submitStrateGyCharFixedIncome, deleteStrateGyCharFixedIncome } = useMutateStrategyCharFixedIncome();
  const { submitStrateGyCharFixedRating, deleteStrateGyCharFixedRating } = useMutateStrategyCharFixedRating();
  const { submitStrateGyCharFixedDuration, deleteStrateGyCharFixedDuration } = useMutateStrategyCharFixedDuration();
  const { submitStrateGyCharFixedSector, deleteStrateGyCharFixedSector } = useMutateStrategyCharFixedSector();

  const handleTabChange = (newFormStep: number) => {
    setActiveStep(newFormStep);
  };

  const getFirmsStrategiesResult = useGetFirmStrategiesCharQuery(selectedFirmShortCode, {
    skip: !selectedFirmShortCode,
  });

  const handleFirmChange = (newValueObj: FirmType) => {
    const { firmShortCode } = newValueObj;
    setSelectedFirmShortCode(firmShortCode);
    setSelectedFirmStrategyShortCode('');
  };

  const a11yProps = (index: number) => ({
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
    form: `form-${activeStep + 1}`,
    type: 'submit',
  });

  const resultAUM = UseGetStrategyCharAumQuery(selectedFirmStrategyShortCode, {
    skip: !selectedFirmStrategyShortCode,
  });

  const resultEquityMKT = useGetStrategyCharEquityMktCapQuery(selectedFirmStrategyShortCode, {
    skip: !selectedFirmStrategyShortCode,
  });

  const resultEquitySec = useGetStrategyCharEquitySecQuery(selectedFirmStrategyShortCode, {
    skip: !selectedFirmStrategyShortCode,
  });

  const resultEquityStyle = useGetStrategyCharEquityStyleQuery(selectedFirmStrategyShortCode, {
    skip: !selectedFirmStrategyShortCode,
  });

  const resultFixedIncome = useGetStrategyCharFixedIncomeQuery(selectedFirmStrategyShortCode, {
    skip: !selectedFirmStrategyShortCode,
  });

  const resultFixedRating = useGetStrategyCharFixedRatingQuery(selectedFirmStrategyShortCode, {
    skip: !selectedFirmStrategyShortCode,
  });

  const resultFixedDuration = useGetStrategyCharFixedDurationQuery(selectedFirmStrategyShortCode, {
    skip: !selectedFirmStrategyShortCode,
  });

  const resultFixedSector = useGetStrategyCharFixedSectorQuery(selectedFirmStrategyShortCode, {
    skip: !selectedFirmStrategyShortCode,
  });

  const steps: strategyStepType[] = [
    {
      title: 'AUM',
      stepId: 'aum',
      columnDefs: AUMColumnDefs,
      result: resultAUM,
      submitQuery: (dataRows: AUMType[]) => submitStrateGyCharAUM(dataRows),
      deleteQuery: (itemTodelete: AUMType) => deleteStrateGyCharAUM(itemTodelete),
      deleteValidation: (aum: AUMType) => aum.dataSourceId !== staticData.NEPC_CREATED_DS_ID,
      emptyObj: emptyAUMType,
    },
    {
      title: 'Equity - Mkt Cap/Chars',
      stepId: 'equityMkt',
      columnDefs: EquityMktCapDefs,
      result: resultEquityMKT,
      submitQuery: (dataRows: EquityMktCapType[]) => submitStrateGyCharEquity(dataRows),
      deleteQuery: (itemTodelete: EquityMktCapType) => deleteStrateGyCharEquityMkt(itemTodelete),
      emptyObj: emptyEquityMktCapType,
    },
    {
      title: 'equity - sector',
      stepId: 'equitySector',
      columnDefs: EquitySecColumnDefs,
      result: resultEquitySec,
      submitQuery: (dataRows: EquitySecType[]) => submitStrateGyCharEquitySec(dataRows),
      deleteQuery: (itemTodelete: EquitySecType) => deleteStrateGyCharEquitySec(itemTodelete),
      emptyObj: emptyEquitySecType,
    },
    {
      title: 'Equity - Style',
      stepId: 'equityStyle',
      columnDefs: EquityStyleColumnDefs,
      result: resultEquityStyle,
      submitQuery: (dataRows: EquityStyleType[]) => submitStrateGyCharEquityStyle(dataRows),
      deleteQuery: (itemTodelete: EquityStyleType) => deleteStrateGyCharEquityStyle(itemTodelete),
      emptyObj: EmptyEquityStyleType,
    },
    {
      title: 'Fixed Inc - Chars',
      stepId: 'fixedChars',
      columnDefs: FixedIncomeColumnDefs,
      result: resultFixedIncome,
      submitQuery: (dataRows: fixedIncomeType[]) => submitStrateGyCharFixedIncome(dataRows),
      deleteQuery: (itemTodelete: fixedIncomeType) => deleteStrateGyCharFixedIncome(itemTodelete),
      emptyObj: EmptyfixedIncomeType,
    },
    {
      title: 'Fixed Inc - Duration',
      stepId: 'fixedDuration',
      columnDefs: FixedDurationColumnDefs,
      result: resultFixedDuration,
      submitQuery: (dataRows: fixedDurationType[]) => submitStrateGyCharFixedDuration(dataRows),
      deleteQuery: (itemTodelete: fixedDurationType) => deleteStrateGyCharFixedDuration(itemTodelete),
      emptyObj: EmptyfixedDurationType,
    },
    {
      title: 'Fixed Inc - Rating',
      stepId: 'fixedRating',
      columnDefs: FixedRatingColumnDefs,
      result: resultFixedRating,
      submitQuery: (dataRows: fixedRatingType[]) => submitStrateGyCharFixedRating(dataRows),
      deleteQuery: (itemTodelete: fixedRatingType) => deleteStrateGyCharFixedRating(itemTodelete),
      emptyObj: EmptyfixedRatingType,
    },
    {
      title: 'Fixed Inc - Sector',
      stepId: 'fixedSector',
      columnDefs: FixedSectorColumnDefs,
      result: resultFixedSector,
      submitQuery: (dataRows: fixedSectorType[]) => submitStrateGyCharFixedSector(dataRows),
      deleteQuery: (itemTodelete: fixedSectorType) => deleteStrateGyCharFixedSector(itemTodelete),
      emptyObj: EmptyfixedSectorType,
    },
  ];

  return (
    <MainTabContent>
      <Fields spacing={2} direction="column">
        <Stack direction="row" gap={4}>
          <Stack gap={1} direction="column" whiteSpace="nowrap" alignItems="start" minWidth="60%">
            <PartialSearch
              disabled={!getFirmsResult.data}
              id="firm-partial-search"
              label="Firm"
              loading={getFirmsResult.isLoading}
              onChange={handleFirmChange}
              noOptionsText="No Firms"
              optionKey="firmShortCode"
              optionName="firmLegalName"
              options={getFirmsResult.data}
              value={selectedFirmShortCode}
              sx={{ minWidth: '100%' }}
            />
            <PartialSearch
              disabled={!getFirmsStrategiesResult.data || selectedFirmShortCode === ''}
              id="strategy-partial-search"
              label="Strategy"
              loading={getFirmsStrategiesResult.isLoading}
              onChange={(newValueObj: GridStrategyType) => {
                setSelectedFirmStrategyShortCode(newValueObj.strategyShortCode);
              }}
              noOptionsText="No strategies"
              optionKey="strategyShortCode"
              optionName="strategyName"
              options={getFirmsStrategiesResult.data}
              value={selectedFirmStrategyShortCode}
              sx={{ minWidth: '100%' }}
            />
          </Stack>
          <Stack direction="column" justifyContent="space-around" width="30%">
            <Stack direction="row" gap={1}>
              Firm ID: <strong>{selectedFirmShortCode}</strong>
            </Stack>
            <Stack direction={{ lg: 'column', xl: 'row' }} alignItems="center">
              <Stack direction="row" gap={1} sx={{ textWrap: 'nowrap' }}>
                Strategy ID: <strong>{selectedFirmStrategyShortCode}</strong>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Fields>
      <Tabs
        orientation={TabsOrientation()}
        value={activeStep}
        variant="scrollable"
        aria-label="Vertical tabs example"
        onChange={(e, newTabID) => {
          handleTabChange(newTabID);
        }}
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        {steps.map(({ title }, index) => (
          <Tab label={title} key={title.trim()} {...a11yProps(index)} wrapped />
        ))}
      </Tabs>
      <Stack width="100%" overflow="overlay">
        <StrategyCharacteristicsContext.Provider value={{ ...steps[activeStep] }}>
          <StrategyCharGrid />
        </StrategyCharacteristicsContext.Provider>
      </Stack>
    </MainTabContent>
  );
};
