import DeleteIcon from '@mui/icons-material/Delete';
import { Checkbox, IconButton, MenuItem, Select, SelectChangeEvent, Tooltip } from '@mui/material';
import {
  DataGrid,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridColumnGroupingModel,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowModel,
  useGridApiContext,
} from '@mui/x-data-grid';
import moment from 'moment';

import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import React, { useState } from 'react';
import { SlugId, staticData } from '../../../../application/common';
import { ShadowFeeType } from '../../../../reducers';
import { StyledTooltip } from '../../../styles';
import ShadowGridNumberInput from '../../Strategy/ShadowGridNumberInput';
import { useFeesContext } from '../Fees';

export const ShadowFeesGrid = () => {
  const { setFieldValue, values, deleteFee, isFeesForPerformanceFetching, errors } = useFeesContext();
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

  const { fees = [] } = values;

  const _deleteRow = (id: number) => {
    if (fees.length > 0) {
      const filteredFees = [
        ...fees.filter((row) => {
          if (row.id === id) {
            if (row.feeId) {
              deleteFee(row.feeId);
            }
            return false;
          }
          return true;
        }),
      ];
      setFieldValue('fees', filteredFees);
    }
  };

  const EditInputCell = (props: GridRenderCellParams<any, number>) => {
    const { id, value, field, hasFocus } = props;
    const apiRef = useGridApiContext();
    const ref = React.useRef<HTMLElement>();
    const numericalInput = typeof value === 'number';

    const handleChange = (event: SelectChangeEvent) => {
      const parsedValue = Number(event.target.value);
      apiRef.current.setEditCellValue({ id, field, value: event.target.value });
      if (field === 'feeTypeId') {
        const feeTypeName: string | undefined = staticData.feeTypes.find((fee) => fee.value === parsedValue)?.name;
        apiRef.current.setEditCellValue({
          id,
          field: 'feeTypeName',
          value: feeTypeName,
        });
        return processRowUpdate({
          ...apiRef.current.getRow(id),
          [field]: parsedValue,
          feeTypeName,
        });
      }
      processRowUpdate({ ...apiRef.current.getRow(id), [field]: Number(event.target.value) });
    };

    useEnhancedEffect(() => {
      if (hasFocus && ref.current) {
        const input = ref.current.querySelector<HTMLInputElement>(`input[value="${value}"]`);
        input?.focus();
      }
    }, [hasFocus, value]);

    const { hasError, feesMessage } = getErrors(field);

    if (field === 'feeTypeId') {
      return (
        <Tooltip title={feesMessage}>
          <Select
            ref={ref}
            error={hasError}
            labelId={`feeTypeId-select-label-${id}`}
            id={`feeTypeId-select-${id}`}
            value={value?.toString()}
            onChange={handleChange}
            fullWidth
          >
            {staticData.feeTypes.map((fee) => (
              <MenuItem value={fee.value} key={`${SlugId(fee.name)}-select-filter`}>
                {fee.name}
              </MenuItem>
            ))}
          </Select>
        </Tooltip>
      );
    }
    return ShadowGridNumberInput(props, numericalInput, handleChange);
  };

  const EditInputBooleanCell = (props: GridRenderCellParams<any, boolean>) => {
    const { id, value, field, hasFocus } = props;
    const apiRef = useGridApiContext();
    const ref = React.useRef<HTMLElement>();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      apiRef.current.setEditCellValue({ id, field, value: event.target.checked });
      processRowUpdate({ ...apiRef.current.getRow(id), [field]: event.target.checked });
    };

    useEnhancedEffect(() => {
      if (hasFocus && ref.current) {
        const input = ref.current.querySelector<HTMLInputElement>(`input[value="${value}"]`);
        input?.focus();
      }
    }, [hasFocus, value]);

    return <Checkbox checked={value} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />;
  };

  const _preProcessEditCellProps = (params: GridPreProcessEditCellProps, field: string) => {
    const { hasError } = getErrors(field);

    return { ...params.props, error: hasError };
  };

  const getErrors = (field: string, index = 0) => {
    const _errors = errors as unknown as { fees: ShadowFeeType[] };
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const hasError = Object.keys(_errors.fees?.[index]?.[field as keyof ShadowFeeType] ?? {}).length > 0;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const feesMessage = _errors.fees?.[index]?.[field as keyof ShadowFeeType];
    return { hasError, feesMessage };
  };

  const getEditsProps = (columnName: string) => ({
    cellClassName: (params: GridCellParams) => {
      const errorRowId = fees.findIndex((fee) => params.id === fee.feeId);
      return getErrors(columnName, errorRowId).hasError ? 'Mui-error' : '';
    },
    renderCell: (params: GridRenderCellParams) => {
      const errorRowId = fees.findIndex((fee) => params.id === fee.feeId);
      return (
        <StyledTooltip title={getErrors(columnName, errorRowId).feesMessage}>
          <span style={{ paddingLeft: '100%' }}>{params.value}</span>
        </StyledTooltip>
      );
    },
    renderEditCell: (params: GridRenderEditCellParams) => {
      const errorRowId = fees.findIndex((fee) => params.id === fee.feeId);
      const { feesMessage } = getErrors(columnName, errorRowId);
      return (
        <Tooltip title={feesMessage}>
          <EditInputCell {...params} />
        </Tooltip>
      );
    },
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => _preProcessEditCellProps(params, columnName),
  });

  const shadowFeesColumns: GridColDef[] = [
    {
      field: 'deleteRow',
      headerName: '',
      headerAlign: 'center',
      width: 50,
      type: 'actions',
      cellClassName: 'actions',
      renderCell: (params: any) => (
        <IconButton id={`delete-btn-${params.row.id}`} aria-label="delete" onClick={() => _deleteRow(params.row.id)}>
          <DeleteIcon />
        </IconButton>
      ),
      sortable: false,
    },
    {
      field: 'feeTypeId',
      headerName: 'Fee Type',
      headerAlign: 'center',
      editable: true,
      renderCell: (params: GridRenderCellParams) => {
        const errorRowId = fees.findIndex((fee) => params.id === fee.feeId);
        const { hasError, feesMessage } = getErrors('feeTypeId', errorRowId);
        return (
          <StyledTooltip title={feesMessage}>
            <Select
              error={hasError}
              labelId={`feeTypeId-select-label-${params.id}`}
              id={`feeTypeId-select-${params.id}`}
              value={params.row.feeTypeId}
              fullWidth
              readOnly
            >
              {staticData.feeTypes.map((fee) => (
                <MenuItem value={fee.value} key={`${SlugId(fee.name)}-select-filter`}>
                  {fee.name}
                </MenuItem>
              ))}
            </Select>
          </StyledTooltip>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        const { feesMessage } = getErrors('feeTypeId');
        return (
          <Tooltip title={feesMessage}>
            <EditInputCell {...params} />
          </Tooltip>
        );
      },
      preProcessEditCellProps: (params) => _preProcessEditCellProps(params, 'feeTypeId'),
      width: 200,
      sortable: false,
    },
    {
      field: 'locked',
      headerName: 'Locked',
      type: 'boolean',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'dateLocked',
      headerName: 'Date Locked',
      valueFormatter: ({ value }) => moment(value).format('MM/DD/YYYY'),
      type: 'date',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
    },
    {
      field: 'dataSourceName',
      headerName: 'Data Source',
      minWidth: 150,
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'modifiedDate',
      headerName: 'Modified Date',
      valueFormatter: ({ value }) => moment(value).format('MM/DD/YYYY'),
      type: 'date',
      minWidth: 125,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
    },
    {
      field: 'modifiedBy',
      headerName: 'Modified By',
      width: 175,
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'indSbFeeComparison',
      headerName: 'Fee Comp',
      headerAlign: 'center',
      editable: true,
      type: 'boolean',
      renderEditCell: (params: GridRenderEditCellParams) => <EditInputBooleanCell {...params} />,
      sortable: false,
    },
    {
      field: 'allBps',
      headerName: 'All Bps',
      headerAlign: 'center',
      align: 'right',
      minWidth: 125,
      editable: true,
      ...getEditsProps('allBps'),
      sortable: false,
    },
    {
      field: 'tier1Amount',
      headerName: 'Amount',
      headerAlign: 'center',
      align: 'right',
      minWidth: 125,
      editable: true,
      ...getEditsProps('tier1Amount'),
      sortable: false,
    },
    {
      field: 'tier1Bps',
      headerName: 'BPS',
      headerAlign: 'center',
      align: 'right',
      minWidth: 125,
      editable: true,
      ...getEditsProps('tier1Bps'),
      sortable: false,
    },
    {
      field: 'tier2Amount',
      headerName: 'Amount',
      headerAlign: 'center',
      align: 'right',
      minWidth: 125,
      editable: true,
      ...getEditsProps('tier2Amount'),
      sortable: false,
    },
    {
      field: 'tier2Bps',
      headerName: 'BPS',
      headerAlign: 'center',
      align: 'right',
      minWidth: 125,
      editable: true,
      ...getEditsProps('tier2Bps'),
      sortable: false,
    },
    {
      field: 'tier3Amount',
      headerName: 'Amount',
      headerAlign: 'center',
      align: 'right',
      minWidth: 125,
      editable: true,
      ...getEditsProps('tier3Amount'),
      sortable: false,
    },
    {
      field: 'tier3Bps',
      headerName: 'BPS',
      headerAlign: 'center',
      align: 'right',
      minWidth: 125,
      editable: true,
      ...getEditsProps('tier3Bps'),
      sortable: false,
    },
    {
      field: 'tier4Amount',
      headerName: 'Amount',
      headerAlign: 'center',
      align: 'right',
      minWidth: 125,
      editable: true,
      ...getEditsProps('tier4Amount'),
      sortable: false,
    },
    {
      field: 'tier4Bps',
      headerName: 'BPS',
      headerAlign: 'center',
      align: 'right',
      minWidth: 125,
      editable: true,
      ...getEditsProps('tier4Bps'),
      sortable: false,
    },
    {
      field: 'tier5Amount',
      headerName: 'Amount',
      headerAlign: 'center',
      align: 'right',
      minWidth: 125,
      editable: true,
      ...getEditsProps('tier5Amount'),
      sortable: false,
    },
    {
      field: 'tier5Bps',
      headerName: 'BPS',
      headerAlign: 'center',
      align: 'right',
      minWidth: 125,
      editable: true,
      ...getEditsProps('tier5Bps'),
      sortable: false,
    },
    {
      field: 'tier6Amount',
      headerName: 'Amount',
      headerAlign: 'center',
      align: 'right',
      minWidth: 125,
      editable: true,
      ...getEditsProps('tier6Amount'),
      sortable: false,
    },
    {
      field: 'tier6Bps',
      headerName: 'BPS',
      headerAlign: 'center',
      align: 'right',
      minWidth: 125,
      editable: true,
      ...getEditsProps('tier6Bps'),
      sortable: false,
    },
    {
      field: 'tier7Amount',
      headerName: 'Amount',
      headerAlign: 'center',
      align: 'right',
      minWidth: 125,
      editable: true,
      ...getEditsProps('tier7Amount'),
      sortable: false,
    },
    {
      field: 'tier7Bps',
      headerName: 'BPS',
      headerAlign: 'center',
      align: 'right',
      minWidth: 125,
      editable: true,
      ...getEditsProps('tier7Bps'),
      sortable: false,
    },
    {
      field: 'balanceBps',
      headerName: 'Balance BPS',
      headerAlign: 'center',
      align: 'right',
      minWidth: 125,
      editable: true,
      ...getEditsProps('balanceBps'),
      sortable: false,
    },
  ];

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: 'Tier 1',
      headerAlign: 'center',
      children: [{ field: 'tier1Amount' }, { field: 'tier1Bps' }],
    },
    {
      groupId: 'Tier 2',
      headerAlign: 'center',
      children: [{ field: 'tier2Amount' }, { field: 'tier2Bps' }],
    },
    {
      groupId: 'Tier 3',
      headerAlign: 'center',
      children: [{ field: 'tier3Amount' }, { field: 'tier3Bps' }],
    },
    {
      groupId: 'Tier 4',
      headerAlign: 'center',
      children: [{ field: 'tier4Amount' }, { field: 'tier4Bps' }],
    },
    {
      groupId: 'Tier 5',
      headerAlign: 'center',
      children: [{ field: 'tier5Amount' }, { field: 'tier5Bps' }],
    },
    {
      groupId: 'Tier 6',
      headerAlign: 'center',
      children: [{ field: 'tier6Amount' }, { field: 'tier6Bps' }],
    },
    {
      groupId: 'Tier 7',
      headerAlign: 'center',
      children: [{ field: 'tier7Amount' }, { field: 'tier7Bps' }],
    },
  ];

  const handleCellClick = React.useCallback((params: GridCellParams, event: React.MouseEvent) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if ((event.target as any).nodeType === 1 && !event.currentTarget.contains(event.target as Element)) {
      return;
    }

    // eslint-disable-next-line arrow-body-style
    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {},
            ),
          }),
          {},
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {},
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = React.useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);

  const processRowUpdate = (newRow: GridRowModel) => {
    const editedRows = fees.map((row: ShadowFeeType) => {
      if (row.id === newRow.id) {
        return {
          ...newRow,
        };
      } else return row;
    });
    setFieldValue('fees', editedRows);
    return editedRows;
  };

  const dataGridStyles = {
    boxShadow: 0,
    '.MuiSelect-select,.MuiInputBase-input': {
      padding: '8px',
    },
    '.MuiDataGrid-columnHeaderTitle': {
      maxWidth: '90px',
      textWrap: 'wrap',
      textAlign: 'center',
      lineHeight: '20px',
    },
    'input.MuiInputBase-input': {
      textAlign: 'end',
    },
    '.MuiDataGrid-cell': {
      padding: '0 0.5rem',
    },
    '.MuiDataGrid-row--editing': {
      boxShadow: 'none',
    },
    '& .Mui-error': {
      border: 'solid 1px red',
    },
  };

  return (
    <DataGrid
      experimentalFeatures={{ columnGrouping: true }}
      rows={fees}
      columns={shadowFeesColumns}
      columnGroupingModel={columnGroupingModel}
      loading={isFeesForPerformanceFetching}
      cellModesModel={cellModesModel}
      onCellModesModelChange={handleCellModesModelChange}
      onCellClick={handleCellClick}
      sx={dataGridStyles}
      hideFooter
      autoHeight
    />
  );
};
