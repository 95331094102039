import { toast } from 'react-toastify';
import {
  EquityStyleType,
  extendedApiStrategyCharacteristcsEquityStyleSlice,
} from '../../components/Shadow/Strategy/stepsSettings/EquityStyle';

type StrategyCharacteristicsEquitysSecSubmitFunction = (
  equitysSecsToSubmit: EquityStyleType[],
) => Promise<EquityStyleType[] | never>;

type StrategyCharacteristicsEquityStyleDeleteFunction = (
  strategyCharEquityToDelete: EquityStyleType,
) => Promise<string | never>;
export const useMutateStrategyCharEquityStyle = () => {
  const [submitStrategiesCharEquityStyle, submitStrategiesCharEquityStyleResult] =
    extendedApiStrategyCharacteristcsEquityStyleSlice.useStrategyCharEquityStyleSubmitMutation();
  const [deleteStrategyEquityStyle, deleteStrateGyCharEquityStyleResult] =
    extendedApiStrategyCharacteristcsEquityStyleSlice.useStrategyCharEquityStyleDeleteMutation();

  const submitStrateGyCharEquityStyle: StrategyCharacteristicsEquitysSecSubmitFunction = async (
    equitysStyleToSubmit,
  ) => {
    try {
      const mappedEquitysStyle = equitysStyleToSubmit.map((equityStyle) => ({
        ...equityStyle,
        modifiedDate: undefined,
      }));
      return await submitStrategiesCharEquityStyle(mappedEquitysStyle)
        .unwrap()
        .then((data) => {
          toast.success('Equities Style submitted successfully');
          return data;
        });
    } catch (err: any) {
      console.error('Equities Style not submitted.');
      throw err;
    }
  };

  const deleteStrateGyCharEquityStyle: StrategyCharacteristicsEquityStyleDeleteFunction = async (
    strategyCharEquityStyleToDelete,
  ) => {
    try {
      return await deleteStrategyEquityStyle(strategyCharEquityStyleToDelete.strategyCharId)
        .unwrap()
        .then((data) => {
          toast.success('Equity Style Deleted');
          return data;
        });
    } catch (err: any) {
      toast.error('Strategy Characteristics Equity Style not deleted.');
      console.error('Strategy Characteristics Equity Style not deleted.');
      throw err;
    }
  };

  return {
    submitStrateGyCharEquityStyle,
    submitStrategiesCharEquityStyleResult,
    deleteStrateGyCharEquityStyle,
    deleteStrateGyCharEquityStyleResult,
  };
};
