import { apiSlice } from './apiSlice';
import * as Globals from '../application/common';
import { toast } from 'react-toastify';

export interface RequestorType {
  workEmail: string;
  fullName: string;
}

export const extendedApiRequestorsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRequestors: builder.query({
      transformResponse: (baseQueryReturnValue?: RequestorType[]): RequestorType[] => baseQueryReturnValue ?? [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting requestors dropdown list. Error: ${response.status}`);
        return response;
      },
      query: () => Globals.API.endpoints.Requestors,
      keepUnusedDataFor: 600,
    }),
  }),
});

export const { useGetRequestorsQuery } = extendedApiRequestorsSlice;
