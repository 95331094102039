import { GridColDef } from '@mui/x-data-grid';
import { DateFormatter, staticData } from '../../../../application/common';

export interface StrategyCharacteristicsBaseType {
  isTouched: boolean;
  id: number;
  strategyCharId: number;
  firmShortCode: string;
  firmLegalName: string;
  strategyShortCode: string;
  strategyName: string;
  year: number;
  month: number;
  dataSourceId: number;
  dataSourceName: string;
  isLocked: boolean;
  dateLocked: string;
  modifiedDate?: string;
  modifiedBy: string;
}

export const EmptyBaseType: StrategyCharacteristicsBaseType = {
  isTouched: true,
  dataSourceId: staticData.NEPC_CREATED_DS_ID,
  id: 0,
  strategyCharId: 0,
  firmShortCode: '',
  firmLegalName: '',
  strategyShortCode: '',
  strategyName: '',
  year: 0,
  month: 3,
  isLocked: false,
  dateLocked: '',
  modifiedDate: '',
  modifiedBy: '',
  dataSourceName: '',
};

export const BaseColumns = (columns: GridColDef[]): GridColDef[] => [
  {
    field: 'year',
    headerName: 'Year',
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params) => params.value.toString(),
    editable: true,
    type: 'string',
    width: 75,
  },
  {
    field: 'month',
    headerName: 'Month',
    headerAlign: 'center',
    align: 'center',
    editable: true,
    width: 75,
  },
  {
    field: 'isLocked',
    headerName: 'Locked',
    type: 'boolean',
    editable: true,
    headerAlign: 'center',
    sortable: false,
  },
  {
    field: 'dateLocked',
    headerName: 'Date Locked',
    valueFormatter: DateFormatter,
    type: 'date',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    field: 'dataSourceName',
    headerName: 'Data Source',
    headerAlign: 'center',
    align: 'center',
    minWidth: 150,
    sortable: false,
  },
  ...columns,
  {
    field: 'modifiedBy',
    headerName: 'Modified By',
    width: 175,
    headerAlign: 'center',
    sortable: false,
  },
  {
    field: 'modifiedDate',
    headerName: 'Modified Date',
    valueFormatter: DateFormatter,
    type: 'date',
    minWidth: 125,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    field: 'compDate',
    type: 'string',
    valueSetter: (params) => `${params.row.month}-${params.row.year}`,
    valueGetter: (params) => `${params.row.month}-${params.row.year}`,
    hideable: true,
  },
];
