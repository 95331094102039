import { Divider, Stack } from '@mui/material';
import { useAppSelector } from '../../../hooks';
import { selectIsLimitedAccess } from '../../../reducers';
import {
  FplSelection,
  IncumbentSelection,
  ClientStrategySelection,
  RiskFreeBenchmark,
  ResearchComments,
  HeaderWithCounter,
} from './StrategiesComponents';
import { StrategiesProvider } from './StrategiesContext';
import { StrategiesGrid, StrategiesGridEdit } from './StrategiesGrid';

export const Strategies = () => {
  const isLimitedAccess = useAppSelector(selectIsLimitedAccess);

  return (
    <StrategiesProvider>
      <FplSelection />
      <Divider flexItem />
      <>
        <IncumbentSelection />
        <Divider flexItem />
      </>
      <>
        <ClientStrategySelection />
        <Divider flexItem />
      </>
      {isLimitedAccess ? null : (
        <>
          <RiskFreeBenchmark />
          <Divider flexItem />
        </>
      )}
      <ResearchComments />
      <Divider flexItem />
      <Stack>
        <HeaderWithCounter />
        <StrategiesGrid />
      </Stack>
      <Divider flexItem />
      {isLimitedAccess ? null : <StrategiesGridEdit />}
    </StrategiesProvider>
  );
};
