import { extendedApiNotificationsSlice, managerNotificationsType } from '../reducers/notificationsSlice';

export type SubmitNotificationsFunction = (arg: {
  notifications: managerNotificationsType;
}) => Promise<managerNotificationsType | never>;

export const useSaveNotifications = () => {
  const [mutate, saveNotificationsResult] = extendedApiNotificationsSlice.useRunSaveNotificationsMutation({
    fixedCacheKey: 'use-save-notifications-api',
  });

  const saveNotifications: SubmitNotificationsFunction = async ({ notifications }) => {
    try {
      const cleanNotifications = {
        ...notifications,
        modifiedDate: undefined,
      };
      return await mutate(cleanNotifications).unwrap();
    } catch (err: any) {
      console.error('Notifications not saved.');
      throw err;
    }
  };

  return {
    saveNotificationsResult,
    saveNotifications,
  };
};
