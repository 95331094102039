import { apiSlice } from './apiSlice';
import * as Globals from '../application/common';
import { toast } from 'react-toastify';
import { FormikErrors, FormikTouched } from 'formik';

export interface FeeType {
  feeID: number;
  feeName: string;
}

export interface ShadowFeeType {
  id: number;
  feeId: number;
  feeName: string;
  allBps?: number;
  balanceBps?: number;
  dataSourceId?: number;
  dataSourceName?: string;
  dateLocked?: string;
  feeIdFromSource?: string;
  feeNameForDisplay?: string;
  feeTypeId?: number;
  feeTypeName?: string;
  firmNepcShortCode?: string;
  firmStrategyDisplayName?: string;
  indSbFeeComparison?: boolean;
  jobId?: string;
  jobName?: string;
  locked?: boolean;
  modifiedBy?: string;
  modifiedDate?: string;
  strategyNepcShortCode?: string;
  tier1Amount?: number;
  tier1Bps?: number;
  tier2Amount?: number;
  tier2Bps?: number;
  tier3Amount?: number;
  tier3Bps?: number;
  tier4Amount?: number;
  tier4Bps?: number;
  tier5Amount?: number;
  tier5Bps?: number;
  tier6Amount?: number;
  tier6Bps?: number;
  tier7Amount?: number;
  tier7Bps?: number;
}

export interface ShadowFeeNestedErrorsType {
  fees: FormikErrors<ShadowFeeType>[];
}

export interface ShadowFeeNestedTouchedType {
  fees: FormikTouched<ShadowFeeType>[];
}

export const extendedApiFeesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFees: builder.query({
      transformResponse: (baseQueryReturnValue?: FeeType[]): FeeType[] =>
        baseQueryReturnValue ? [{ feeID: 0, feeName: '-none-' }, ...baseQueryReturnValue] : [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting fees dropdown list. Error: ${response.status}`);
        return response;
      },
      query: (strategyID: string) => `${Globals.API.endpoints.Fees}/${strategyID}`,
      keepUnusedDataFor: 600,
    }),
    getFeesForPerformance: builder.query({
      transformResponse: (baseQueryReturnValue?: ShadowFeeType[]): ShadowFeeType[] =>
        baseQueryReturnValue ? [...baseQueryReturnValue] : [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting fees dropdown list. Error: ${response.status}`);
        return response;
      },
      query: ({ jobId, strategyShortCode }) => `${Globals.API.endpoints.ShadowFees}/${jobId}/${strategyShortCode}`,
      keepUnusedDataFor: 600,
      providesTags: (result, error, arg) => [{ type: 'Fees' }],
    }),
    runFeesSubmit: builder.mutation({
      transformResponse: (baseQueryReturnValue?: ShadowFeeType[]): ShadowFeeType[] => baseQueryReturnValue ?? [],
      transformErrorResponse: (response) => {
        toast.error(`Error running fees submit. Error: ${response.data}`);
        return response;
      },
      query: (feesToSubmit: ShadowFeeType[]) => ({
        url: `${Globals.API.endpoints.FeeSubmit}`,
        method: 'POST',
        body: feesToSubmit,
      }),
      invalidatesTags: (result, error, arg) => (error ? [] : [{ type: 'Fees' }]),
    }),
    runFeesDelete: builder.mutation({
      transformResponse: (baseQueryReturnValue?: string): string => baseQueryReturnValue ?? '',
      transformErrorResponse: (response) => {
        toast.error(`Error running fees delete. Error: ${response.status}`);
        return response;
      },
      query: (feeToDelete: number) => ({
        url: `${Globals.API.endpoints.FeeDelete}/${feeToDelete}`,
        method: 'DELETE',
        responseHandler: (response) => response.text(),
      }),
    }),
  }),
});

export const { useGetFeesQuery, useGetFeesForPerformanceQuery, useRunFeesSubmitMutation, useRunFeesDeleteMutation } =
  extendedApiFeesSlice;
