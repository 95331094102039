import { Stack, Tab, Tabs, useMediaQuery } from '@mui/material';
import { UserClaims } from '@okta/okta-auth-js';
import { createContext, useContext, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useAppAuth, useAppDispatch, useAppSelector } from '../../hooks';
import {
  GridStrategyType,
  selectIsGetMethodError,
  setIsReadOnly,
  useGetJobsQuery,
  useGetJobStrategiesByJobIDQuery,
} from '../../reducers';
import Header from '../Header';
import { Benchmark } from './Benchmark';
import { Fees } from './Fees';
import { FirmLitigation } from './Firm';
import { ManagerNotification } from './ManagerNotification';
import { Strategy } from './Strategy';
import { useGetFirmsQuery } from '../../reducers/firmsSlice';
import { ShadowStack } from './StyledShadow';
import { GridPerformanceType, VehiclesForPerformance } from './Vehicle';

type UserProfileExtra = Pick<UserClaims, 'locale' | 'preferred_username'>;
interface ShadowExtra {
  jobId: string;
  setJobId: (newId: string) => void;
  strategyShortCode: string;
  setStrategyShortCode: (newShortCode: string) => void;
  selectedFirmShortCode: string;
  setSelectedFirmShortCode: (newShortCode: string) => void;
  selectedFirmStrategyShortCode: string;
  setSelectedFirmStrategyShortCode: (newShortCode: string) => void;
  firmStrategies: GridStrategyType[];
  setFirmStrategies: (strategyShortCode: GridStrategyType[]) => void;
  vehicles: GridPerformanceType[];
  setVehicles: (strategyShortCode: GridPerformanceType[]) => void;
  selectedVehicleShortCode: string;
  setSelectedVehicleShortCode: (vehicleId: string) => void;
  setSelectedVehicleGrossOrNet: (grossOrNet: string) => void;
  selectedVehicleGrossOrNet: string;
  getJobsResult?: any;
  getFirmsResult?: any;
  avaliableStrategies?: any;
}

const emptyUserContext = {
  preferred_username: '',
  locale: '',
} as UserProfileExtra;

const emptyShadowContext: ShadowExtra = {
  jobId: '',
  strategyShortCode: '',
  selectedFirmShortCode: '',
  selectedFirmStrategyShortCode: '',
  selectedVehicleShortCode: '',
  firmStrategies: [],
  setFirmStrategies: (strategyShortCode: GridStrategyType[]) => {},
  vehicles: [],
  setVehicles: (strategyShortCode: GridPerformanceType[]) => {},
  setStrategyShortCode: (StrategyShortCode: string) => {},
  setSelectedFirmShortCode: (firmShortCode: string) => {},
  setSelectedFirmStrategyShortCode: (firmStrategyShortCode: string) => {},
  setJobId: (JobId: string) => {},
  setSelectedVehicleShortCode: (vehicleId: string) => {},
  selectedVehicleGrossOrNet: '',
  setSelectedVehicleGrossOrNet: (grossOrNet: string) => {},
};

export const UserContext = createContext(emptyUserContext);

export const ShadowContext = createContext(emptyShadowContext);
export const useShadowContext = () => useContext(ShadowContext);

const Home = () => {
  const [jobId, setJobId] = useState('');
  const [strategyShortCode, setStrategyShortCode] = useState('');
  const [selectedFirmShortCode, setSelectedFirmShortCode] = useState('');
  const [selectedFirmStrategyShortCode, setSelectedFirmStrategyShortCode] = useState('');
  const [selectedVehicleShortCode, setSelectedVehicleShortCode] = useState('');
  const [firmStrategies, setFirmStrategies] = useState<GridStrategyType[]>([]);
  const [vehicles, setVehicles] = useState<GridPerformanceType[]>([]);
  const [selectedVehicleGrossOrNet, setSelectedVehicleGrossOrNet] = useState('');

  const getJobsResult = useGetJobsQuery('');
  const getFirmsResult = useGetFirmsQuery('');

  const getStrategiesResult = useGetJobStrategiesByJobIDQuery(jobId, {
    skip: !jobId,
  });

  const dispatch = useAppDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const isGetMethodError = useAppSelector(selectIsGetMethodError);

  const { isUnauthorized } = useAppAuth();

  const smallScreen = useMediaQuery('(max-width: 821px)');

  const steps = [
    { title: 'Benchmark', content: <Benchmark /> },
    { title: 'Fees', content: <Fees /> },
    { title: 'Firm Pending Litigation', content: <FirmLitigation /> },
    { title: 'Manager Notif. Responses', content: <ManagerNotification /> },
    { title: 'Strategy Characteristics', content: <Strategy /> },
    { title: 'Vehicle Performance', content: <VehiclesForPerformance /> },
  ];

  useEffect(() => {
    dispatch(setIsReadOnly(isGetMethodError));
  }, [dispatch, isGetMethodError]);

  const a11yProps = (index: number) => ({
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
    form: `form-${activeStep + 1}`,
    type: 'submit',
  });

  const tabsOrientation = () => {
    if (smallScreen) {
      return 'horizontal';
    } else {
      return 'vertical';
    }
  };

  const handleTabChange = (newFormStep: number) => {
    setActiveStep(newFormStep);
  };

  const startShadowContext: ShadowExtra = {
    jobId,
    setJobId,
    strategyShortCode,
    setStrategyShortCode,
    selectedFirmShortCode,
    setSelectedFirmShortCode,
    selectedFirmStrategyShortCode,
    setSelectedFirmStrategyShortCode,
    selectedVehicleShortCode,
    setSelectedVehicleShortCode,
    firmStrategies,
    setFirmStrategies,
    vehicles,
    setVehicles,
    setSelectedVehicleGrossOrNet,
    selectedVehicleGrossOrNet,
    getJobsResult,
    getFirmsResult,
    avaliableStrategies: getStrategiesResult,
  };

  return (
    <>
      <Header pageName="The Shadow Data Editing" btnRedirectToName="RDRP" btnRedirectToURL="/" />
      <ToastContainer
        position="top-right"
        autoClose={8000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="colored"
      />
      {isUnauthorized ? null : (
        <Stack
          sx={{
            display: 'flex',
            gap: '1rem',
            width: '100%',
            alignItems: 'center',
            marginTop: '1rem',
          }}
          marginBottom={2}
        >
          <ShadowContext.Provider value={startShadowContext}>
            <ShadowStack marginBottom={2}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Tabs
                  orientation={tabsOrientation()}
                  value={activeStep}
                  variant="scrollable"
                  aria-label="Vertical tabs example"
                  onChange={(e, newTabID) => handleTabChange(newTabID)}
                  sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                  {steps.map(({ title }, index) => (
                    <Tab disabled={isGetMethodError} label={title} key={title.trim()} {...a11yProps(index)} wrapped />
                  ))}
                </Tabs>
                <Stack width="100%" overflow="overlay">
                  {steps[activeStep].content}
                </Stack>
              </Stack>
            </ShadowStack>
          </ShadowContext.Provider>
        </Stack>
      )}
    </>
  );
};

export default Home;
