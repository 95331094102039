import { GridColDef } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import * as Globals from '../../../../application/common';
import { apiSlice } from '../../../../reducers';
import { BaseColumns, EmptyBaseType, StrategyCharacteristicsBaseType } from './CommonSettings';

const STRATEGIES_CHARACTERISTICS_FIXED_INCOME_TAG = 'Strategy-char-fixed-income';

export interface fixedIncomeType extends StrategyCharacteristicsBaseType {
  weightedAvgCoupon: number;
  yieldToMaturity: number;
  averageMaturityYrs: number;
  yieldToWorst: number;
  durationYrs: number;
  averageQualityIssue: string;
  minimumQualityIssue: string;
  numberOfIssues: number;
  currentCash: number;
  annualTurnover: number;
  convexity: number;
}

export const EmptyfixedIncomeType: fixedIncomeType = {
  ...EmptyBaseType,
  weightedAvgCoupon: 0,
  yieldToMaturity: 0,
  averageMaturityYrs: 0,
  yieldToWorst: 0,
  durationYrs: 0,
  averageQualityIssue: '',
  minimumQualityIssue: '',
  numberOfIssues: 0,
  currentCash: 0,
  annualTurnover: 0,
  convexity: 0,
};

export const FixedIncomeColumnDefs: GridColDef[] = BaseColumns([
  {
    field: 'weightedAvgCoupon',
    headerName: 'Consumer Discretionary',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'yieldToMaturity',
    headerName: 'Yield To Maturity',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    align: 'right',
    editable: true,
  },
  {
    field: 'averageMaturityYrs',
    headerName: 'Average Maturity Yrs',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    align: 'right',
    editable: true,
  },
  {
    field: 'yieldToWorst',
    headerName: 'Yield To Worst',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'durationYrs',
    headerName: 'Duration Years',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'averageQualityIssue',
    headerName: 'Average Quality Issue',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'minimumQualityIssue',
    headerName: 'Minimum Quality Issue',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'numberOfIssues',
    headerName: 'Number Of Issues',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'currentCash',
    headerName: 'Current Cash',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'annualTurnover',
    headerName: 'Annual Turnover',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'convexity',
    headerName: 'Convexity',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
]);

export const extendedApiStrategyCharacteristcsFixedIncomeSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStrategyCharFixedIncome: builder.query({
      transformResponse: (baseQueryReturnValue?: fixedIncomeType[]): fixedIncomeType[] => baseQueryReturnValue ?? [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting Equity style data. Error: ${response.status}`);
        return response;
      },
      query: (strategyShortCode: string) => `${Globals.API.endpoints.strategyCharFixedIncome}/${strategyShortCode}`,
      providesTags: [STRATEGIES_CHARACTERISTICS_FIXED_INCOME_TAG],
    }),
    strategyCharFixedIncomeSubmit: builder.mutation({
      transformErrorResponse: (response) => {
        toast.error(`Update Strategy Characteristics Fixed inc action failed. Error: ${response.status}`);
        return response;
      },
      query: (equityMkt: fixedIncomeType[]) => ({
        url: `${Globals.API.endpoints.shadowStrategyCharFixedIncome}`,
        method: 'POST',
        body: equityMkt,
      }),
      invalidatesTags: [STRATEGIES_CHARACTERISTICS_FIXED_INCOME_TAG],
    }),
    strategyCharFixedIncomeDelete: builder.mutation({
      transformResponse: (baseQueryReturnValue?: string): string => baseQueryReturnValue ?? '',
      transformErrorResponse: (response) => {
        toast.error(`Delete Strategy Characteristics Fixed Inc action failed. Error: ${response.status}`);
        return response;
      },
      query: (FixedIncomeToDelete: number) => ({
        url: `${Globals.API.endpoints.shadowStrategyCharFixedIncome}/${FixedIncomeToDelete}`,
        method: 'DELETE',
        responseHandler: (response) => response.text(),
      }),
      invalidatesTags: [STRATEGIES_CHARACTERISTICS_FIXED_INCOME_TAG],
    }),
  }),
});

export const {
  useGetStrategyCharFixedIncomeQuery,
  useStrategyCharFixedIncomeDeleteMutation,
  useStrategyCharFixedIncomeSubmitMutation,
} = extendedApiStrategyCharacteristcsFixedIncomeSlice;
