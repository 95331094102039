import { TextField } from '@mui/material';
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { ChangeEvent } from 'react';

const ShadowGridNumberInput = (
  { row: { id }, field, value, hasFocus, colDef: { type } }: GridRenderCellParams,
  numericalInput: boolean,
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void,
) => {
  const ref = React.useRef<HTMLInputElement>(null);

  useEnhancedEffect(() => {
    if (hasFocus && ref.current) {
      const input = ref.current.querySelector<HTMLInputElement>(`input[value="${value}"]`);
      input?.focus();
    }
  }, [hasFocus, value]);

  const handleBlur = (event: any) => {
    const newEvent = {
      ...event,
      target: {
        ...event.target,
        value: event.target.value === '' ? 0 : numericalInput ? Number(event.target.value) : event.target.value,
      },
    };
    return handleChange(newEvent);
  };

  return (
    <TextField
      ref={ref}
      onBlur={handleBlur}
      onChange={handleChange}
      type={numericalInput ? 'number' : 'text'}
      value={value}
      id={`${id}-${field}-id`}
    />
  );
};

export default ShadowGridNumberInput;
