import { toast } from 'react-toastify';
import { staticData } from '../../application/common';
import { ErrorClass } from '../../application/common/ErrorClass';
import { useValidateAumGrid } from '../../components/Shadow/Strategy/stepsSettings/AumGridValidations';
import { extendedApiStrategyCharacteristcsSlice } from '../../reducers/strategyCharacteristicsSlice';
import { AUMType } from '../../components/Shadow/Strategy/stepsSettings/Aum';

type StrategyCharacteristicsAumSubmitFunction = (firmsToDelete: AUMType[]) => Promise<AUMType[] | never>;
type StrategyCharacteristicsAumDeleteFunction = (strategyCharAUMToDelete: AUMType) => Promise<string | never>;
export const useMutateStrategyCharAum = () => {
  const [submitStrategiesChar, submitStrategiesCharResult] =
    extendedApiStrategyCharacteristcsSlice.useStrategyCharSubmitMutation();
  const [deleteStrategyCharAum, deleteStrateGyCharAumResult] =
    extendedApiStrategyCharacteristcsSlice.useStrategyCharDeleteMutation();

  const { validateAumGrid } = useValidateAumGrid();

  const submitStrateGyCharAUM: StrategyCharacteristicsAumSubmitFunction = async (aumsToSubmit) => {
    try {
      validateAumGrid(aumsToSubmit);
      const mappedAUMs = [
        ...aumsToSubmit.map((aum) => ({
          ...aum,
          modifiedDate: undefined,
          createdDate: undefined,
        })),
      ];
      return await submitStrategiesChar(mappedAUMs)
        .unwrap()
        .then((data) => {
          toast.success('AUMs submitted successfully');
          return data;
        });
    } catch (err: any) {
      console.error('Aum not submitted.');
      throw err;
    }
  };

  const deleteStrateGyCharAUM: StrategyCharacteristicsAumDeleteFunction = async (strategyCharAUMToDelete) => {
    try {
      if (strategyCharAUMToDelete.dataSourceId !== staticData.NEPC_CREATED_DS_ID) {
        toast.error('Can only Delete "NEPC Created" Strategy Characteristics');
        throw new ErrorClass('Can only Delete "NEPC Created" Strategy Characteristics', []);
      }
      return await deleteStrategyCharAum(strategyCharAUMToDelete.strategyCharId)
        .unwrap()
        .then((data) => {
          toast.success('AUM Deleted');
          return data;
        });
    } catch (err: any) {
      console.error('Strategy Characteristics Aum not deleted.');
      throw err;
    }
  };

  return {
    submitStrateGyCharAUM,
    submitStrategiesCharResult,
    deleteStrateGyCharAUM,
    deleteStrateGyCharAumResult,
  };
};
