import { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Button, Checkbox, FormControl, Stack, TextField, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { MainTabContent, ShadowFormControl } from '../StyledShadow';
import { PartialSearch } from '../../Common';
import {
  FirmLitigationType,
  FirmType,
  useGetFirmPendingLitigationMutation,
  useGetFirmsQuery,
  useGetShadowFirmIntigationQuery,
} from '../../../reducers/firmsSlice';
import { AwnserCardLine, BooleanDataLine, CheckBoxWLabel, TextAreaAwnserCardLine, TextDataLine } from '../../styles';
import { useDeleteFirm } from '../../../hooks/FirmDeleteHook';
import BooleanDisplay from '../../Common/BooleanDisplay';

export const FirmLitigation = () => {
  const [selectedFirmShortCode, setSelectedFirmShortCode] = useState<string>('');

  const getFirmDataResult = useGetShadowFirmIntigationQuery(selectedFirmShortCode, {
    skip: !selectedFirmShortCode,
  });

  const [editedFirmPending, setEditedFirmPending] = useState<FirmLitigationType>({
    firmShortCode: selectedFirmShortCode,
    dataSourceName: '',
    isLocked: false,
  });

  const { deleteFirm } = useDeleteFirm();

  const [getFirmPendingLitigation] = useGetFirmPendingLitigationMutation();

  const isFirmSelected: boolean = selectedFirmShortCode !== '';

  const isFirmByNEPC: boolean = editedFirmPending.dataSourceName === 'NEPC Created';

  useEffect(() => {
    const parsedFirm = {
      ...getFirmDataResult.data,
      firmShortCode: selectedFirmShortCode,
      dataSourceName: getFirmDataResult.data?.dataSourceName ?? '',
      isLocked: getFirmDataResult.data?.isLocked ?? false,
      modifiedDate: getFirmDataResult.data?.modifiedDate,
      pendingLitigationDisclosure: getFirmDataResult.data?.pendingLitigationDisclosure ?? '',
    };
    setEditedFirmPending(parsedFirm);
  }, [getFirmDataResult, isFirmSelected, selectedFirmShortCode]);

  const fetchFirmPendingLitigation = async () => {
    const parsedFirmPendingLitigation = { ...editedFirmPending, modifiedDate: undefined };
    const result = await getFirmPendingLitigation(parsedFirmPendingLitigation).unwrap();
    setEditedFirmPending(result);
  };

  const getFirmsResult = useGetFirmsQuery('');

  const handleFirmChange = (newValueObj: FirmType) => {
    const { firmShortCode } = newValueObj;
    setSelectedFirmShortCode(firmShortCode);
    setEditedFirmPending({ firmShortCode: firmShortCode, dataSourceName: '', isLocked: false });
  };

  return (
    <MainTabContent>
      <Stack direction="column" gap={2}>
        <Stack direction="row" alignItems="center" gap={2}>
          <FormControl size="small" fullWidth>
            <PartialSearch
              disabled={getFirmsResult.isLoading}
              id="firm-selection-partial-search"
              label="Firm Selection"
              loading={getFirmsResult.isLoading}
              noOptionsText="No Firm"
              onChange={handleFirmChange}
              optionKey="firmShortCode"
              optionName="firmLegalName"
              options={getFirmsResult.data}
              value={selectedFirmShortCode}
            />
          </FormControl>
          <strong>{selectedFirmShortCode}</strong>
          <Tooltip
            disableFocusListener={isFirmSelected || isFirmByNEPC}
            disableHoverListener={isFirmSelected || isFirmByNEPC}
            disableTouchListener={isFirmSelected || isFirmByNEPC}
            title="Delete is just avaliable for NEPC Created"
          >
            <span>
              <Button
                variant="contained"
                disabled={!isFirmSelected || !isFirmByNEPC}
                onClick={() => deleteFirm(selectedFirmShortCode).then(() => toast.success('Disclosure Deleted'))}
                startIcon={<DeleteIcon />}
                color="error"
              >
                Delete
              </Button>
            </span>
          </Tooltip>
          <Button
            id="save-firm-pending-button"
            variant="contained"
            disabled={!isFirmSelected}
            onClick={() => fetchFirmPendingLitigation().then(() => toast.success('Disclosure Updated'))}
          >
            Save
          </Button>
        </Stack>
        <Stack direction={{ lg: 'row' }} gap={2} justifyContent={{ md: 'center', lg: 'space-between' }}>
          <Stack direction="column" border={1} borderColor="#002060" borderRadius={2} paddingInline={5} minWidth="40%">
            <Stack alignItems="center">
              <h3>Current</h3>
            </Stack>
            <Stack rowGap={2} marginBottom={2}>
              <TextDataLine id="data-source">
                <label>Data Source:</label>
                <AwnserCardLine>{getFirmDataResult.data?.dataSourceName}</AwnserCardLine>
              </TextDataLine>
              <TextDataLine id="pending-litigation-disclosure" direction="column">
                <label>Pending Litigation Disclosure:</label>
                <TextAreaAwnserCardLine className="pending-litigation-disclosure" minWidth="98%" width="98%">
                  {getFirmDataResult.data?.pendingLitigationDisclosure}
                </TextAreaAwnserCardLine>
              </TextDataLine>
              <BooleanDataLine id="data-record-locked">
                <label>Record Locked:</label>
                <BooleanDisplay value={getFirmDataResult.data?.isLocked} />
              </BooleanDataLine>
              <TextDataLine id="date-record-locked">
                <label>Date Record Locked:</label>
                <AwnserCardLine>
                  {Boolean(getFirmDataResult.data?.dateLocked) &&
                    moment(getFirmDataResult.data?.dateLocked).format('MM/DD/YYYY hh:mm:ss A')}
                </AwnserCardLine>
              </TextDataLine>
              <TextDataLine id="Last-Modified-by">
                <label>Last Modified by:</label>
                <AwnserCardLine>{getFirmDataResult.data?.modifiedBy}</AwnserCardLine>
              </TextDataLine>
              <TextDataLine id="last-modified-date">
                <label>Last Modified date:</label>
                <AwnserCardLine>
                  {Boolean(getFirmDataResult.data?.modifiedDate) &&
                    moment(getFirmDataResult.data?.modifiedDate).format('MM/DD/YYYY hh:mm:ss A')}
                </AwnserCardLine>
              </TextDataLine>
            </Stack>
          </Stack>
          <Stack direction="column" border={1} borderRadius={2} borderColor="#002060" paddingInline={5} minWidth="40%">
            <Stack alignItems="center">
              <h3>New</h3>
            </Stack>
            <ShadowFormControl sx={{ marginTop: '4rem' }} fullWidth>
              <TextField
                id="input-PLD"
                label="Pending Litigation Disclosure"
                multiline
                rows={5}
                fullWidth
                variant="outlined"
                onChange={(e) =>
                  setEditedFirmPending({ ...editedFirmPending, pendingLitigationDisclosure: e.target.value })
                }
                value={editedFirmPending.pendingLitigationDisclosure}
                InputLabelProps={{ shrink: Boolean(editedFirmPending!.pendingLitigationDisclosure) }}
                disabled={!editedFirmPending}
              />
            </ShadowFormControl>
            <Stack alignSelf="baseline">
              <CheckBoxWLabel
                value="start"
                control={
                  <Checkbox
                    id="lock-record-firm-pending-button"
                    checked={editedFirmPending.isLocked}
                    onChange={(e) => setEditedFirmPending({ ...editedFirmPending, isLocked: e.target.checked })}
                    disabled={!editedFirmPending}
                  />
                }
                label="Lock Record"
                labelPlacement="start"
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </MainTabContent>
  );
};
