import { toast } from 'react-toastify';
import {
  extendedApiStrategyCharacteristcsFixedRatingSlice,
  fixedRatingType,
} from '../../components/Shadow/Strategy/stepsSettings/fixedRating';

type StrategyCharacteristicsFixedRatingSubmitFunction = (
  fixedRatingsToSubmit: fixedRatingType[],
) => Promise<fixedRatingType[] | never>;

type StrategyCharacteristicsFixedRatingDeleteFunction = (
  strategyCharEquityToDelete: fixedRatingType,
) => Promise<string | never>;
export const useMutateStrategyCharFixedRating = () => {
  const [submitStrategiesCharFixedRating, submitStrategiesCharFixedRatingResult] =
    extendedApiStrategyCharacteristcsFixedRatingSlice.useStrategyCharFixedRatingSubmitMutation();
  const [deleteStrategyFixedRating, deleteStrateGyCharFixedRatingResult] =
    extendedApiStrategyCharacteristcsFixedRatingSlice.useStrategyCharFixedRatingDeleteMutation();

  const submitStrateGyCharFixedRating: StrategyCharacteristicsFixedRatingSubmitFunction = async (
    equitysStyleToSubmit,
  ) => {
    try {
      const mappedFixedRating = equitysStyleToSubmit.map((equityStyle) => ({
        ...equityStyle,
        modifiedDate: undefined,
      }));
      return await submitStrategiesCharFixedRating(mappedFixedRating)
        .unwrap()
        .then((data) => {
          toast.success('Fixed Rating submitted successfully');
          return data;
        });
    } catch (err: any) {
      console.error('Fixed Rating not submitted.');
      throw err;
    }
  };

  const deleteStrateGyCharFixedRating: StrategyCharacteristicsFixedRatingDeleteFunction = async (
    strategyCharFixedRatingToDelete,
  ) => {
    try {
      return await deleteStrategyFixedRating(strategyCharFixedRatingToDelete.strategyCharId)
        .unwrap()
        .then((data) => {
          toast.success('Fixed Rating Deleted');
          return data;
        });
    } catch (err: any) {
      toast.error('Strategy Characteristics Fixed Rating not deleted.');
      console.error('Strategy Characteristics Fixed Rating not deleted.');
      throw err;
    }
  };

  return {
    submitStrateGyCharFixedRating,
    submitStrategiesCharFixedRatingResult,
    deleteStrateGyCharFixedRating,
    deleteStrateGyCharFixedRatingResult,
  };
};
