import moment from 'moment';
import { ErrorClass } from '../../../../application/common/ErrorClass';
import { toast } from 'react-toastify';
import { AUMType } from './Aum';

const getAumGridErrors = (aums: AUMType[]): string[] => {
  const today = moment();

  const isValidQuarter = (month: number) =>
    month ? Boolean(month === 3 || month === 6 || month === 9 || month === 12) : false;

  let isFutureDateMsg = '';
  let isValidQuarterMsg = '';
  let isValidYearMsg = '';

  aums.forEach((aum) => {
    const dateToCompare = moment(`${aum.year}-${aum.month}-01`);
    if (aum.year < 999) {
      isValidYearMsg = 'Year is not valid';
    }
    if (today.isBefore(dateToCompare, 'month')) {
      isFutureDateMsg = 'Year/Month cannot be in the future';
    }
    if (!isValidQuarter(aum.month)) {
      isValidQuarterMsg = 'Month must be a Quarter End Month';
    }
  });

  return [isFutureDateMsg, isValidYearMsg, isValidQuarterMsg].filter((msg) => msg);
};

export const useValidateAumGrid = () => {
  const validateAumGrid = (aums: AUMType[]): boolean | never => {
    const aumGridErros = getAumGridErrors(aums);
    if (aumGridErros.length) {
      aumGridErros.forEach((_msg) => toast.error(_msg));
      throw new ErrorClass('Aum Grid not valid', aumGridErros);
    }
    return true;
  };
  return { validateAumGrid };
};
