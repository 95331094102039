import moment from 'moment';
import { ErrorClass } from '../../../../application/common/ErrorClass';
import { EquityMktCapType } from '../StrategiesColumnDefs';
import { toast } from 'react-toastify';

const getEquityGridErrors = (equitys: EquityMktCapType[]): string[] => {
  const today = moment();

  const isValidQuarter = (month: number) =>
    month ? Boolean(month === 3 || month === 6 || month === 9 || month === 12) : false;

  let isFutureDateMsg = '';
  let isValidQuarterMsg = '';
  let isValidYearMsg = '';

  equitys.forEach((equityCap) => {
    const dateToCompare = moment(`${equityCap.year}-${equityCap.month}-01`);
    if (equityCap.year < 999) {
      isValidYearMsg = 'Year is not valid';
    }
    if (today.isBefore(dateToCompare, 'month')) {
      isFutureDateMsg = 'Year/Month cannot be in the future';
    }
    if (!isValidQuarter(equityCap.month)) {
      isValidQuarterMsg = 'Month must be a Quarter End Month';
    }
  });

  return [isFutureDateMsg, isValidYearMsg, isValidQuarterMsg].filter((msg) => msg);
};

export const useValidateEquityCapGrid = () => {
  const validateEquityCapGrid = (equityCaps: EquityMktCapType[]): boolean | never => {
    const equityCapGridErros = getEquityGridErrors(equityCaps);
    if (equityCapGridErros.length) {
      equityCapGridErros.forEach((_msg) => toast.error(_msg));
      throw new ErrorClass('Equity Grid not valid', equityCapGridErros);
    }
    return true;
  };
  return { validateEquityCapGrid };
};
