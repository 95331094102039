import { useEffect, useState } from 'react';
import { Button, Checkbox, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';

import { PartialSearch } from '../../Common';
import { Fields, MainTabContent } from '../StyledShadow';
import { JobLiteType, GridStrategyType } from '../../../reducers';
import { AwnserCardLine, BooleanDataLine, TextDataLine } from '../../styles';
import { managerNotificationsType, useGetNotificationsQuery } from '../../../reducers/notificationsSlice';
import { useDeleteNotification, useSaveNotifications } from '../../../hooks';
import { toast } from 'react-toastify';
import { useShadowContext } from '../Shadow';

export const ManagerNotification = () => {
  const { jobId, strategyShortCode, setStrategyShortCode, setJobId, getJobsResult, avaliableStrategies } =
    useShadowContext();
  const [notifications, setNotifications] = useState<managerNotificationsType>({
    saProposed: false,
    cfProposed: false,
    mfProposed: false,
  });

  const filteredJobs = getJobsResult.data?.filter((job: JobLiteType) => `Job Canceled` !== job.jobStatus);

  const { saveNotifications, saveNotificationsResult } = useSaveNotifications();
  const { deleteNotification } = useDeleteNotification();

  const getNotificationsResult = useGetNotificationsQuery(
    { selectedStrategyShortCode: strategyShortCode, jobID: jobId },
    {
      skip: !strategyShortCode,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (!getNotificationsResult.currentData) {
      return setNotifications({
        jobId: jobId,
        strategyShortCode: strategyShortCode,
      });
    }
    if (getNotificationsResult.data) {
      return setNotifications({
        jobId: jobId,
        strategyShortCode: strategyShortCode,
        ...getNotificationsResult.data,
      });
    }
  }, [getNotificationsResult, saveNotificationsResult, jobId, strategyShortCode]);

  const deleteNotifications = () => {
    if (notifications.id) {
      deleteNotification(notifications.id)
        .then(() => {
          toast.success('Notifications Deleted');
          setNotifications({
            jobId: jobId,
            strategyShortCode: strategyShortCode,
          });
        })
        .then(() => setNotifications({}));
    }
  };

  return (
    <MainTabContent>
      <Stack direction="column" gap={2}>
        <Fields spacing={2} direction="column">
          <Stack direction="row" gap={4}>
            <Stack gap={1} direction="column" whiteSpace="nowrap" alignItems="start" minWidth="60%">
              <PartialSearch
                disabled={!getJobsResult.data}
                id="job-partial-search"
                label="Job"
                loading={getJobsResult.isLoading}
                onChange={(newValueObj: JobLiteType) => {
                  setStrategyShortCode('');
                  setJobId(newValueObj.jobID);
                }}
                noOptionsText="No jobs"
                optionKey="jobID"
                optionName="jobName"
                options={filteredJobs}
                value={jobId}
                sx={{ minWidth: '100%' }}
              />
              <PartialSearch
                disabled={!avaliableStrategies.data || jobId === ''}
                id="strategy-partial-search"
                label="Strategy"
                loading={avaliableStrategies.isLoading}
                onChange={(newValueObj: GridStrategyType) => {
                  setStrategyShortCode(newValueObj.strategyShortCode);
                }}
                noOptionsText="No strategies"
                optionKey="strategyShortCode"
                optionName="strategyName"
                options={avaliableStrategies.data}
                value={strategyShortCode}
                sx={{ minWidth: '100%' }}
              />
            </Stack>
            <Stack direction="column" justifyContent="space-around" width="30%">
              <Stack direction="row" gap={1}>
                Job ID: <strong>{jobId}</strong>
              </Stack>
              <Stack direction={{ lg: 'column', xl: 'row' }} alignItems="center">
                <Stack direction="row" gap={1} sx={{ textWrap: 'nowrap' }}>
                  Strategy ID: <strong>{strategyShortCode}</strong>
                </Stack>
                <Stack direction="row" gap={1} justifyContent="flex-end">
                  <Button
                    variant="contained"
                    disabled={!notifications.jobStrategyId}
                    onClick={deleteNotifications}
                    startIcon={<DeleteIcon />}
                    color="error"
                  >
                    Delete
                  </Button>
                  <Button
                    variant="contained"
                    disabled={!strategyShortCode}
                    onClick={() =>
                      saveNotifications({ notifications }).then((data) => {
                        setNotifications(data);
                        toast.success('Notifications Saved');
                      })
                    }
                  >
                    Save
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Fields>
        <Stack width="100%" direction="column" gap={2}>
          <BooleanDataLine width="40%" alignItems="center">
            <label>SA Proposed:</label>
            <Checkbox
              disabled={!strategyShortCode}
              checked={notifications.saProposed ?? false}
              onChange={(e) => setNotifications({ ...notifications, saProposed: e.target.checked })}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </BooleanDataLine>
          <BooleanDataLine width="40%" alignItems="center">
            <label>CF Proposed:</label>
            <Checkbox
              disabled={!strategyShortCode}
              checked={notifications.cfProposed ?? false}
              onChange={(e) => setNotifications({ ...notifications, cfProposed: e.target.checked })}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </BooleanDataLine>
          <BooleanDataLine width="40%" alignItems="center">
            <label>MF Proposed:</label>
            <Checkbox
              disabled={!strategyShortCode}
              checked={notifications.mfProposed ?? false}
              onChange={(e) => setNotifications({ ...notifications, mfProposed: e.target.checked })}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </BooleanDataLine>
          <TextDataLine
            id="notification-modified-by"
            width={{ md: '60%', lg: '40%' }}
            direction={{ md: 'column', lg: 'row' }}
          >
            <label>Modified by:</label>
            <AwnserCardLine>{notifications.modifiedBy}</AwnserCardLine>
          </TextDataLine>
          <TextDataLine
            id="notification-modified-date"
            width={{ md: '60%', lg: '40%' }}
            direction={{ md: 'column', lg: 'row' }}
          >
            <label>Modified Date:</label>
            <AwnserCardLine>
              {Boolean(notifications.modifiedDate) &&
                moment(notifications.modifiedDate).format('MM/DD/YYYY hh:mm:ss A')}
            </AwnserCardLine>
          </TextDataLine>
        </Stack>
      </Stack>
    </MainTabContent>
  );
};
