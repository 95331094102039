import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 821,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          borderColor: '#16709E',
          '&.Mui-selected': {
            backgroundColor: '#16709E',
          },
          '&:focus.Mui-selected': {
            backgroundColor: '#16709E',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          maxHeight: '20vh',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#16709E',
      dark: '#002060',
      light: '#6ed0f7',
    },
    secondary: {
      main: '#C9D4DE',
      dark: '#43505E',
    },
    action: {
      hover: '#DBE7F2',
      focus: '#DBE7F2',
    },
    background: { default: '#f4f7fb' },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

export default theme;
