import { Stack, FormControl } from '@mui/material';
import { BenchmarkType, useGetBenchmarksQuery } from '../../../../reducers';
import { PartialSearch } from '../../../Common';
import { useStrategiesContext } from '../StrategiesContext/StrategiesContext';

export const RiskFreeBenchmark = () => {
  const { isReadOnly, setFieldValue, values } = useStrategiesContext();
  const getBenchmarksResult = useGetBenchmarksQuery('');
  return (
    <>
      <h4>Risk Free Proxy Benchmark.</h4>
      <Stack direction="row" width="100%" spacing={2} marginBottom="1rem" marginTop="1rem">
        <FormControl size="small" fullWidth>
          <PartialSearch
            disabled={isReadOnly}
            id="risk-free-proxy-benchmark-partial-search"
            label="Risk Free Proxy Benchmark"
            loading={getBenchmarksResult.isFetching}
            onChange={(newValueObj: BenchmarkType) =>
              setFieldValue('fkRiskFreeProxyBmk', newValueObj.benchmarkShortCode)
            }
            optionKey="benchmarkShortCode"
            optionName="benchmarkName"
            options={getBenchmarksResult.data}
            value={values.fkRiskFreeProxyBmk}
          />
        </FormControl>
      </Stack>
    </>
  );
};
