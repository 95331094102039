import { GridColDef } from '@mui/x-data-grid';
import { BaseColumns, EmptyBaseType, StrategyCharacteristicsBaseType } from './CommonSettings';

export interface AUMType extends StrategyCharacteristicsBaseType {
  cfAccounts: number;
  cfAssets: number;
  createdBy: string;
  createdDate?: string;
  institutionalAccounts: number;
  institutionalAssets: number;
  institutionalMfAssets: number;
  portfolioManagers: number;
  researchAnalysts: number;
  retailMfAssets: number;
  saAccounts: number;
  saAssets: number;
  strategyCharAumId: number;
  totalAccounts: number;
  totalAssets: number;
  traders: number;
}

export const emptyAUMType: AUMType = {
  ...EmptyBaseType,
  cfAccounts: 0,
  cfAssets: 0,
  createdBy: '',
  institutionalAccounts: 0,
  institutionalAssets: 0,
  institutionalMfAssets: 0,
  portfolioManagers: 0,
  researchAnalysts: 0,
  retailMfAssets: 0,
  saAccounts: 0,
  saAssets: 0,
  strategyCharAumId: 0,
  totalAccounts: 0,
  totalAssets: 0,
  traders: 0,
};

export const AUMColumnDefs: GridColDef[] = BaseColumns([
  {
    field: 'totalAssets',
    headerName: 'Total Assets',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'totalAccounts',
    headerName: 'Total Accounts',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'saAssets',
    headerName: 'SA Assets',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'saAccounts',
    headerName: 'SA Accounts',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'cfAssets',
    headerName: 'CF Assets',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'cfAccounts',
    headerName: 'CF Accounts',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'institutionalMfAssets',
    headerName: 'Institutional MF Assets',
    width: 150,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'retailMfAssets',
    headerName: 'Retail MF Assets',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'institutionalAccounts',
    headerName: 'Institutional Accounts',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'institutionalAssets',
    headerName: 'Institutional Assets',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'portfolioManagers',
    headerName: 'Portfolio Managers',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'researchAnalysts',
    headerName: 'Research Analysts',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'traders',
    headerName: 'Traders',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
]);
