import { ReactNode, createContext, useContext, useState } from 'react';
import {
  GridStrategyType,
  JobType,
  emptyGridStrategyRow,
  emptyJob,
  selectIsReadOnly,
  useLazyGetVehicleDataSourceIDQuery,
} from '../../../../reducers';
import { useFormikContext, FormikErrors, FormikTouched, FormikHelpers } from 'formik';
import { StrategySelectionStyled } from '../styles';
import { GetFirstFplFunction, SaveJobFunction, useAppSelector, useFirstFpl, useSaveJob } from '../../../../hooks';

interface StrategiesContextType {
  errors: FormikErrors<JobType>;
  getFirstFpl: GetFirstFplFunction;
  initialValues: JobType;
  isReadOnly: boolean;
  isSaveJobLoading: boolean;
  isStrategiesGridLocked: boolean;
  isStrategyInTheGrid: (selectedStrategyShortCode: string) => boolean;
  populateAllMissingFieldsAsync: (strategies: GridStrategyType[]) => Promise<GridStrategyType[]>;
  populateMissingFieldsAsync: (strategy: GridStrategyType) => Promise<GridStrategyType>;
  saveJob: SaveJobFunction;
  selectedJobStrategyRow: GridStrategyType;
  setFieldValue: FormikHelpers<JobType>['setFieldValue'];
  setIsStrategiesGridLocked: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedJobStrategyRow: React.Dispatch<React.SetStateAction<GridStrategyType>>;
  touched: FormikTouched<JobType>;
  values: JobType;
}

const emptyStrategiesContext: StrategiesContextType = {
  errors: {},
  getFirstFpl: () => ({}),
  initialValues: emptyJob,
  isReadOnly: false,
  isSaveJobLoading: false,
  isStrategiesGridLocked: false,
  isStrategyInTheGrid: () => false,
  populateAllMissingFieldsAsync: async () => [],
  populateMissingFieldsAsync: async () => emptyGridStrategyRow,
  saveJob: async () => emptyJob,
  selectedJobStrategyRow: emptyGridStrategyRow,
  setFieldValue: () => new Promise(() => ({})),
  setIsStrategiesGridLocked: () => ({}),
  setSelectedJobStrategyRow: () => ({}),
  touched: {},
  values: emptyJob,
};

const StrategiesContext = createContext<StrategiesContextType>(emptyStrategiesContext);

const StrategiesProvider = ({ children }: { children: ReactNode }) => {
  const [isStrategiesGridLocked, setIsStrategiesGridLocked] = useState(false);
  const [selectedJobStrategyRow, setSelectedJobStrategyRow] = useState<GridStrategyType>(emptyGridStrategyRow);

  const { errors, initialValues, setFieldValue, touched, values } = useFormikContext<JobType>();
  const { strategies = [] } = values;

  const isReadOnly = useAppSelector(selectIsReadOnly);

  const [getVehicleDataSourceIDQueryTrigger] = useLazyGetVehicleDataSourceIDQuery();
  const { getFirstFpl } = useFirstFpl();
  const { saveJob, saveJobResult } = useSaveJob();
  const { isLoading: isSaveJobLoading } = saveJobResult;

  const isStrategyInTheGrid = (selectedStrategyShortCode: string): boolean =>
    strategies.some((strategy: GridStrategyType) => strategy.strategyShortCode === selectedStrategyShortCode);

  const populateMissingFieldsAsync = async (strategy: GridStrategyType): Promise<GridStrategyType> => {
    const vehicleDataSourceIDResult = await getVehicleDataSourceIDQueryTrigger(strategy);
    const { data: dataSourceID, isSuccess } = vehicleDataSourceIDResult;
    return {
      ...strategy,
      benchmarkShortCode:
        values.fkRequestedBmk ||
        (strategy.benchmarkShortCode === values.fkSecondaryBmk ? '' : strategy.benchmarkShortCode),
      benchmark2ShortCode:
        values.fkSecondaryBmk ||
        (strategy.benchmark2ShortCode === values.fkRequestedBmk ? '' : strategy.benchmark2ShortCode),
      benchmarkName: '',
      benchmark2Name: '',
      dataSourceID: isSuccess ? dataSourceID : '',
      isPerformanceOnly: Boolean(strategy.isPerformanceOnly || values.isPerformanceOnlyBook),
      jobID: values.jobID,
    };
  };

  const populateAllMissingFieldsAsync = async (strategies: GridStrategyType[] = []): Promise<GridStrategyType[]> =>
    await Promise.all(
      strategies
        .filter((strategy) => strategy.strategyShortCode !== 'All')
        .map(async (strategy) => await populateMissingFieldsAsync(strategy)),
    );

  return (
    <StrategiesContext.Provider
      value={{
        errors,
        getFirstFpl,
        initialValues,
        isReadOnly,
        isSaveJobLoading,
        isStrategiesGridLocked,
        isStrategyInTheGrid,
        populateAllMissingFieldsAsync,
        populateMissingFieldsAsync,
        saveJob,
        selectedJobStrategyRow,
        setFieldValue,
        setIsStrategiesGridLocked,
        setSelectedJobStrategyRow,
        touched,
        values,
      }}
    >
      <StrategySelectionStyled>{children}</StrategySelectionStyled>
    </StrategiesContext.Provider>
  );
};

const useStrategiesContext = () => useContext(StrategiesContext);

export { StrategiesProvider, useStrategiesContext };
