import { Stack, Button } from '@mui/material';
import { toastPerformanceOnlyBookMsg } from '../../../../application/common';
import { FplType, GridStrategyType, emptyFpl, useGetFplStrategiesQuery, useGetFplsQuery } from '../../../../reducers';
import { PartialSearch } from '../../../Common';
import { useStrategiesContext } from '../StrategiesContext/StrategiesContext';
import { useState } from 'react';

export const FplSelection = () => {
  const {
    errors,
    isReadOnly,
    isStrategyInTheGrid,
    populateAllMissingFieldsAsync,
    saveJob,
    setFieldValue,
    touched,
    values,
  } = useStrategiesContext();
  const { strategies = [] } = values;

  const [selectedFpl, setSelectedFpl] = useState<FplType>(emptyFpl);
  const [selectedFplStrategyShortCode, setSelectedFplStrategyShortCode] = useState('All');

  const getFplsResult = useGetFplsQuery('');
  const getFplStrategiesResult = useGetFplStrategiesQuery(selectedFpl.fplGuid, { skip: !selectedFpl.fplGuid });

  const handleFplChange = (newValueObj: FplType) => {
    setSelectedFpl(newValueObj);
    setSelectedFplStrategyShortCode('All');
  };

  const getSelectedStrategies = () =>
    selectedFplStrategyShortCode !== 'All' && getFplStrategiesResult.data?.length
      ? getFplStrategiesResult.data.filter(
          (strategy: GridStrategyType) => strategy.strategyShortCode === selectedFplStrategyShortCode,
        )
      : [...(getFplStrategiesResult.data ?? [])];

  const getNonDuplicatedStrategies = (): GridStrategyType[] =>
    getSelectedStrategies()
      .filter((strategy: GridStrategyType) => !isStrategyInTheGrid(strategy.strategyShortCode))
      .map((strategy: GridStrategyType) => ({ ...strategy, grouping: selectedFpl.fplName }));

  const handleSelectBtnClick = async () => {
    const setAllPerfOnly = (strategies: GridStrategyType[]) =>
      strategies.map((strategy) => ({
        ...strategy,
        isPerformanceOnly: true,
      }));
    const nonDuplicatedStrategies = getNonDuplicatedStrategies();
    let newStrategies: GridStrategyType[];
    const newStrategiesToTheGrid = await populateAllMissingFieldsAsync(nonDuplicatedStrategies);
    if (nonDuplicatedStrategies.length) {
      if (!strategies.filter((strategy) => strategy.type === 'FPL').length && selectedFpl.isPerformanceOnlyBook) {
        toastPerformanceOnlyBookMsg(selectedFpl);
        newStrategies = setAllPerfOnly([...strategies, ...newStrategiesToTheGrid]);
      } else {
        newStrategies = [...strategies, ...newStrategiesToTheGrid];
      }

      setFieldValue('strategies', newStrategies);
      saveJob({ job: { ...values, strategies: newStrategies } });
    }
  };

  const isAllStrategiesInTheGrid = (): boolean =>
    getSelectedStrategies()
      .filter((strategy) => strategy.strategyShortCode !== 'All')
      .every((strategy) => isStrategyInTheGrid(strategy.strategyShortCode));

  return (
    <>
      <h4>First, select an FPL. FPL Strategies will be included by default and can be removed.</h4>
      <Stack direction="row" alignItems="flex-end" width="100%" spacing={2} marginBottom="1rem" marginTop="1rem">
        <Stack direction="column" width="100%" spacing={2}>
          <PartialSearch
            disabled={isReadOnly}
            id="fpl-selection-partial-search"
            error={errors.fplGuid}
            label="FPL Selection"
            loading={getFplsResult.isFetching}
            noOptionsText="No FPL"
            onChange={handleFplChange}
            optionKey="fplGuid"
            optionName="fplNameWithPerfOnly"
            options={getFplsResult.data?.map((fpl) => ({
              ...fpl,
              fplNameWithPerfOnly: `${fpl.fplName}${fpl.isPerformanceOnlyBook ? ' (Perf Only)' : ''}`,
            }))}
            touched={touched.fplGuid}
            value={selectedFpl.fplGuid}
          />
          <PartialSearch
            disabled={!selectedFpl.fplGuid || isReadOnly}
            id="strategy-partial-search"
            label="Strategy"
            loading={getFplStrategiesResult.isFetching}
            noOptionsText="No FPL strategies"
            onChange={(newValueObj: GridStrategyType) => setSelectedFplStrategyShortCode(newValueObj.strategyShortCode)}
            optionKey="strategyShortCode"
            optionName="strategyName"
            options={getFplStrategiesResult.data}
            value={selectedFplStrategyShortCode}
          />
        </Stack>
        <Button
          variant="contained"
          onClick={handleSelectBtnClick}
          disabled={
            !selectedFpl.fplGuid ||
            !getFplStrategiesResult.data?.length ||
            getFplStrategiesResult.isFetching ||
            isAllStrategiesInTheGrid()
          }
        >
          Select
        </Button>
      </Stack>
    </>
  );
};
