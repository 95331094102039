import { GridColDef } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import * as Globals from '../../../../application/common';
import { apiSlice } from '../../../../reducers';
import { BaseColumns, EmptyBaseType, StrategyCharacteristicsBaseType } from './CommonSettings';

const STRATEGIES_CHARACTERISTICS_EQUITY_STYLE_TAG = 'Strategy-char-equity-style';

export interface EquityStyleType extends StrategyCharacteristicsBaseType {
  earningsYield: number;
  dividendYield: number;
  epsGrowth: number;
  fundamentalBeta: number;
  bookPrice: number;
  returnOnEquity: number;
}

export const EmptyEquityStyleType: EquityStyleType = {
  ...EmptyBaseType,
  earningsYield: 0,
  dividendYield: 0,
  epsGrowth: 0,
  fundamentalBeta: 0,
  bookPrice: 0,
  returnOnEquity: 0,
};

export const EquityStyleColumnDefs: GridColDef[] = BaseColumns([
  {
    field: 'earningsYield',
    headerName: 'Earnings Yield',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'dividendYield',
    headerName: 'Dividend Yield',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    align: 'right',
    editable: true,
  },
  {
    field: 'epsGrowth',
    headerName: 'Eps Growth',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'fundamentalBeta',
    headerName: 'Fundamental Beta',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'bookPrice',
    headerName: 'Book Price',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
  {
    field: 'returnOnEquity',
    headerName: 'Return On Equity',
    width: 125,
    headerAlign: 'center',
    sortable: false,
    editable: true,
    align: 'right',
  },
]);

export const extendedApiStrategyCharacteristcsEquityStyleSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStrategyCharEquityStyle: builder.query({
      transformResponse: (baseQueryReturnValue?: EquityStyleType[]): EquityStyleType[] => baseQueryReturnValue ?? [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting Equity style data. Error: ${response.status}`);
        return response;
      },
      query: (strategyShortCode: string) => `${Globals.API.endpoints.strategyCharEquityStyle}/${strategyShortCode}`,
      providesTags: [STRATEGIES_CHARACTERISTICS_EQUITY_STYLE_TAG],
    }),
    strategyCharEquityStyleSubmit: builder.mutation({
      transformErrorResponse: (response) => {
        toast.error(`Update Strategy Characteristics Equity Style action failed. Error: ${response.status}`);
        return response;
      },
      query: (equityMkt: EquityStyleType[]) => ({
        url: `${Globals.API.endpoints.shadowStrategyCharEquityStyle}`,
        method: 'POST',
        body: equityMkt,
      }),
      invalidatesTags: [STRATEGIES_CHARACTERISTICS_EQUITY_STYLE_TAG],
    }),
    strategyCharEquityStyleDelete: builder.mutation({
      transformResponse: (baseQueryReturnValue?: string): string => baseQueryReturnValue ?? '',
      transformErrorResponse: (response) => {
        toast.error(`Delete Strategy Characteristics Style action failed. Error: ${response.status}`);
        return response;
      },
      query: (EquityStyleToDelete: number) => ({
        url: `${Globals.API.endpoints.shadowStrategyCharEquityStyle}/${EquityStyleToDelete}`,
        method: 'DELETE',
        responseHandler: (response) => response.text(),
      }),
      invalidatesTags: [STRATEGIES_CHARACTERISTICS_EQUITY_STYLE_TAG],
    }),
  }),
});

export const {
  useGetStrategyCharEquityStyleQuery,
  useStrategyCharEquityStyleDeleteMutation,
  useStrategyCharEquityStyleSubmitMutation,
} = extendedApiStrategyCharacteristcsEquityStyleSlice;
