import { extendedApiShadowVehiclesSlice, GridPerformanceType, performanceType } from './ShadowVehicleSlice';

export type SaveVehiclesFunction = (vehiclesToSubmit: GridPerformanceType[]) => Promise<performanceType[] | never>;
export const useSubmitVehicle = () => {
  const [mutate, submitVehiclesResult] = extendedApiShadowVehiclesSlice.useVehiclesSubmitMutation();

  const submitVehicles: SaveVehiclesFunction = async (vehiclesToSubmit) => {
    try {
      const mappedVehiclesToSubmit = vehiclesToSubmit.map((vehicle) => ({
        ...vehicle,
        modifiedDate: undefined,
        id: undefined,
        isTouched: undefined,
        compDate: undefined,
        isLocked: true,
      }));
      return await mutate(mappedVehiclesToSubmit).unwrap();
    } catch (err: any) {
      console.error('Vehicles performance not Submited.');
      throw err;
    }
  };

  return {
    submitVehiclesResult,
    submitVehicles,
  };
};
