import { apiSlice } from './apiSlice';
import * as Globals from '../application/common';
import { toast } from 'react-toastify';

export interface ClientType {
  clientShortCode: string;
  clientName: string;
}

export const extendedApiClientsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getClients: builder.query({
      transformResponse: (baseQueryReturnValue?: ClientType[]): ClientType[] => baseQueryReturnValue ?? [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting clients dropdown list. Error: ${response.status}`);
        return response;
      },
      query: () => Globals.API.endpoints.Clients,
      keepUnusedDataFor: 600,
    }),
  }),
});

export const { useGetClientsQuery } = extendedApiClientsSlice;
